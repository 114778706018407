import { loader } from 'graphql.macro';
import request from '../../utils/request';
import {
	GET_SELECTIONS_FAIL,
	GET_SELECTIONS_START,
	GET_SELECTIONS_SUCCESS,
	SelectionsThunkResult,
} from './types';
import { getClient } from '../categories/actions';
import { Fournisseur } from '../../types/Fournisseur';
import { Produit, Selection } from '../../types/Produit';

const query = loader('./getSelections.graphql');

export function getSelections(id: string): SelectionsThunkResult {
	return async function (dispatch) {
		try {
			dispatch({ type: GET_SELECTIONS_START });
			const client = await getClient(id);
			// recuperation de l'id du fournisseur
			const variables = { id: client.fournisseur.id };
			type TResponse = { fournisseur: Fournisseur };
			const { fournisseur } = await request<TResponse>(query, variables);

			// Extraire les sélections et aplatir le tableau imbriqué
			const selections: Selection[] = fournisseur.produits
				.map((produit: Produit) => produit.selections) // Cela renvoie Selection[][]
				.flat(); // .flat() aplatit le tableau imbriqué en Selection[]

			// Filtrer les doublons en fonction du nom
			const uniqueCategorie: Selection[] = selections.filter(
				(sel: Selection, index: number, self: Selection[]) =>
					index === self.findIndex((t: Selection) => t.nom === sel.nom),
			);

			// Old version on selections
			// type TResponse = { list: SelectionsState['list'] };
			// const { list } = await request<TResponse>(query);
			dispatch({ type: GET_SELECTIONS_SUCCESS, list: uniqueCategorie });
		} catch (err) {
			dispatch({ type: GET_SELECTIONS_FAIL, error: err.message });
		}
	};
}
