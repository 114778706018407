import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useTitle } from '../../../hooks/useTitle';
import { useUnmount } from '../../../hooks/useUnmount';
import request from '../../../utils/request';
import FormulaireFournisseur from '../FormulaireFournisseur/FormulaireFournisseur';
import { getCharaAlphabetPos, parseDelaiLivraison } from '../../../utils/parse';

// const getActualiteQuery = loader('./getActualite.graphql');
const getFournisseurQuery = loader('./getFournisseur.graphql');
const updateFournisseurMutationEmail = loader('./updateMailFournisseur.graphql');

const updateFournisseurMutation = loader('./updateFournisseur.graphql');
const createContact = loader('./createFournisseurContact.graphql');

interface Props {
	id: string;
}

function EditionFournisseur({ id }: Props) {
	useTitle(`Édition d'un fournisseur`);

	const { isMounted } = useUnmount();

	const [error, setError] = useState('');
	const [loading, setLoading] = useState(true);
	const [done, setDone] = useState(false);
	const [data, setData] = useState([]);

	const [disabled, changeDisabled] = useState('');
	const [login, changeLogin] = useState('');
	const [email, changeEmail] = useState('');
	const [optionLivraison, changeoptionLivraison] = useState('');
	const [raisonSociale, changeRaisonSociale] = useState('');
	const [enseigne, changeEnseigne] = useState('');
	const [siret, changeSiret] = useState('');
	const [description, changeDescription] = useState('');
	const [search, changeSearch] = useState('');
	const [prelevementAuto, changePrelevementAuto] = useState('');
	const [kbis, changeKbis] = useState('');
	const [rib, changeRib] = useState('');
	const [horairesOuverture, changeHorairesOuverture] = useState('');
	const [nomContactCompta, changeNomContactCompta] = useState('');
	const [prenomContactCompta, changePrenomContactCompta] = useState('');
	const [emailContactCompta, changeEmailContactCompta] = useState('');
	const [telephoneContactCompta, changeTelephoneContactCompta] = useState('');
	const [secteurActivite, changeSecteurActivite] = useState('');
	const [numeroVoieLivraison, changeNumeroVoieLivraison] = useState('');
	const [codePostalLivraison, changeCodePostalLivraison] = useState('');
	const [villeLivraison, changeVilleLivraison] = useState('');
	const [numeroVoieFacturation, changeNumeroVoieFacturation] = useState('');
	const [codePostalFacturation, changeCodePostalFacturation] = useState('');
	const [villeFacturation, changeVilleFacturation] = useState('');
	const [datesFermetureExceptionnelle, changeDatesFermetureExceptionnelle] =
		useState('');
	const [franco, changeFranco] = useState('');

	const [clients, changeClients] = useState([
		{ id: '', email: '', nom: '', first: '', type: '', tel: '' },
	]);

	const [joursLivraison, changeJoursLivraison]: any = useState([]);
	const [jourLivraison, changeJourLivraison]: any = useState('');

	const [numeroTVAIntracommunautaire, changeNumeroTVAIntracommunautaire] =
		useState('');
	const [conditionPaiement, changeConditionPaiement] = useState('');
	const [heureLimite, changeHeureLimite] = useState('');
	const [nomContactReferent, changeNomContactReferent] = useState('');
	const [prenomContactReferent, changePrenomContactReferent] = useState('');
	const [emailContactReferent, changeEmailContactReferent] = useState('');
	const [telephoneContactReferent, changeTelephoneContactReferent] =
		useState('');
	// const [photo, changePhoto] = useState<File | undefined>();
	const [photo, changePhoto] = useState('');
	const [test, changeTest] = useState('');
	const [actif, changeActif] = useState('');
	const [idPayement, changeIdPayement]: any = useState('');

	const [imgSrc, setPhoto] = useState<File | undefined>();
	const [imgKbisSrc, setKbis] = useState<File | undefined>();
	const [imgRibSrc, setRib] = useState<File | undefined>();
	const [contactType, changeContactType]: any = useState('');
	const [contactNom, changeContactNom]: any = useState('');
	const [contactPrenom, changeContactPrenom]: any = useState('');
	const [contactEmail, changeContactEmail]: any = useState('');
	const [contactTelephone, changeContactTelephone]: any = useState('');

	const [check, setCheck] = useState(false);

	useEffect(() => {
		changeJourLivraison(String(joursLivraison).split(','));
	}, [joursLivraison]);

	useEffect(() => {
		setError('');
		setLoading(true);

		let isMounted = true;
		type TResponse = { fournisseur: [] };
		request<TResponse>(getFournisseurQuery, { id })
			.then((res) => {
				if (res) {
					setData(res.fournisseur);
					if ((res.fournisseur as any).contacts.length) {
						changeClients(
							(res.fournisseur as any).contacts.map((item: any) => {
								return {
									id: item.id,
									nom: item.nom,
									tel:
										item.telephones.length > 0
											? item.telephones.join(', ')
											: '',
									type: item.type,
									first: item.prenom,
									email: item.email,
								};
							}),
						);
					}
				}
			})
			.catch((err) => {
				setError(err.message);
			})
			.finally(() => setLoading(false));

		return () => {
			isMounted = false;
		};
	}, [id, isMounted]);

	useEffect(() => {
		// @ts-ignore
		// console.log(data);
		if (data) {

			const option = 'A -> ' + getCharaAlphabetPos((data as any).optionLivraison);
			changeoptionLivraison(option);


			// @ts-ignore
			changeDisabled(data.disabled);
			// @ts-ignore
			changeRaisonSociale(data.raisonSociale);
			// @ts-ignore
			changeEnseigne(data.enseigne);
			// @ts-ignore
			changeEmail(data.email);
			// @ts-ignore
			changeLogin(data.login);
			// @ts-ignore
			changeDescription(data.description);
			// @ts-ignore
			changeHeureLimite(data.timeLivraison);
			// @ts-ignore
			changeHorairesOuverture(data.horairesOuverture);
			// @ts-ignore
			changeDatesFermetureExceptionnelle(data.datesFermetureExceptionnelle);
			// @ts-ignore
			changeNumeroTVAIntracommunautaire(data.numeroTVAIntracommunautaire);
			// @ts-ignore
			changeJoursLivraison(data.dayDelivery);
			// @ts-ignore
			changeJourLivraison(data.dayDelivery);
			// @ts-ignore
			changeConditionPaiement(data?.conditionsPaiement?.id);
			// @ts-ignore
			changeSecteurActivite(data?.secteurActivite?.id);
			// @ts-ignore
			changeRib(data?.rib);
			// @ts-ignore
			changeKbis(data?.kbis);
			// @ts-ignore
			changePhoto(data?.photo);
			// @ts-ignore
			changeFranco(data?.franco);
			// @ts-ignore
			changeNumeroVoieFacturation(data?.adresseFacturation?.voie);
			// @ts-ignore
			changeVilleFacturation(data?.adresseFacturation?.ville);
			// @ts-ignore
			changeCodePostalFacturation(data?.adresseFacturation?.codePostal);
		}
	}, [data]);

	async function submit() {
		let heureLimites = parseInt(heureLimite);
		let francos = parseInt(franco);
		let opt = parseDelaiLivraison(optionLivraison);
		try {
			setError('');
			setLoading(true);
			// console.log(clients);
			const data = await request(updateFournisseurMutation, {
				id,
				raisonSociale,
				enseigne,
				siret,

				description,
				prelevementAuto,
				kbis: imgKbisSrc,
				photo: imgSrc,
				disabled: disabled,
				rib: imgRibSrc,
				optionLivraison: opt,
				timeLivraison: heureLimites,
				horairesOuverture,
				heureLimites,
				secteurActivite,
				dayDelivery: jourLivraison.join(','),
				francos,
				jourLivraison,
				conditionPaiement,
				datesFermetureExceptionnelle,
				numeroTVAIntracommunautaire,
			});

			const emailUpd = await request(updateFournisseurMutationEmail,{id, email})
			if (
				clients.length > 0 &&
				clients[0].email !== '' &&
				clients[0].nom !== '' &&
				clients[0].first !== ''
			) {
				clients.forEach(async (item) => {
					console.log(item);
					await request(createContact, {
						fournisseurId: id,
						id: '',
						type: item.type,
						tel: item.tel,
						nom: item.nom,
						prenom: item.first,
						email: item.email,
					});
				});

				if (isMounted.current) setDone(true);
			} else {
				if (isMounted.current) setDone(true);
			}
		} catch (err) {
			setError(err.message);
			setLoading(false);
		}
	}

	return done ? (
		<Redirect to="/admin/fournisseurs" />
	) : (
		<FormulaireFournisseur
			type="Édition"
			{...{
				error,
				test,
				actif,
				loading,
				disabled,
				login,
				email,
				raisonSociale,
				enseigne,
				siret,
				description,
				search,
				prelevementAuto,
				kbis,
				photo,
				rib,
				nomContactReferent,
				prenomContactReferent,
				emailContactReferent,
				telephoneContactReferent,
				nomContactCompta,
				prenomContactCompta,
				emailContactCompta,
				telephoneContactCompta,
				secteurActivite,
				numeroVoieLivraison,
				codePostalLivraison,
				villeLivraison,
				numeroVoieFacturation,
				codePostalFacturation,
				optionLivraison,
				villeFacturation,
				horairesOuverture,
				heureLimite,
				conditionPaiement,
				franco,
				joursLivraison,
				contactType,
				contactNom,
				contactPrenom,
				contactEmail,
				contactTelephone,
				changeoptionLivraison,
				idPayement,
				datesFermetureExceptionnelle,
				numeroTVAIntracommunautaire,
				changeDisabled,
				changeLogin,
				changeEmail,
				changeRaisonSociale,
				changeEnseigne,
				changeSiret,
				changeDescription,
				changeSearch,
				changePhoto,
				setPhoto,
				setKbis,
				setRib,
				clients,
				changeClients,
				changePrelevementAuto,
				changeKbis,
				changeRib,
				changeHorairesOuverture,
				changeNomContactCompta,
				changePrenomContactCompta,
				changeEmailContactCompta,
				changeTelephoneContactCompta,
				changeSecteurActivite,
				changeNumeroVoieLivraison,
				changeCodePostalLivraison,
				changeVilleLivraison,
				changeNumeroVoieFacturation,
				changeCodePostalFacturation,
				changeVilleFacturation,
				changeJoursLivraison,
				changeJourLivraison,
				changeConditionPaiement,
				changeHeureLimite,
				changeNomContactReferent,
				changeEmailContactReferent,
				changePrenomContactReferent,
				changeTelephoneContactReferent,
				changeNumeroTVAIntracommunautaire,
				changeFranco,
				changeDatesFermetureExceptionnelle,
				changeTest,
				changeActif,
				changeIdPayement,
				changeContactType,
				changeContactNom,
				changeContactPrenom,
				changeContactEmail,
				changeContactTelephone,
				submit,
			}}
		/>
	);
}

export default EditionFournisseur;
