import React, { useState } from 'react';
import BtnBack from '../../Common/BtnBack/BtnBack';
import Button from '../../Common/Button/Button';
import Dropzone from '../../Common/Dropzone/Dropzone';
import ErrorMessage from '../../Common/Form/ErrorMessage/ErrorMessage';
import Form from '../../Common/Form/Form';
import Input from '../../Common/Form/Input/Input';
import TableHeader from '../../Common/Table/TableHeader/TableHeader';
import TableWrapper from '../../Common/Table/TableWrapper/TableWrapper';
import styles from './FormulaireActualite.module.scss';

export interface Props {
	type: 'Édition' | 'Création';

	error?: string;
	loading?: boolean;

	url: string;
	titre: string;
	contenu: string;
	imageSrc: string;
	embedVal: string;
	setEmbedVal: (val: string) => void;
	changeUrl: (val: string) => void;
	changeTitre: (val: string) => void;
	changeContenu: (val: string) => void;
	changeImageSrc: (val: string) => void;
	setImageFile: (val: File) => void;

	submit: () => void;
}

function FormulaireActualite({
	type,
	error,
	loading,
	url,
	titre,
	contenu,
	imageSrc,
	submit,
	changeUrl,
	changeTitre,
	setImageFile,
	changeContenu,
	setEmbedVal,
	embedVal,
	changeImageSrc,
}: Props) {
	const [propert, setProp] = useState(false);

	function embedCh(val: any) {
		setEmbedVal(val);
	}

	return (
		<TableWrapper>
			<TableHeader>
				<BtnBack to="/admin" className={styles.btnBack} withText />
				<p className={styles.title}>{type} d'une actualité</p>
			</TableHeader>

			<Form onSubmit={submit} className={styles.form}>
				<div className={styles.uplct}>
					<span className={styles.embed} onClick={() => setProp(!propert)}>
						{!propert ? 'Ajouter une vidéo' : 'Image'}
					</span>
					<div className={styles.upload}>
						{propert ? (
							<div>
								<Input
									label="ajouter une vidéo (lien de partage youtube)"
									type="text"
									value={embedVal}
									setValue={(val) => embedCh(val)}
									className={styles.inputTitre}
									required
								/>

								<div className={styles.videoresponsive}>
									<iframe
										className={styles.videoResponsiveIframe}
										src={
											embedVal.includes('youtu')
												? `https://www.youtube.com/embed/${embedVal.replace(
														'https://youtu.be/',
														'',
												  )}`
												: `https://www.youtube.com/embed/${embedVal}`
										}
										frameBorder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowFullScreen
										title="Embedded youtube"
									/>
								</div>
							</div>
						) : (
							<>
								<Dropzone
									required
									imageSrc={imageSrc}
									imageWidthToDisplay={600}
									onChange={({ tempURL, file }) => {
										setImageFile(file);
										changeImageSrc(tempURL);
									}}
								/>
							</>
						)}
					</div>
				</div>

				<div className={styles.right}>
					<Input
						label="Titre"
						type="text"
						value={titre}
						setValue={changeTitre}
						className={styles.inputTitre}
						required
					/>

					<Input
						label="Contenu"
						type="textarea"
						value={contenu}
						setValue={changeContenu}
						required
					/>

					<Input label="Lien" type="text" value={url} setValue={changeUrl} />

					<ErrorMessage>{error}</ErrorMessage>

					<Button submit loading={loading} className={styles.btnSubmit}>
						{type === 'Édition' ? "Éditer l'actualité" : "Publier l'actualité"}
					</Button>
				</div>
			</Form>
		</TableWrapper>
	);
}

export default FormulaireActualite;
