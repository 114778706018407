import { loader } from 'graphql.macro';
import React, { ComponentProps, useEffect, useState } from 'react';
import { useUnmount } from '../../../hooks/useUnmount';
import { Fournisseur } from '../../../types';
import request from '../../../utils/request';
import Error404 from '../../Common/Error404/Error404';
import AdresseFacturation from './AdresseFacturation/AdresseFacturation';
import AdressePrincipale from './AdressePrincipale/AdressePrincipale';
import ConditionsPaiement from './ConditionsPaiement/ConditionsPaiement';
import styles from './FicheFournisseur.module.scss';
import Horaires from './Horaires/Horaires';
import InformationsLegales from './InformationsLegales/InformationsLegales';
import InfosGenerales from './InfosGenerales/InfosGenerales';
import Livraison from './Livraison/Livraison';
import Presentation from './Presentation/Presentation';

const query = loader('./getFournisseur.graphql');

interface QueryResponse {
	fournisseur:
		| null
		| ((Pick<Fournisseur, 'numeroTVAIntracommunautaire'> &
				ComponentProps<typeof AdressePrincipale>['data'] &
				ComponentProps<typeof Horaires>['data'] &
				ComponentProps<typeof Livraison>['data'] &
				ComponentProps<typeof Presentation>['data'] &
				ComponentProps<typeof InfosGenerales>['data'] &
				ComponentProps<typeof AdresseFacturation>['data'] &
				ComponentProps<typeof ConditionsPaiement>['data']) &
				ComponentProps<typeof InformationsLegales>['data']);
}

function FicheFournisseur({ id }: { id: string }) {
	const { isMounted } = useUnmount();

	const [error, setError] = useState('');
	const [f, setF] = useState<QueryResponse['fournisseur']>(null);

	useEffect(() => {
		request<QueryResponse>(query, { id })
			.then(({ fournisseur }) => {
				if (!isMounted.current) return;
				if (!fournisseur) {
					setError(`Ce fournisseur n'existe pas`);
				} else {
					setF(fournisseur);
					setError('');
				}
			})
			.catch((err) => setError(err.message));
	}, [id, isMounted]);

	return error ? (
		<Error404>{error}</Error404>
	) : (
		<div className={styles.wrapper}>
			<div className={styles.column}>
				<InfosGenerales data={f} />
			</div>

			<div className={styles.column}>
				<InformationsLegales data={f} />
				<Presentation data={f} />
			</div>

			<div className={styles.column}>
				<Horaires data={f} />
				<Livraison data={f} />
			</div>

			<div className={styles.column}>
				<AdressePrincipale data={f} />
				<AdresseFacturation data={f} />
				<ConditionsPaiement data={f} />
			</div>
		</div>
	);
}

export default FicheFournisseur;
