import React from 'react';
import styles from './Input.module.scss';

interface ReadOnly {
	setValue?: undefined;
	readOnly: true;
}

interface Editable {
	setValue: (value: string) => void;
	readOnly?: boolean;
}

interface Common {
	label: string;
	value?: string | number;
	type?: 'text' | 'email' | 'textarea' | 'password' | 'url' | 'number';
	minLength?: number;
	required?: boolean;
	autoFocus?: boolean;
	className?: string;
	placeholder?: string;
	pattern?: string;
	title?: string;
	loading?: boolean;
}

export type Props = Common & (Editable | ReadOnly);

function Input({
	label,
	type = 'text',
	value,
	setValue,
	readOnly,
	minLength,
	pattern,
	placeholder,
	required,
	autoFocus,
	className,
	title,
	loading,
}: Props) {
	return (
		<label
			className={[styles.label, loading && styles.loading, className]
				.filter(Boolean)
				.join(' ')}
		>
			{type !== 'textarea' ? (
				<input
					type={type}
					value={value}
					minLength={minLength}
					pattern={pattern}
					required={required}
					className={styles.input}
					onChange={(e) => !readOnly && setValue!(e.target.value)}
					autoFocus={autoFocus}
					placeholder={placeholder}
					title={title}
					readOnly={readOnly || loading}
					step="any"
				/>
			) : (
				<textarea
					value={value}
					minLength={minLength}
					required={required}
					className={[styles.input, styles.textarea].join(' ')}
					onChange={(e) => !readOnly && setValue!(e.target.value)}
					placeholder={placeholder}
					autoFocus={autoFocus}
					title={title}
					readOnly={readOnly || loading}
				/>
			)}
			<span className={styles.text}>{label}</span>
		</label>
	);
}

export default Input;
