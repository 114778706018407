import React from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';
import { AppState } from '../../../store';
import { closePanier, emptyPanier } from '../../../store/panier/actions';
import { PanierState } from '../../../store/panier/types';
import { media } from '../../../utils/breakpoints/breakpoints';
import BtnPoubelle from '../../Common/BtnPoubelle/BtnPoubelle';
import Button from '../../Common/Button/Button';
import Count from '../../Common/Count/Count';
import LignePanier from './LignePanier/LignePanier';
import styles from './Panier.module.scss';

export interface Props extends PanierState {
	inverted?: boolean;
	emptyPanier: typeof emptyPanier;
	closePanier: typeof closePanier;
}

export function _Panier({
	lignes,
	emptyPanier,
	closePanier,
	loadingInfoProduits,
}: Props) {
	const countLignes = lignes.length;

	return (
		<div className={styles.wrapper}>
			{/* Scrollable */}
			<div className={styles.scrollable}>
				{/* Header */}
				<header className={styles.header}>
					<div>
						{/* Titre */}
						<h1 className={styles.title}>Commande en cours</h1>

						{/* Nbr total de lignes */}
						{loadingInfoProduits ? (
							<p className={styles.subtitle}>Chargement...</p>
						) : (
							<p className={styles.subtitle}>
								{countLignes ? <Count count={countLignes} /> : 'Aucun'} produit
								{countLignes > 1 && 's'} dans votre panier
							</p>
						)}
					</div>

					{/* Vider panier */}
					{!!countLignes && (
						<BtnPoubelle
							title="Vider le panier"
							className={styles.delete}
							onClick={emptyPanier}
						/>
					)}
				</header>

				{lignes.map(ligne => (
					<LignePanier key={ligne.produit.id} {...ligne} />
				))}
			</div>

			{/* Lien vers commande en cours */}
			{!!countLignes && !lignes.find(l => l.produit.disabled) && (
				<div className={styles.cta}>
					<Link
						to="/commande-en-cours"
						title="Accéder à la commande en cours"
						onClick={closePanier}
					>
						<MediaQuery query={media['<laptop']}>
							<Button>Voir ma commande</Button>
						</MediaQuery>
						<MediaQuery query={media['>=laptop']}>
							<Button inverted>Voir ma commande</Button>
						</MediaQuery>
					</Link>
				</div>
			)}
		</div>
	);
}

function mapStateToProps(state: AppState) {
	return state.panier;
}

export default connect(mapStateToProps, { emptyPanier, closePanier })(_Panier);
