import React from 'react';
import { Pick2 } from 'ts-multipick';
import { Fournisseur } from '../../../../types';
import Section from '../Section/Section';
import Skeleton from '../Skeleton/Skeleton';

interface Props {
	data: null | Pick2<Fournisseur, 'adresse', 'voie' | 'codePostal' | 'ville'>;
}

function AdressePrincipale({ data }: Props) {
	return (
		<Section title="Adresse principale">
			{data ? (
				<>
					{data.adresse.voie},<br />
					{data.adresse.codePostal} {data.adresse.ville}
				</>
			) : (
				<>
					<Skeleton size="big" />
					<br />
					<Skeleton size="small" />
				</>
			)}
		</Section>
	);
}

export default AdressePrincipale;
