import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTitle } from '../../../hooks/useTitle';
import { french } from '../../../utils/french';
import request from '../../../utils/request';
import BtnEdit from '../../Common/BtnEdit/BtnEdit';
import Button from '../../Common/Button/Button';
import Table from '../../Common/Table/Table';
import TableWrapper from '../../Common/Table/TableWrapper/TableWrapper';
import { RowProps } from '../../Common/Table/types';
import styles from '../Clients/Clients.module.scss';

const queryActu = loader('./queries/getAdmins.graphql');

function Admins() {
	useTitle('Admins');

	const [clients, setClients] = useState<[]>([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');

	useEffect(() => {
		let isMounted = true;
		setLoading(true);
		setError('');

		type TResponse = { admins: [] };
		request<TResponse>(queryActu)
			.then((res) => {
				isMounted && setClients(res.admins);
			})
			.catch((err) => setError(err.message))
			.finally(() => setLoading(false));

		return () => {
			isMounted = false;
		};
	}, []);

	function deleteActu(id: string) {
		setError('');

		// request(mutationDeleteActu, { id })
		// 	.then(() => setActualites(actualites.filter(a => a.id !== id)))
		// 	.catch(err => setError(err.message));
	}

	function connect(id: string) {
		setError('');
	}

	async function adminEdit(id: string) {
		setError('');
		// const response = await request<UserResponse>(loginWithId, { id, model: 'Client' });

		// window.location.href = '';
		window.location.href = `/admin/edition/${id}`;
		// window.location.reload()
	}

	function invertActus(index1: number, index2: number) {
		// On récupère les actus
		// const actu1: Actualite | null = actualites[index1];
		// const actu2: Actualite | null = actualites[index2];

		// Annule si une des deux n'existe pas (donc qu'on est à une extrémité de la liste)
		// if (!actu1 || !actu2) return;

		// On récupère les ids et ordres de chaque actu (nécessaire pour casser la référence objet)
		// const { id: id1, ordre: ordre1 } = actu1;
		// const { id: id2, ordre: ordre2 } = actu2;

		setError('');

		// request(mutationChangeOrderActu, { id1, id2, ordre1, ordre2 })
		// 	.then(() => {
		// 		const actus = actualites.map(a => {
		// 			if (a.id === actu1.id) a.ordre = ordre2;
		// 			else if (a.id === actu2.id) a.ordre = ordre1;
		// 			return a;
		// 		});
		// 		setActualites(actus.sort((a, b) => a.ordre - b.ordre));
		// 	})
		// 	.catch(err => setError(err.message));
	}

	return (
		<TableWrapper>
			<div>
				<div className={styles.buttonWrapper}>
					<Link to={{ pathname: '/admin/creation' }}>
						<Button submit loading={loading}>
							Ajouter un admin
						</Button>
					</Link>
				</div>
			</div>
			<Table
				error={error}
				loading={loading}
				noResultMessage={'Aucun Clients'}
				columns={[
					{ content: 'id', center: true },
					{ content: 'login', center: true },
					{ content: 'email', center: true },
					{ content: 'nom', center: true },
					{ content: 'prenom', center: true },
					{ content: 'Edition', isButton: true, center: true },
				]}
				rows={clients.map(({ id, login, email, nom, prenom }, i) => {
					return {
						id,
						mobileTitle: (
							<div className={styles.mobileTitle}>{french(login)}</div>
						),
						href: '/admin/edition/' + id,
						cells: [
							{ content: french(id), className: styles.actuTitle },
							{ content: french(login), className: styles.actuTitle },
							{ content: french(email), className: styles.actuTitle },
							{ content: french(nom), className: styles.actuTitle },
							{ content: french(prenom), className: styles.actuTitle },
							{
								content: (
									<BtnEdit
										title="Edition"
										onClick={() => {
											adminEdit(id);
										}}
									/>
								),
							},
						],
					} as RowProps;
				})}
			/>

			{/*<div className={styles.buttonWrapper}>*/}
			{/*	<Link to={{ pathname: '/actualites/creation' }}>*/}
			{/*		<Button>Ajouter une actualité</Button>*/}
			{/*	</Link>*/}
			{/*</div>*/}
		</TableWrapper>
	);
}

export default Admins;
