import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppState } from '../../../../store';
import { CategoriesState } from '../../../../store/categories/types';

export interface HOCProps {
	categories: CategoriesState['list'];
	loadingCategories: CategoriesState['loading'];
	errorCategories?: CategoriesState['error'];

	activeCategorie?: CategoriesState['list'][0];
	activeCategorieSlug: string;

	activeSousCategorie?: CategoriesState['list'][0]['sousCategories'][0];
	activeSousCategorieSlug: string;

	search: RouteComponentProps['location']['search'];
	pathname: RouteComponentProps['location']['pathname'];
}

interface ComponentProps extends RouteComponentProps {
	categories: CategoriesState;
}

export function withCategories(WrappedComponent: FunctionComponent<HOCProps>) {
	function WithData({
		categories,
		location: { pathname, search },
	}: ComponentProps) {
		const searchParams = new URLSearchParams(search);

		const activeCategorieSlug = searchParams.get('categorie') || '';
		const activeSousCategorieSlug = searchParams.get('sous-categorie') || '';

		const activeCategorie = categories.list.find(
			c => c.slug === activeCategorieSlug,
		);

		return (
			<WrappedComponent
				{...{
					search,
					pathname,
					activeCategorie,
					activeCategorieSlug,
					activeSousCategorieSlug,
					categories: categories.list,
					errorCategories: categories.error,
					loadingCategories: categories.loading,
				}}
			/>
		);
	}

	function mapStateToProps(state: AppState) {
		return { categories: state.categories };
	}

	return withRouter(connect(mapStateToProps)(WithData));
}
