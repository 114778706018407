import React, { useState } from 'react';
import BtnMenu from './BtnMenu/BtnMenu';
import PopinMenu from './PopinMenu/PopinMenu';

const btnMenuId = 'btnMenuId';

interface Props {
	links: { title: string; to: string }[];
}

function MenuGeneral({ links }: Props) {
	const [isMenuOpen, setMenuOpen] = useState(false);

	return (
		<>
			<BtnMenu
				id={btnMenuId}
				isMenuOpen={isMenuOpen}
				openMenu={() => setMenuOpen(true)}
				closeMenu={() => setMenuOpen(false)}
			/>

			<PopinMenu
				links={links}
				id={btnMenuId}
				isOpen={isMenuOpen}
				closeMenu={() => setMenuOpen(false)}
			/>
		</>
	);
}

export default MenuGeneral;
