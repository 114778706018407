import {
	GET_SECTEURS_ACTIVITE_FAIL,
	GET_SECTEURS_ACTIVITE_START,
	GET_SECTEURS_ACTIVITE_SUCCESS,
	SecteursActiviteActionTypes,
	SecteursActiviteState,
} from './types';

const initialState: SecteursActiviteState = {
	loading: true,
	error: null,
	list: [],
};

export function secteursActiviteReducer(
	state = initialState,
	action: SecteursActiviteActionTypes,
): SecteursActiviteState {
	switch (action.type) {
		case GET_SECTEURS_ACTIVITE_START:
			return {
				...state,
				loading: true,
				error: null,
			};

		case GET_SECTEURS_ACTIVITE_SUCCESS:
			return {
				...state,
				loading: false,
				list: action.list,
			};

		case GET_SECTEURS_ACTIVITE_FAIL:
			return {
				...state,
				loading: false,
				error: action.error,
			};

		default:
			return state;
	}
}
