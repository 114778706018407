import { ThunkAction } from 'redux-thunk';
import { Pick2 } from 'ts-multipick';
import {
	CommandeLigne,
	JourDeLaSemaine,
	Label,
	Produit,
	Tarif,
} from '../../types';

export interface Panier {
	nom?: string;
	favorite: boolean;
	dateLivraison?: Date;
	lignes: PanierLigne[];
	recurrenceMois?: number;
	recurrenceSemaine?: JourDeLaSemaine[];
}

export interface PanierFav {
	nom?: string;
	favorite: boolean;
	dateLivraison?: Date;
	recurrenceMois?: number;
	lignes?: PanierLigne[];
	recurrenceSemaine?: JourDeLaSemaine[];
}

export interface PanierLigne {
	quantite: CommandeLigne['quantite'];
	commentaire: String | null | undefined,
	readonly ancienPrix: Tarif['prix'];
	readonly produit: Pick<CommandeLigne['produit'],
		'id' | 'nom' | 'unite' | 'imageSrc' | 'disabled' | 'isFavoriteForMe' | 'aucour' | 'codeFournisseur'> & {
		labels: Pick<Label, 'abreviation'>[];
		tarif?: Pick<Tarif, 'prix'> &
			Pick2<Tarif, 'fournisseur', 'id' | 'enseigne' | 'dateLivraison' | 'datesFermetureExceptionnelle' | 'franco' | 'timeLivraison' | 'optionLivraison' | 'dayDelivery'>;
	};
}

export type LocalStoragePanier = Omit<Panier, 'lignes'> & {
	lignes: LocalStoragePanierLigne[];
	comments: any[]
};

export type LocalStoragePanierLigne =
	Pick<PanierLigne,
		'quantite' | 'ancienPrix'>
	& { readonly idProduit: Produit['id'], uc: String | undefined | null, commentaire: String | undefined | null, fournisseurId: String | undefined | null };

export interface PanierState extends Panier {
	isOpen: boolean;
	errorInfoProduits?: string;
	loadingInfoProduits: boolean;
	isPopinRecurrenceOpen: boolean;
}

// Open / Close Panier
export const OPEN_PANIER = 'OPEN_PANIER';

interface OpenPanierAction {
	type: typeof OPEN_PANIER;
}

export const CLOSE_PANIER = 'CLOSE_PANIER';

interface ClosePanierAction {
	type: typeof CLOSE_PANIER;
}

// Get panier
export const GET_INFO_PRODUITS_PANIER_START = 'GET_INFO_PRODUITS_PANIER_START';

interface GetInfoProduitsPanierStartAction {
	type: typeof GET_INFO_PRODUITS_PANIER_START;
}

export const GET_INFO_PRODUITS_PANIER_SUCCESS =
	'GET_INFO_PRODUITS_PANIER_SUCCESS';

interface GetInfoProduitsPanierSuccessAction {
	type: typeof GET_INFO_PRODUITS_PANIER_SUCCESS;
	lignes: Panier['lignes'];
}

export const GET_INFO_PRODUITS_PANIER_FAIL = 'GET_INFO_PRODUITS_PANIER_FAIL';

interface GetInfoProduitsPanierFailAction {
	type: typeof GET_INFO_PRODUITS_PANIER_FAIL;
	error: string;
}

// Manipulate Produits
export const ADD_PRODUIT_TO_PANIER = 'ADD_PRODUIT_TO_PANIER';

interface AddProduitToPanierAction {
	type: typeof ADD_PRODUIT_TO_PANIER;
	ligne: PanierLigne;
}

export const CHANGE_LIGNE_PANIER = 'CHANGE_LIGNE_PANIER';

interface ChangeLignePanierAction {
	type: typeof CHANGE_LIGNE_PANIER;
	quantite: number;
	idProduit: string;
}

export const REMOVE_PRODUIT_FROM_PANIER = 'REMOVE_PRODUIT_FROM_PANIER';

interface RemoveProduitFromPanierAction {
	type: typeof REMOVE_PRODUIT_FROM_PANIER;
	idProduit: string;
}

export const EMPTY_PANIER = 'EMPTY_PANIER';

interface EmptyPanierStartAction {
	type: typeof EMPTY_PANIER;
}

export const CHANGE_NOM_PANIER = 'CHANGE_NOM_PANIER';

interface ChangeNomPanierAction {
	type: typeof CHANGE_NOM_PANIER;
	nom: string;
}

export const CHANGE_DATE_LIVRAISON_PANIER = 'CHANGE_DATE_LIVRAISON_PANIER';

interface ChangeDateLivraisonPanierAction {
	type: typeof CHANGE_DATE_LIVRAISON_PANIER;
	date: Date;
}


export const SET_GLOBAL_COMMENT_FOR_FOURNISSEUR = 'SET_GLOBAL_COMMENT_FOR_FOURNISSEUR';

interface SetGlobalCommentForFournisseur {
	type: typeof SET_GLOBAL_COMMENT_FOR_FOURNISSEUR;
	date: Date;
}


export const SET_PANIER_AS_COMMANDE_FAVORITE =
	'SET_PANIER_AS_COMMANDE_FAVORITE';

interface SetPanierAsCommandeFavoriteAction {
	type: typeof SET_PANIER_AS_COMMANDE_FAVORITE;
	favorite: boolean;
}

// Récurrence
export const SET_PANIER_RECURRENCE = 'SET_PANIER_RECURRENCE';

interface SetPanierRecurrenceAction {
	type: typeof SET_PANIER_RECURRENCE;
	recurrenceMois?: number;
	recurrenceSemaine?: JourDeLaSemaine[];
}

export const REMOVE_PANIER_RECURRENCE = 'REMOVE_PANIER_RECURRENCE';

interface RemovePanierRecurrenceAction {
	type: typeof REMOVE_PANIER_RECURRENCE;
}

// Reset
export const RESET_PANIER = 'RESET_PANIER';

interface ResetPanierAction {
	type: typeof RESET_PANIER;
}

export type PanierActionTypes =
	| OpenPanierAction
	| ClosePanierAction
	| GetInfoProduitsPanierStartAction
	| GetInfoProduitsPanierSuccessAction
	| GetInfoProduitsPanierFailAction
	| AddProduitToPanierAction
	| ChangeLignePanierAction
	| RemoveProduitFromPanierAction
	| SetGlobalCommentForFournisseur
	| EmptyPanierStartAction
	| ChangeNomPanierAction
	| ChangeDateLivraisonPanierAction
	| SetPanierAsCommandeFavoriteAction
	| SetPanierRecurrenceAction
	| RemovePanierRecurrenceAction
	| ResetPanierAction;

export type PanierThunkResult = ThunkAction<any,
	PanierState,
	{},
	PanierActionTypes>;
