import React from 'react';
import { Unite } from '../../../types';
import { convertUnite, french, thinSpace } from '../../../utils/french';
import styles from './Prix.module.scss';

export interface Props {
	prix?: number | string;
	unite?: Unite;
	className?: string;
	afficherNA?: boolean;
	aucour?: boolean;
}

function Prix({ prix, unite, className, afficherNA, aucour }: Props) {
	return aucour ? (
		<div>
			<strong className={className}>
				<span className={styles.big}>Au cours</span>
				{/*{unite && thinSpace + '/' + thinSpace + convertUnite(unite)}*/}
			</strong>
		</div>
	) : prix && typeof prix === 'number' ? (
		<strong className={className}>
			<span className={styles.big}>{french(prix.toFixed(2))}</span>
			{thinSpace}€{unite && thinSpace + '/' + thinSpace + convertUnite(unite)}
		</strong>
	) : afficherNA ? (
		<strong
			title="Prix non disponible"
			className={[className, styles.na].filter(Boolean).join(' ')}
		>
			N/A
		</strong>
	) : (
		<strong className={className}>
			<span className={styles.big}>N/A</span>
			{/*{unite && thinSpace + '/' + thinSpace + convertUnite(unite)}*/}
		</strong>
	);
}

export default Prix;
