import React from 'react';
import Button from '../../../Common/Button/Button';
import InfoRecurrence from '../InfoRecurrence/InfoRecurrence';
import { RecurrenceMois, RecurrenceSemaine } from '../Recurrence';
import styles from './BtnRecurrence.module.scss';
import { ReactComponent as PictoHorloge } from './horloge.svg';

export interface Props {
	btnId: string;
	className?: string;
	onBtnClick: Function;
	mois?: RecurrenceMois;
	semaine?: RecurrenceSemaine;
}

function BtnRecurrence({ btnId, mois, semaine, onBtnClick, className }: Props) {
	const hasRecurrence = !!((semaine && semaine.length) || mois);

	return (
		<div className={[styles.wrapper, className].filter(Boolean).join(' ')}>
			{/* Récurrence mois */}
			{mois && <InfoRecurrence mois={mois} />}

			{/* Récurrence semaine */}
			{semaine && !!semaine.length && <InfoRecurrence semaine={semaine} />}

			{/* Trait */}
			{hasRecurrence && <hr className={styles.separator} />}

			{/* Button */}
			<Button
				id={btnId}
				className={styles.btnRecurrence}
				onClick={onBtnClick}
				inverted={true}
				outline
			>
				<PictoHorloge className={styles.pictoHorloge} />
				{hasRecurrence ? 'Modifier' : 'Créer commande récurrente'}
			</Button>
		</div>
	);
}

export default BtnRecurrence;
