import { JourDeLaSemaine } from '../../../types';
import {
	loadStateFromLocalStorage,
	saveStateToLocalStorage,
} from '../../../utils/localStorage';
import {
	PanierActionTypes,
	REMOVE_PANIER_RECURRENCE,
	SET_PANIER_RECURRENCE,
} from '../types';

// Set recurrence
type RecurrenceMois = { mois: number; semaine?: undefined };
type RecurrenceSemaine = { semaine: JourDeLaSemaine[]; mois?: undefined };
export function setPanierRecurrence(
	props: RecurrenceMois | RecurrenceSemaine,
): PanierActionTypes {
	const recurrence = {
		recurrenceMois: props.mois,
		recurrenceSemaine: props.semaine,
	};
	const panier = loadStateFromLocalStorage('panier');
	if (panier) saveStateToLocalStorage('panier', { ...panier, ...recurrence });
	return { type: SET_PANIER_RECURRENCE, ...recurrence };
}

// Remove recurrence
export function removePanierRecurrence(): PanierActionTypes {
	const panier = loadStateFromLocalStorage('panier');
	if (panier) {
		saveStateToLocalStorage('panier', {
			...panier,
			recurrenceMois: undefined,
			recurrenceSemaine: undefined,
		});
	}
	return { type: REMOVE_PANIER_RECURRENCE };
}
