import React, { DragEvent, useState } from 'react';
import styles from './Dropzone.module.scss';
import { ReactComponent as UploadSVG } from './upload.svg';
import xlsxIcon from './xlsx-icon.webp';
export interface Props {
	imageSrc?: string;
	required?: boolean;
	className?: string;
	imageWidthToDisplay: number;
	onChange: (val: { file: File; tempURL: string }) => void;
	register?: any;
	name?: string;
}

function Dropzone({
	imageSrc,
	required,
	className,
	onChange,
	imageWidthToDisplay,
	register,
	name,
}: Props) {
	const [highlight, setHighlight] = useState(false);
	const [isXlsx, setIsXlsx] = useState(false); // Nouvel état pour vérifier si le fichier est un XLSX

	function handleFiles(files: FileList | null) {
		if (!files || !files.length) return;
		const file = files[0];
		const isExcelFile =
			file.type ===
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
			file.name.endsWith('.xlsx');
		setIsXlsx(isExcelFile); // Définir si c'est un fichier XLSX
		if (
			(![
				'image/png',
				'image/jpeg',
				'image/svg+xml',
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			].includes(file.type) &&
				!file.name.endsWith('.xlsx')) || // Vérifie l'extension du fichier
			file.size > 10000000 // 10Mo
		)
			return;
		onChange({ file, tempURL: URL.createObjectURL(file) });
	}

	function onDragOver(e: DragEvent<HTMLLabelElement>) {
		e.preventDefault();
		setHighlight(true);
	}

	function onDrop(e: DragEvent<HTMLLabelElement>) {
		e.preventDefault();
		handleFiles(e.dataTransfer.files);
		setHighlight(false);
	}

	const cta = (
		<div className={styles.cta}>
			<UploadSVG className={styles.icon} />
			<p>
				{name === 'xlsxFile'
					? 'Glisser ici votre fichier produit (Excel, 10Mo max)'
					: 'Téléverser une image (jpeg ou png, 10Mo max)'}
			</p>
		</div>
	);

	return (
		<label
			onDrop={onDrop}
			onDragOver={onDragOver}
			onDragLeave={() => setHighlight(false)}
			className={[styles.wrapper, className, highlight && styles.highlight]
				.filter(Boolean)
				.join(' ')}
		>
			{imageSrc && !isXlsx ? ( // Si une image est présente et que ce n'est pas un fichier XLSX
				<div className={styles.img}>
					<img
						alt=""
						src={imageSrc.replace(
							/@[0-9]+.webp$/,
							`@${imageWidthToDisplay}.webp`,
						)}
					/>
					{cta}
				</div>
			) : isXlsx ? ( // Si c'est un fichier XLSX, on affiche une image générique
				<div className={styles.img}>
					<img alt="Aperçu fichier Excel" src={xlsxIcon} />
					{cta}
				</div>
			) : (
				<div className={styles.dropzone}>{cta}</div>
			)}

			<input
				type="file"
				className={styles.input}
				ref={register}
				accept="image/jpeg, image/png, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				onChange={({ target: { files } }) => handleFiles(files)}
				required={required && !imageSrc}
				name={name}
			/>
		</label>
	);
}

export default Dropzone;
