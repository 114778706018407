import { loader } from 'graphql.macro';
import request from '../../utils/request';
import {
	GET_SECTEURS_ACTIVITE_FAIL,
	GET_SECTEURS_ACTIVITE_START,
	GET_SECTEURS_ACTIVITE_SUCCESS,
	SecteurActivite,
	SecteursActiviteThunkResult,
} from './types';

const query = loader('./getSecteursActivite.graphql');

export function getSecteursActivite(
	type: 'CLIENT' | 'FOURNISSEUR',
): SecteursActiviteThunkResult {
	return async function (dispatch) {
		try {
			dispatch({ type: GET_SECTEURS_ACTIVITE_START });
			type TResponse = { list: SecteurActivite[] };
			const { list } = await request<TResponse>(query, { type });
			dispatch({ type: GET_SECTEURS_ACTIVITE_SUCCESS, list });
		} catch (err) {
			dispatch({
				type: GET_SECTEURS_ACTIVITE_FAIL,
				error: err.message,
			});
		}
	};
}
