import React, { ReactNode, useState } from 'react';
import styles from './Section.module.scss';

interface Props {
	title: string;
	children: ReactNode;
}

function Section({ title, children }: Props) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<h2
				onClick={() => setIsOpen(!isOpen)}
				className={[styles.h2, isOpen && styles.isOpen]
					.filter(Boolean)
					.join(' ')}
			>
				{title}
			</h2>

			<div
				className={[styles.content, isOpen && styles.isOpen]
					.filter(Boolean)
					.join(' ')}
			>
				{children}
			</div>
		</>
	);
}

export default Section;
