import React, { ComponentProps, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { AppState } from '../../../../store';
import { logOut } from '../../../../store/compte/actions';
import { User, UserType } from '../../../../store/compte/types';
import { media } from '../../../../utils/breakpoints/breakpoints';
import { toggleNotifications } from '../../../../utils/notifications/toggleNotifications';
import Popin from '../../Popin/PopinPortal';
import PositionedPopin from '../../Popin/PositionedPopin/PositionedPopin';
import MenuGeneral from '../MenuGeneral';
import styles from './PopinMenu.module.scss';

export interface Props {
	id: string;
	type: UserType;
	isOpen: boolean;
	idUser: User['id'];
	logOut: typeof logOut;
	closeMenu: () => void;
	links: ComponentProps<typeof MenuGeneral>['links'];
}

export function List({
	isOpen,
	idUser,
	closeMenu,
	logOut,
	links,
	type,
}: Props) {
	return (
		<>
			{links.map(({ title, to }, i) => (
				<NavLink
					key={i}
					to={to}
					exact
					className={styles.link}
					activeClassName={styles.current}
					onClick={closeMenu}
					style={{ transitionDelay: isOpen ? i * 30 + 'ms' : '0ms' }}
				>
					{title}
				</NavLink>
			))}

			<span
				className={styles.link}
				style={{ transitionDelay: isOpen ? links.length * 30 + 'ms' : '0ms' }}
				onClick={() => {
					logOut();
					closeMenu();
					toggleNotifications({ disabled: true, idUser, type });
				}}
			>
				Se déconnecter
			</span>
		</>
	);
}

export function _PopinMenu(props: Props) {
	const openRef = useRef(props.isOpen);

	const { closeMenu } = props;

	useEffect(() => {
		const resize = () => openRef.current && closeMenu();
		window.addEventListener('resize', resize);
		return () => window.removeEventListener('resize', resize);
	}, [closeMenu]);

	useEffect(() => {
		openRef.current = props.isOpen;
	});

	const position = useRef({} as ClientRect);
	const btn = document.getElementById(props.id);
	if (btn) position.current = btn.getBoundingClientRect();

	const { width, height } = position.current;

	return (
		<>
			<MediaQuery query={media['<laptop']}>
				<Popin>
					<CSSTransition
						in={props.isOpen}
						timeout={+styles.duration}
						classNames={styles}
						mountOnEnter
						unmountOnExit
					>
						<div className={styles.fullScreen}>
							<div className={styles.cache} onClick={props.closeMenu} />
							<div
								className={styles.close}
								onClick={props.closeMenu}
								style={{ width, height }}
							/>
							<List {...props} />
						</div>
					</CSSTransition>
				</Popin>
			</MediaQuery>

			<MediaQuery query={media['>=laptop']}>
				<PositionedPopin
					isOpen={props.isOpen}
					onClose={props.closeMenu}
					position={position.current}
				>
					<List {...props} />
				</PositionedPopin>
			</MediaQuery>
		</>
	);
}

function mapStateToProps(state: AppState) {
	return {
		idUser: state.compte.id,
		type: state.compte.type,
	};
}
export default connect(mapStateToProps, { logOut })(_PopinMenu);
