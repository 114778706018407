import React from 'react';
import Gradient from '../../../Common/Gradient/Gradient';
import SideMenuWrapper from '../../../Common/SideMenuWrapper/SideMenuWrapper';
import SousCategories from '../Common/SousCategories/SousCategories';
import TitleAndError from '../Common/TitleAndError/TitleAndError';
import { HOCProps, withCategories } from '../Common/withCategories';
import styles from './SousCategoriesMobile.module.scss';

export function _SousCategoriesMobile({ activeCategorie }: HOCProps) {
	return (
		<div className={styles.wrapper}>
			<Gradient />

			<SideMenuWrapper>
				<TitleAndError>
					{activeCategorie ? activeCategorie.nom : ''}
				</TitleAndError>
				<SousCategories />
			</SideMenuWrapper>
		</div>
	);
}

export default withCategories(_SousCategoriesMobile);
