import React from 'react';
import { Fournisseur } from '../../../../types';
import Section from '../Section/Section';
import Skeleton from '../Skeleton/Skeleton';

interface Props {
	data: null | Pick<Fournisseur, 'horairesOuverture'>;
}

function Horaires({ data }: Props) {
	return (
		<Section title="Horaires d'ouverture">
			{data ? data.horairesOuverture : <Skeleton size="big" />}
		</Section>
	);
}

export default Horaires;
