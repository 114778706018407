import React from 'react';
import { Contact } from '../../../types';
import { thinSpace } from '../../../utils/french';
import CenteredPopin from '../Popin/CenteredPopin/CenteredPopin';
import styles from './PopinContacts.module.scss';

interface Props {
	isOpen: boolean;
	close: () => void;
	contacts: Pick<Contact, 'type' | 'nom' | 'prenom' | 'email' | 'telephones'>[];
}

function PopinContacts({ contacts, isOpen, close }: Props) {
	return (
		<CenteredPopin isOpen={isOpen} onCacheClick={close}>
			<h1 className={styles.title}>Contacts</h1>

			{contacts.map((contact, i) => (
				<div key={i} className={styles.contact}>
					<h2 className={styles.type}>{contact.type + thinSpace}:</h2>

					<p>
						{contact.prenom} {contact.nom}
					</p>

					<p className={styles.email}>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={`mailto:${contact.email}`}
						>
							{contact.email}
						</a>
					</p>

					{contact.telephones.map((tel, o) => (
						<p key={o} className={styles.tel}>
							<a target="_blank" rel="noopener noreferrer" href={`tel:${tel}`}>
								{tel}
							</a>
						</p>
					))}
				</div>
			))}
		</CenteredPopin>
	);
}

export default PopinContacts;
