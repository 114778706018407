import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useUnmount } from '../../../hooks/useUnmount';
import request from '../../../utils/request';
import BtnBack from '../../Common/BtnBack/BtnBack';
import Button from '../../Common/Button/Button';
import Checkbox from '../../Common/Checkbox/Checkbox';
import Dropzone from '../../Common/Dropzone/Dropzone';
import ErrorMessage from '../../Common/Form/ErrorMessage/ErrorMessage';
import Form from '../../Common/Form/Form';
import Input from '../../Common/Form/Input/Input';
import TableHeader from '../../Common/Table/TableHeader/TableHeader';
import TableWrapper from '../../Common/Table/TableWrapper/TableWrapper';
import styles from './FormulaireClient.module.scss';
const getSecteurActiviteQuery = loader(
	'../EditionFournisseur/getSecteurActivite.graphql',
);

type Option = {
	value: string;
	label: string;
};

export interface Props {
	type: 'Édition' | 'Création';
	backLink: string;

	error?: string;
	name?: string;
	loading?: boolean;

	disabled: string;
	login: string;
	email: string;
	nomContactReferent: string;
	prenomContactReferent: string;
	emailContactReferent: string;
	telephone1ContactReferent: string;
	telephone2ContactReferent: string;
	nomContactCuisine: string;
	prenomContactCuisine: string;
	emailContactCuisine: string;
	telephoneContactCuisine: string;
	nomContactCompta: string;
	prenomContactCompta: string;
	emailContactCompta: string;
	telephoneContactCompta: string;
	secteurActivite: string;
	numeroVoieLivraison: string;
	codePostalLivraison: string;
	villeLivraison: string;
	numeroVoieFacturation: string;
	codePostalFacturation: string;
	villeFacturation: string;
	raisonSociale: string;
	enseigne: string;
	siret: string;
	description: string;
	search: string;
	prelevementAuto: string;
	photo: string;
	kbis: string;
	rib: string;
	iban: string;
	bic: string;
	banque: string;

	changeClients: any;
	clients: any;

	horairesOuverture: string;
	dateFermetureExceptionnelle: string;
	fournisseursAutorise: any[];
	contacts: string;

	changeDisabled: (val: string) => void;
	changeLogin: (val: string) => void;
	changeEmail: (val: string) => void;
	changeNomContactReferent: (val: string) => void;
	changePrenomContactReferent: (val: string) => void;
	changeEmailContactReferent: (val: string) => void;
	changeTelephone1ContactReferent: (val: string) => void;
	changeTelephone2ContactReferent: (val: string) => void;
	changeNomContactCuisine: (val: string) => void;
	changePrenomContactCuisine: (val: string) => void;
	changeEmailContactCuisine: (val: string) => void;
	changeTelephoneContactCuisine: (val: string) => void;
	changeNomContactCompta: (val: string) => void;
	changePrenomContactCompta: (val: string) => void;
	changeEmailContactCompta: (val: string) => void;
	changeTelephoneContactCompta: (val: string) => void;
	changeSecteurActivite: (val: string) => void;
	changeNumeroVoieLivraison: (val: string) => void;
	changeCodePostalLivraison: (val: string) => void;
	changeVilleLivraison: (val: string) => void;
	changeNumeroVoieFacturation: (val: string) => void;
	changeCodePostalFacturation: (val: string) => void;
	changeVilleFacturation: (val: string) => void;
	changeRaisonSociale: (val: string) => void;
	changeEnseigne: (val: string) => void;
	changeSiret: (val: string) => void;
	changeDescription: (val: string) => void;
	changeSearch: (val: string) => void;
	changePrelevementAuto: (val: string) => void;
	changePhoto: (val: string) => void;
	setPhoto: (val: File) => void;

	changeKbis: (val: string) => void;
	changeIban: (val: string) => void;
	changeBic: (val: string) => void;
	changeBanque: (val: string) => void;
	changeFournisseursAutoriseVal: (val: any) => void;
	fournisseursAutoriseVal: string;
	setKbis: (val: File) => void;
	setRib: (val: File) => void;

	changeRib: (val: string) => void;
	changeHorairesOuverture: (val: string) => void;
	changeDateFermetureExceptionnelle: (val: string) => void;
	changeFournisseursAutorise: (val: any) => void;
	changeContacts: (val: string) => void;
	submit: () => void;
}

function FormulaireClient({
	backLink,
	name,
	type,
	error,
	loading,
	disabled,
	login,
	email,
	secteurActivite,
	numeroVoieFacturation,
	codePostalFacturation,
	villeFacturation,
	raisonSociale,
	enseigne,
	siret,
	description,
	prelevementAuto,
	photo,
	kbis,
	rib,
	iban,
	bic,
	banque,
	horairesOuverture,
	dateFermetureExceptionnelle,
	fournisseursAutorise,
	changeFournisseursAutoriseVal,
	changeDisabled,
	changeRaisonSociale,
	changeEnseigne,
	changeSiret,
	changeSecteurActivite,
	changeNumeroVoieFacturation,
	changeCodePostalFacturation,
	changeVilleFacturation,
	changeLogin,
	changeEmail,
	changeDescription,
	changePrelevementAuto,
	changePhoto,
	setPhoto,
	changeKbis,
	setKbis,
	setRib,
	changeRib,
	changeBanque,
	changeBic,
	changeIban,
	changeHorairesOuverture,
	changeDateFermetureExceptionnelle,
	clients,
	changeClients,
	submit,
}: Props) {
	const [] = useState(false);
	const [] = useState(false);
	const [fournisseursAvailable, setFournisseursAvailable] = useState<Option[]>(
		[],
	);
	const [newSecteurActivite, setNewSecteurActivite]: any = useState();
	const { isMounted } = useUnmount();
	const url = window.location.pathname.split('/')[2];

	// if (url === 'creation') {
	// 	changePrelevementAuto(check2 as any);
	// }
	// if (url === 'creation') {
	// 	changeDisabled(check as any);
	// }
	function pushClient() {
		let loadClient = [...clients];
		loadClient.push({ email: '', nom: '', first: '' });
		changeClients(loadClient as any);
	}

	function deleteClient(index: any) {
		let loadCLient = [...clients];
		changeClients(loadCLient.splice(0, index) as any);
	}

	useEffect(() => {
		const options: Option[] = fournisseursAutorise.map((fournisseur) => ({
			value: fournisseur.id,
			label: fournisseur.enseigne,
		}));
		setFournisseursAvailable(options);
	}, [fournisseursAutorise]);

	useEffect(() => {
		type TResponse = { secteursActivite: [] };
		request<TResponse>(getSecteurActiviteQuery)
			.then((res) => {
				if (res) {
					setNewSecteurActivite(res.secteursActivite);
				}
			})
			.catch(() => {});

		return () => {};
	}, [isMounted]);
	function changeValContact(index: any, key: string, val: any) {
		if (key === 'nom') {
			clients[index].nom = val;
			// if (type === 'Édition') {
			// 	console.log(val);
			// } else {
			// 	clients[index].nom = clients[index].nom + val;
			// }
		}

		if (key === 'email') {
			clients[index].email = val;
			// if (type === 'Édition') {
			// 	console.log(val);
			// } else {
			// 	clients[index].email = clients[index].email + val;
			// }
		}

		if (key === 'first') {
			clients[index].first = val;
			// if (type === 'Édition') {
			// 	console.log(val);
			// } else {
			// 	clients[index].first = clients[index].first + val;
			// }
		}

		if (key === 'type') {
			clients[index].type = val;
		}
		if (key === 'tel') {
			clients[index].tel = val;
		}
	}

	function handleChangeFournisseur(val: any) {
		changeFournisseursAutoriseVal(val.value);
	}

	return (
		<TableWrapper>
			<TableHeader>
				<BtnBack to={backLink} className={styles.btnBack} withText />
				<p className={styles.title}>
					{type} d'un {name ?? 'Client'}
				</p>
			</TableHeader>

			<Form onSubmit={submit} className={styles.newform}>
				<div className={styles.container}>
					<div className={styles.table1}>
						<div className={styles.disabled}>
							{url === 'edition' ? (
								<label className={styles.label}>Inactif</label>
							) : (
								''
							)}

							{url === 'edition' ? (
								// <input
								// 	name="isGoing"
								// 	type="checkbox"
								// 	checked={disabled as any}
								// 	onChange={() => {
								// 		setCheck(!check);
								// 		changeDisabled(check as any);
								// 	}}
								// />
								<Checkbox
									loading={loading}
									checked={disabled as any}
									onClick={changeDisabled as any}
								/>
							) : (
								''
							)}
						</div>
						<div>
							<Input
								label="Login"
								type="text"
								value={login}
								setValue={changeLogin}
								className=""
								readOnly={url === 'creation' ? false : true}
							/>
						</div>
						<div>
							<Input
								label="Email"
								type="text"
								value={email}
								setValue={changeEmail}
								className=""
							/>
						</div>

						<div>
							<Input
								label="Raison Sociale"
								type="text"
								value={raisonSociale}
								setValue={changeRaisonSociale}
							/>
						</div>
						<div>
							<Input
								label="Enseigne"
								type="text"
								value={enseigne}
								setValue={changeEnseigne}
							/>
						</div>
						<div className={styles.facture}>
							<div>
								<Input
									label="N° et nom de voie"
									value={numeroVoieFacturation}
									setValue={changeNumeroVoieFacturation}
								/>
								<Input
									label="Code postal"
									value={codePostalFacturation}
									setValue={changeCodePostalFacturation}
								/>
								<Input
									label="Ville"
									value={villeFacturation}
									setValue={changeVilleFacturation}
								/>
							</div>
						</div>
						<div>
							<Input
								label="Siret"
								type="text"
								value={siret}
								setValue={changeSiret}
							/>
						</div>
						<div className="">
							Banque
							<div className={styles.banque}>
								<div>
									<Input label="Iban" value={iban} setValue={changeIban} />
								</div>
								<div>
									<Input label="Bic" value={bic} setValue={changeBic} />
								</div>
								<div>
									<Input
										label="Banque"
										value={banque}
										setValue={changeBanque}
									/>
								</div>
							</div>
						</div>
						<div>
							<h1 className={styles.hh}>
								Secteur Activité <span className={styles.span}>*</span>
							</h1>
							<select
								className={styles.secteur}
								onChange={(l) => changeSecteurActivite(l.target.value)}
							>
								<option>-- Choisir une option</option>

								{newSecteurActivite === undefined ? (
									<option></option>
								) : (
									newSecteurActivite.map((e: any) => (
										<option value={e.id} selected={secteurActivite === e.id}>
											{e.nom}
										</option>
									))
								)}
							</select>
						</div>
						<div>
							<Input
								label="Horaires Ouverture"
								type="text"
								value={horairesOuverture}
								setValue={changeHorairesOuverture}
							/>
						</div>
					</div>

					<div className={styles.table2}>
						<div>
							<Input
								label="Description"
								type="text"
								value={description}
								setValue={changeDescription}
							/>
						</div>
						<div>
							<div className={styles.prelevement}>
								<label className={styles.label}>
									<h1>
										Prélèvement automatique{' '}
										<span className={styles.span}>*</span>
									</h1>
								</label>
								{/* <input
									name="isGoing"
									type="checkbox"
									checked={prelevementAuto as any}
									onChange={() => {
										setCheck2(!check2);
										changePrelevementAuto(check2 as any);
									}}
								/> */}
								<Checkbox
									loading={loading}
									checked={prelevementAuto as any}
									onClick={changePrelevementAuto as any}
								/>
							</div>
						</div>
						<div className={styles.photo}>
							<h1 className={styles.h1}>Photo</h1>
							<Dropzone
								imageSrc={photo}
								imageWidthToDisplay={600}
								onChange={({ tempURL, file }) => {
									setPhoto(file);
									changePhoto(tempURL);
								}}
							/>
						</div>
						<div className={styles.kbis}>
							<h1>Kbis</h1>
							<Dropzone
								imageSrc={kbis}
								imageWidthToDisplay={600}
								onChange={({ tempURL, file }) => {
									setKbis(file);
									changeKbis(tempURL);
								}}
							/>
						</div>
						<div className={styles.kbis}>
							<h1>Rib</h1>
							<Dropzone
								imageSrc={rib}
								imageWidthToDisplay={600}
								onChange={({ tempURL, file }) => {
									setRib(file);
									changeRib(tempURL);
								}}
							/>
						</div>

						<div>
							{/* <Input label="Contacts" value={contacts} setValue={changeContacts} /> */}
							{/* {test.map((e: any) => {
						console.log(e);
						// e.map((elem: any) => {
						// 	console.log(elem);
						// });
					})} */}
						</div>
						<div>
							<Input
								label="Dates De Fermeture Exceptionnelle (yyyy-mm-dd)"
								type="text"
								value={dateFermetureExceptionnelle}
								setValue={changeDateFermetureExceptionnelle}
							/>
						</div>
						<div>
							<label>Choisissez un fournisseur</label>
							<Select
								onChange={handleChangeFournisseur}
								options={fournisseursAvailable}
							/>
						</div>

						{/*<div>*/}
						{/*	<label>Choisissez un fournisseur</label>*/}
						{/*	<Select*/}
						{/*		value={fournisseursAutorise*/}
						{/*			.filter((e) => {*/}
						{/*				return fournisseursAutoriseVal.includes(e.id);*/}
						{/*			})*/}
						{/*			.map((e) => {*/}
						{/*				return {*/}
						{/*					label: e.login + ' - ' + e.email + ' - ' + e.enseigne,*/}
						{/*					value: e.id,*/}
						{/*				};*/}
						{/*			})}*/}
						{/*		onChange={handleChangeFournisseur}*/}
						{/*		isMulti*/}
						{/*		options={fournisseursAutorise.map((e: any) => {*/}
						{/*			return {*/}
						{/*				label: e.login + ' - ' + e.email + ' - ' + e.enseigne,*/}
						{/*				value: e.id,*/}
						{/*			};*/}
						{/*		})}*/}
						{/*	/>*/}
						{/*</div>*/}
					</div>
				</div>
				<div>
					<div className={styles.btnCtc} onClick={() => pushClient()}>
						Ajouter un contact
					</div>
					{clients.map((item: any, index: any) => {
						return (
							<div className={styles.ctnCtc} key={index}>
								<div
									onClick={() => deleteClient(index)}
									className={styles.deleteBtn}
								>
									Supprimer
								</div>
								<div>
									<Input
										label="Type"
										type="text"
										placeholder={item.type}
										value={item.typeVal}
										setValue={(valType) =>
											changeValContact(index, 'type', valType)
										}
									/>
								</div>
								<div>
									<Input
										label="Nom"
										type="text"
										placeholder={item.nom}
										value={item.nomVal}
										setValue={(val) => changeValContact(index, 'nom', val)}
									/>
								</div>
								<div>
									<Input
										label="Prénom"
										type="text"
										placeholder={item.first}
										value={item.firstVal}
										setValue={(valPrenom) =>
											changeValContact(index, 'first', valPrenom)
										}
									/>
								</div>
								<div>
									<Input
										label="Email"
										type="text"
										placeholder={item.email}
										value={item.emailVal}
										setValue={(valEmail) =>
											changeValContact(index, 'email', valEmail)
										}
									/>
								</div>
								<div>
									<Input
										label="Téléphones"
										type="text"
										placeholder={item.tel}
										value={item.telVal}
										setValue={(valTel) =>
											changeValContact(index, 'tel', valTel)
										}
									/>
								</div>
							</div>
						);
					})}
				</div>
				<ErrorMessage>{error}</ErrorMessage>
				<div>
					<Button submit loading={loading} className={styles.btnSubmit}>
						{type === 'Édition' ? 'Éditer un client' : 'Publier un client'}
					</Button>
				</div>
			</Form>
		</TableWrapper>
	);
}

export default FormulaireClient;
