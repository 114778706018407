import React from 'react';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import styles from './MenuLink.module.scss';

interface Props {
	to: string;
	children: string;
	pictoSrc?: string;
	isActive: boolean;
	withOffset?: boolean;
	isSubActive?: boolean;
	isCategorie?: boolean;
}

function MenuLink({
	to,
	pictoSrc,
	children,
	isActive,
	withOffset,
	isCategorie,
	isSubActive,
}: Props) {
	return (
		<Link
			to={to}
			className={[
				styles.wrapper,
				isActive && styles.isActive,
				withOffset && styles.withOffset,
				isSubActive && styles.isSubActive,
				isCategorie && styles.isCategorie,
			]
				.filter(Boolean)
				.join(' ')}
		>
			<span className={styles.subWrapper}>
				{pictoSrc && <ReactSVG src={pictoSrc} className={styles.picto} />}
				<span className={styles.text}>{children}</span>
			</span>
		</Link>
	);
}

export default MenuLink;
