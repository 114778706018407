import { ThunkAction } from 'redux-thunk';

export interface SecteurActivite {
	id: string;
	nom: string;
	slug: string;
}

export interface SecteursActiviteState {
	loading: boolean;
	error: string | null;
	list: SecteurActivite[];
}

export const GET_SECTEURS_ACTIVITE_START = 'GET_SECTEURS_ACTIVITE_START';
export const GET_SECTEURS_ACTIVITE_SUCCESS = 'GET_SECTEURS_ACTIVITE_SUCCESS';
export const GET_SECTEURS_ACTIVITE_FAIL = 'GET_SECTEURS_ACTIVITE_FAIL';

interface GetSecteursActiviteStartAction {
	type: typeof GET_SECTEURS_ACTIVITE_START;
}

interface GetSecteursActiviteSuccessAction {
	type: typeof GET_SECTEURS_ACTIVITE_SUCCESS;
	list: SecteurActivite[];
}

interface GetSecteursActiviteFailAction {
	type: typeof GET_SECTEURS_ACTIVITE_FAIL;
	error: string;
}

export type SecteursActiviteActionTypes =
	| GetSecteursActiviteStartAction
	| GetSecteursActiviteSuccessAction
	| GetSecteursActiviteFailAction;

export type SecteursActiviteThunkResult = ThunkAction<
	any,
	SecteursActiviteState,
	{},
	SecteursActiviteActionTypes
>;
