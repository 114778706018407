import {
	loadStateFromLocalStorage,
	saveStateToLocalStorage,
} from '../../../utils/localStorage';
import { CHANGE_DATE_LIVRAISON_PANIER, PanierActionTypes } from '../types';

export function changeDateLivraisonPanier(date: Date): PanierActionTypes {
	const panier = loadStateFromLocalStorage('panier');
	if (panier) {
		saveStateToLocalStorage('panier', { ...panier, dateLivraison: date });
	}
	return { type: CHANGE_DATE_LIVRAISON_PANIER, date };
}
