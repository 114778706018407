import React from 'react';
import { Link } from 'react-router-dom';
import { useTitle } from '../../../hooks/useTitle';
import Button from '../Button/Button';
import styles from './Error404.module.scss';

interface Props {
	children?: string;
}

function Error404({ children }: Props) {
	useTitle('Page introuvable');
	return (
		<div className={styles.wrapper}>
			<h1 className={styles.title}>404</h1>
			<p className={styles.text}>
				{children || `La page que vous avez demandée n'existe pas ou plus.`}
			</p>
			<Link to="/">
				<Button>Retour à l'accueil</Button>
			</Link>
		</div>
	);
}

export default Error404;
