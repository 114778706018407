import React from 'react';
import BtnAlertes from '../../Common/BtnAlertes/BtnAlertes';
import BtnPanier from '../BtnPanier/BtnPanier';
import BtnRecherche from '../BtnRecherche/BtnRecherche';
import PopinPanier from '../Panier/PopinPanier/PopinPanier';
import styles from './Footer.module.scss';

function Footer() {
	return (
		<footer className={styles.footer}>
			<BtnRecherche />
			<BtnAlertes />
			<BtnPanier />
			<PopinPanier />
		</footer>
	);
}

export default Footer;
