import React from 'react';
import MediaQuery from 'react-responsive';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import { media } from '../../../utils/breakpoints/breakpoints';
import Error404 from '../../Common/Error404/Error404';
import Gradient from '../../Common/Gradient/Gradient';
import Onglets from '../../Common/Onglets/Onglets';
import CallToActionDashboard from '../CallToActionDashboard/CallToActionDashboard';
import Filtres from '../Filtres/Filtres';
import styles from './Routes.module.scss';
import { onglets, switchRoutes } from './variables';
import MesFactures from '../MesFactures/MesFactures';

export function _Routes({
	location: { pathname, search },
}: RouteComponentProps) {
	const scrollableDiv = useScrollToTop();

	const searchParams = new URLSearchParams(search);
	const isSelection = searchParams.has('selection');
	const isRechercheWithoutSelection = !isSelection && pathname === '/recherche';

	return (
		<main className={styles.main}>
			{isRechercheWithoutSelection && <Filtres />}

			<Gradient />

			<div className={styles.scrollable} ref={scrollableDiv}>
				{/* CTA sur mobile */}
				<MediaQuery query={media['<laptop']}>
					<Route path="/" exact>
						<CallToActionDashboard />
					</Route>
				</MediaQuery>

				{/* Navigation rapide par onglets */}
				<Route path={onglets.shownOnPaths}>
					<Onglets pages={onglets.pages} />
				</Route>

				<Switch>
					{switchRoutes.map(({ component, path, exact }, i) => (
						<Route key={i} path={path} exact={exact}>
							{component}
						</Route>
					))}
					<Route path="/mes-factures" exact={true} component={MesFactures} />
					<Route>
						<Error404 />
					</Route>
				</Switch>
			</div>
		</main>
	);
}

export default withRouter(_Routes);
