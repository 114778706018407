import React from 'react';
import MediaQuery from 'react-responsive';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { media } from '../../../utils/breakpoints/breakpoints';
import BtnBack from '../BtnBack/BtnBack';
import styles from './Onglets.module.scss';

interface Props extends RouteComponentProps {
	pages: { title: string; to: string; subRoutes?: string[] }[];
}

export function _Onglets({ pages, match: { path } }: Props) {
	return (
		<div className={styles.wrapper}>
			{pages.map(({ title, to, subRoutes = [] }, i) => {
				const isActive = to === path;
				const isSubActive = subRoutes.includes(path);
				return (
					<Link
						key={i}
						to={to}
						title={title}
						className={[styles.link, (isActive || isSubActive) && styles.active]
							.filter(Boolean)
							.join(' ')}
					>
						{isSubActive && (
							<MediaQuery query={media['<tablet']}>
								<BtnBack className={styles.btnBack} />
							</MediaQuery>
						)}
						{title}
					</Link>
				);
			})}
		</div>
	);
}

export default withRouter(_Onglets);
