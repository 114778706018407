import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../store';
import { User } from '../../../../store/compte/types';
import { photosClientsSizes } from '../../../../utils/imgSizes';
import Img from '../../Img/Img';
import styles from './BtnMenu.module.scss';

export interface Props {
	id: string;
	nom: User['nom'];
	photo?: User['photo'];
	isMenuOpen: boolean;
	openMenu: () => void;
	closeMenu: () => void;
}

export function _BtnMenu({
	id,
	nom,
	photo,
	isMenuOpen,
	openMenu,
	closeMenu,
}: Props) {
	const btnClasses = [styles.btn, isMenuOpen && styles.isMenuCompteOpen]
		.filter(Boolean)
		.join(' ');

	return (
		<button
			id={id}
			title={`${isMenuOpen ? 'Fermer' : 'Ouvrir'} le menu du compte`}
			className={btnClasses}
			onClick={() => (isMenuOpen ? closeMenu() : openMenu())}
		>
			<p className={styles.nom}>{nom}</p>

			{photo ? (
				<Img
					alt={nom}
					src={photo}
					fallback="jpg"
					className={styles.photo}
					mediaQueries={[{ width: 40 }]}
					otherSizes={photosClientsSizes}
				/>
			) : (
				<span className={styles.initial}>{nom.charAt(0)}</span>
			)}
		</button>
	);
}

function mapStateToProps(state: AppState) {
	return {
		nom: state.compte.nom,
		photo: state.compte.photo,
	};
}

export default connect(mapStateToProps)(_BtnMenu);
