import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styles from './OrderBy.module.scss';

interface Props extends RouteComponentProps {
	fields: { name: string; slug: string }[];
}

export function _OrderBy({
	fields,
	history,
	location: { pathname, search },
}: Props) {
	const searchParams = new URLSearchParams(search);
	const orderParam = searchParams.get('order') as 'ASC' | 'DESC' | null;
	const orderByParam = searchParams.get('orderBy') as string | null;

	function onChange(key: 'orderBy' | 'order', value: string) {
		searchParams.set(key, value);
		searchParams.delete('page');
		history.push(pathname + '?' + searchParams);
	}

	return (
		<div className={styles.wrapper}>
			{/* Order by */}
			<label className={styles.tri}>
				Trier :
				<select
					className={styles.select}
					value={orderByParam || undefined}
					onChange={e => onChange('orderBy', e.target.value)}
				>
					{fields.map(({ name, slug }) => (
						<option key={slug} value={slug}>
							{name} &nbsp;&nbsp;
						</option>
					))}
				</select>
			</label>

			{/* Direction */}
			<label className={styles.tri}>
				Ordre :
				<select
					className={styles.select}
					value={orderParam || undefined}
					onChange={e => onChange('order', e.target.value)}
				>
					<option value="DESC">Descendant &nbsp;&nbsp;</option>
					<option value="ASC">Ascendant &nbsp;&nbsp;</option>
				</select>
			</label>
		</div>
	);
}

export default withRouter(_OrderBy);
