import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useUnmount } from '../../../hooks/useUnmount';
import { AppState } from '../../../store';
import { User } from '../../../store/compte/types';
import {
	Client,
	Commande,
	CommandeLigne,
	Contact,
	Label,
	Produit,
} from '../../../types';
import { StatutCommandeLigne } from '../../../types/StatutCommandeLigne';
import request from '../../../utils/request';
import TableWrapper from '../../Common/Table/TableWrapper/TableWrapper';
import styles from './DetailCommande.module.scss';
import Header from './Header/Header';
import ListeProduits from './ListeProduits/ListeProduits';
import PopinUpdateStatus from './PopinUpdateStatus/PopinUpdateStatus';
import DropzonePDF from '../../Common/DropzonePDF/DropzonePDF';

const getCommandeQuery = loader('./getCommande.graphql');
const linkPdfFournisseur = loader('./createFacture.graphql');
const getFactureQuery = loader('./getFacture.graphql');

export interface QueryResponse {
	commande: Pick<Commande, 'id' | 'dateLivraison'> & {
		client: Pick<Client, 'enseigne' | 'raisonSociale'> & {
			contacts: Pick<
				Contact,
				'id' | 'nom' | 'prenom' | 'email' | 'telephones'
			>[];
		};
		lignes: (Pick<
			CommandeLigne,
			'id' | 'quantite' | 'statut' | 'commentaire'
		> & {
			produit: Pick<Produit, 'id' | 'nom' | 'imageSrc' | 'unite'> & {
				labels: Pick<Label, 'abreviation'>[];
				code: string;
			};
		})[];
	};
}

interface Props extends RouteComponentProps<{ id: string }> {
	idFournisseur: User['id'];
}

type CommandeResponse = {
	commande: {
		facture: {
			id: string;
			pdfName: string;
			pdfPath: string;
		};
	};
};

export function _DetailCommande({
	idFournisseur,
	match: {
		params: { id },
	},
}: Props) {
	const { isMounted } = useUnmount();
	const [commande, setCommande] = useState<QueryResponse['commande']>();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');
	const [popinStatusOpened, setPopinStatusOpened] = useState(false);
	const [pdfName, setPdfName] = useState<string | undefined>(undefined);
	const [pdfPath, setPdPath] = useState<string | null>(null);
	const statut = commande?.lignes[0].statut;

	useEffect(() => {
		setError('');
		setLoading(true);
		request<QueryResponse>(getCommandeQuery, { idCommande: id, idFournisseur })
			.then((res) => isMounted.current && setCommande(res.commande))
			.catch((err) => setError(err.message))
			.finally(() => {
				setLoading(false);
			});
	}, [id, idFournisseur, isMounted]);

	useEffect(() => {
		if (commande) {
			const fetchFacture = async () => {
				const variables = {
					idCommande: commande.id,
				};

				const response = await request<CommandeResponse>(
					getFactureQuery,
					variables,
				);

				if (response.commande.facture) {
					setPdfName(response.commande.facture.pdfName);
					setPdPath(response.commande.facture.pdfPath);
				}
			};

			fetchFacture().then();
		}
	}, [commande]);

	function setCommandeStatus(statut: StatutCommandeLigne) {
		if (commande && isMounted) {
			setCommande({
				...commande,
				lignes: commande.lignes.map((l) => ({ ...l, statut })),
			});
		}
	}

	const linkPdfToTheFournisseur = async (file: File | null) => {
		setPdfName(file?.name);
		const variables = { idCommand: commande?.id, pdf: file };
		await request(linkPdfFournisseur, variables);
	};

	return (
		<TableWrapper>
			<Header
				statut={statut}
				client={commande?.client}
				openPopin={() => setPopinStatusOpened(true)}
				dateLivraison={commande?.dateLivraison}
			/>
			<ListeProduits
				error={error}
				loading={loading}
				lignes={commande?.lignes || []}
			/>
			{pdfName}
			<DropzonePDF
				defaultPdfPath={pdfPath}
				onFileSelect={(file) => linkPdfToTheFournisseur(file)}
			/>
			<PopinUpdateStatus
				statut={statut}
				isOpen={popinStatusOpened}
				setCommandeStatus={setCommandeStatus}
				close={() => setPopinStatusOpened(false)}
				lignesIds={commande?.lignes.map((l) => l.id) || []}
			/>
			<div className={styles.emptyFooter} />
		</TableWrapper>
	);
}

function mapStateToProps(state: AppState) {
	return { idFournisseur: state.compte.id };
}

export default withRouter(connect(mapStateToProps)(_DetailCommande));
