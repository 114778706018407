import formatDate from 'dateformat';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	closeAlertes,
	deleteAlerte,
	readAlerte,
} from '../../../../store/alertes/actions';
import { AlertesState } from '../../../../store/alertes/types';
import BtnPoubelle from '../../BtnPoubelle/BtnPoubelle';
import styles from './Alerte.module.scss';

export type Props = AlertesState['list'][0] & {
	readAlerte: typeof readAlerte;
	deleteAlerte: typeof deleteAlerte;
	closeAlertes: typeof closeAlertes;
};

export function _Alerte({
	id,
	lu,
	url,
	texte,
	deleting,
	createdAt,
	readAlerte,
	deleteAlerte,
	closeAlertes,
}: Props) {
	const today = new Date();
	today.setHours(0, 0, 0, 0);

	createdAt = new Date(createdAt);
	let date = formatDate(createdAt, 'HH:MM');

	if (createdAt < today) {
		date = formatDate(createdAt, 'dd/mm');
		if (createdAt.getFullYear() < today.getFullYear()) {
			date = formatDate(createdAt, 'dd/mm/yy');
		}
	}

	function onClick() {
		readAlerte(id);
		closeAlertes();
	}

	const text = (
		<>
			<span className={styles.date}>{date} -</span> {texte}
		</>
	);

	return (
		<div
			className={[
				styles.wrapper,
				!lu && styles.nonLu,
				deleting && styles.deleting,
			]
				.filter(Boolean)
				.join(' ')}
		>
			{url ? (
				<Link to={url} onClick={onClick} className={styles.link}>
					{text}
				</Link>
			) : (
				<p onClick={onClick} className={styles.link}>
					{text}
				</p>
			)}

			<BtnPoubelle
				title="Supprimer l'alerte"
				className={styles.delete}
				onClick={() => deleteAlerte(id)}
			/>
		</div>
	);
}

export default connect(null, { deleteAlerte, closeAlertes, readAlerte })(
	_Alerte,
);
