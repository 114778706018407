import React from 'react';
import { CellProps, ColumnProps } from '../../types';
import styles from './Td.module.scss';

type Props = CellProps & Pick<ColumnProps, 'center'>;

function Td({ content, className, stopPropagation, center }: Props) {
	return (
		<td
			onClick={e => (stopPropagation ? e.stopPropagation() : null)}
			className={[
				styles.wrapper,
				className,
				center && styles.centered,
				stopPropagation && styles.noPointer,
			]
				.filter(Boolean)
				.join(' ')}
		>
			{content}
		</td>
	);
}
export default Td;
