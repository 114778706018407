import React from 'react';
import { Adresse } from '../../../../../types';
import Input from '../../../Form/Input/Input';
import { CompteSectionProps, CompteSections } from '../../types';
import sectionStyles from '../Formulaire.module.scss';
import styles from './Adresses.module.scss';

export const fields: { label: string; name: keyof Adresse }[] = [
	{ label: 'Voie', name: 'voie' },
	{ label: 'Code postal', name: 'codePostal' },
	{ label: 'Ville', name: 'ville' },
];

type Props = CompteSectionProps & { fieldGroups: CompteSections['adresses'] };

function Adresses({ user, setUser, loading, fieldGroups }: Props) {
	return (
		<section className={sectionStyles.section}>
			<h1 className={sectionStyles.title}>Adresses</h1>

			{fieldGroups.map((g, i) => (
				<div
					key={i}
					className={[sectionStyles.column, i > 0 && styles.mobileMT]
						.filter(Boolean)
						.join(' ')}
				>
					<h2 className={sectionStyles.subtitle}>{g.title}</h2>

					{fields.map((field) => (
						<Input
							type="text"
							key={field.name}
							loading={loading}
							label={field.label}
							value={(user as any)[g.name][field.name]}
							setValue={(val) =>
								setUser({
									...user,
									[g.name]: { ...(user as any)[g.name], [field.name]: val },
								})
							}
						/>
					))}
				</div>
			))}
		</section>
	);
}

export default Adresses;
