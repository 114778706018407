import { DocumentNode } from 'graphql';
import 'whatwg-fetch';

type Variables = { [key: string]: any };

interface GraphQLError {
	message: string;
	locations: { line: number; column: number }[];
	path: string[];
}

interface GraphQLResponse {
	data?: any;
	errors?: GraphQLError[];
	extensions?: any;
	status: number;
	[key: string]: any;
}

class ClientError extends Error {
	response: GraphQLResponse;

	constructor(response: GraphQLResponse) {
		super(ClientError.extractMessage(response));
		this.name = 'ClientError';
		this.response = response;
		if (Error.captureStackTrace) Error.captureStackTrace(this, ClientError);
	}

	private static extractMessage(response: GraphQLResponse): string {
		try {
			return response.errors![0].message;
		} catch (e) {
			return `Erreur ${response.status}`;
		}
	}
}

async function request<TResponse>(
	query: DocumentNode,
	variables?: Variables,
): Promise<TResponse> {
	const body = new FormData();

	// On prépare un array qui contiendra tous les fichiers
	const files: { name: string; file: File }[] = [];

	if (variables) {
		// On boucle sur toutes les variables de premier plan (donc marche qu'à la racine de 'variables')
		// et si c'est un fichier, on l'ajoute à 'files' et on remplace sa value par 'null' dans 'variables'
		Object.entries(variables).forEach(([key, value]) => {
			if (value instanceof File) {
				files.push({ name: key, file: value });
				variables[key] = null;
			}
		});
	}

	// Maintenant qu'on a enlevé tous les fichiers de 'variables', on stringify l'ensemble dans le body
	body.append('operations', JSON.stringify({ query, variables }));

	// Pour chaque fichier, on ajoute une entrée dans le 'map' du body, avec comme clé le nom de la variable
	const maps = files.map((f) => [`variables.${f.name}`]);
	body.append('map', JSON.stringify({ ...maps }));

	// Enfin, on ajoute chaque fichier à la fin du body
	files.forEach((f, i) => body.append(i.toString(), f.file));

	/*
	Comment ça marche ?
	Comme on peut pas passer de fichier directement dans du json, on est obligés d'utiliser la méthode multipart/form-data.
	Cette méthode permet d'envoyer une requête en plusieurs parties.
	La première partie ce sont les 'operations', qui contiennent la query et les variables.
	Mais on ne peut pas passer les fichiers dans les variables, vu que c'est du JSON (donc une string).
	On passe les fichiers séparément, et on map chaque fichier à une variable via une sorte de "table relationnelle" qui est le 'map'.
	En gros, dans les variables, on remplace les fichiers par 'null', puis on rajoute un map entre la variable (représentée par sa clé)
	et le fichier (représenté par un numéro).

	Exemple de body :
		'operations' : "{ query: 'peu importe', variables: { fichier1: null, fichier2: null } }"
		'map': "{ '0': ['variables.fichier1'], '1': ['variables.fichier2'], }"
		'0': Fichier1
		'1': Fichier2
	*/

	const response = await fetch('/api', { method: 'POST', body });

	const contentType = response.headers.get('Content-Type');
	const isJson = contentType?.startsWith('application/json');
	const result = isJson ? await response.json() : await response.text();

	if (response.ok && !result.errors && result.data) {
		return result.data;
	} else {
		const errorResult = typeof result === 'string' ? { error: result } : result;
		throw new ClientError({ ...errorResult, status: response.status });
	}
}

export default request;
