import React from 'react';
import { Link } from 'react-router-dom';
import SideMenuWrapper from '../../../Common/SideMenuWrapper/SideMenuWrapper';
import Categories from '../Common/Categories/Categories';
import Selections from '../Common/Selections/Selections';
import SousCategories from '../Common/SousCategories/SousCategories';
import { HOCProps, withCategories } from '../Common/withCategories';
import styles from './SideMenuDesktop.module.scss';

export function _SideMenuDesktop({
	search,
	pathname,
	activeCategorie,
}: HOCProps) {
	// pour le cache qui ferme au clic
	const linkBack = new URLSearchParams(search);
	linkBack.delete('categorie');

	return (
		<>
			{/* Colonne 1 */}
			<SideMenuWrapper className={styles.menu}>
				<Categories />
				<Selections />
			</SideMenuWrapper>

			{/* Colonne 2 */}
			<SideMenuWrapper
				className={[styles.sousCategories, activeCategorie && styles.visible]
					.filter(Boolean)
					.join(' ')}
			>
				<SousCategories />
			</SideMenuWrapper>

			{/* Cache */}
			<Link to={`${pathname}?${linkBack}`} className={styles.cache}>
				&nbsp;
			</Link>
		</>
	);
}

export default withCategories(_SideMenuDesktop);
