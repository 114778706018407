import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useTitle } from '../../../hooks/useTitle';
import request from '../../../utils/request';
import Button from '../../Common/Button/Button';
import TableWrapper from '../../Common/Table/TableWrapper/TableWrapper';
import styles from './Actualites.module.scss';
import ListeActualites from './ListeActualites/ListeActualites';
import { AppState } from '../../../store';
import { connect } from 'react-redux';
import { hideLoginError, logIn } from '../../../store/compte/actions';
import { CompteMinimalist } from '../../Common/CompteUser/types';

const queryActu = loader('./queries/getActualites.graphql');
const mutationDeleteActu = loader('./queries/deleteActualite.graphql');
const mutationChangeOrderActu = loader(
	'./queries/changeOrderActualite.graphql',
);

type Fournisseur = {
	id: string;
	enseigne: string;
}

export interface Actualite {
	id: string;
	url: string;
	titre: string;
	ordre: number;
	contenu: string;
	createdAt: Date;
	imageSrc: string;
	fournisseur: Fournisseur;
}

type Props = {
	compte: CompteMinimalist;
}

function Actualites({ compte }: Props) {
	useTitle('Actualités');

	const [actualites, setActualites] = useState<Actualite[]>([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');

	useEffect(() => {
		let isMounted = true;
		setLoading(true);
		setError('');

		type TResponse = { actualites: Actualite[] };
		request<TResponse>(queryActu)
			.then((res) => {
				const filteredActualites = res.actualites.filter(
					(actualite) => {
						return actualite.fournisseur?.id === compte.id || compte.id.includes('.ADMIN');
					},
				);
				isMounted && setActualites(filteredActualites)
			})
			.catch(err => setError(err.message))
			.finally(() => setLoading(false));

		return () => {
			isMounted = false;
		};
	}, []);

	function deleteActu(id: string) {
		setError('');

		request(mutationDeleteActu, { id })
			.then(() => setActualites(actualites.filter(a => a.id !== id)))
			.catch(err => setError(err.message));
	}

	function invertActus(index1: number, index2: number) {
		// On récupère les actus
		const actu1: Actualite | null = actualites[index1];
		const actu2: Actualite | null = actualites[index2];

		// Annule si une des deux n'existe pas (donc qu'on est à une extrémité de la liste)
		if (!actu1 || !actu2) return;

		// On récupère les ids et ordres de chaque actu (nécessaire pour casser la référence objet)
		const { id: id1, ordre: ordre1 } = actu1;
		const { id: id2, ordre: ordre2 } = actu2;

		setError('');

		request(mutationChangeOrderActu, { id1, id2, ordre1, ordre2 })
			.then(() => {
				const actus = actualites.map(a => {
					if (a.id === actu1.id) a.ordre = ordre2;
					else if (a.id === actu2.id) a.ordre = ordre1;
					return a;
				});
				setActualites(actus.sort((a, b) => a.ordre - b.ordre));
			})
			.catch(err => setError(err.message));
	}

	return (
		<TableWrapper>
			<ListeActualites
				error={error}
				loading={loading}
				actualites={actualites}
				deleteActu={deleteActu}
				invertActus={invertActus}
			/>

			<div className={styles.buttonWrapper}>
				<Link to={{ pathname: '/actualites/creation' }}>
					<Button>Ajouter une actualité</Button>
				</Link>
			</div>
		</TableWrapper>
	);
}

function mapStateToProps(state: AppState) {
	return {
		compte: state.compte,
	};
}

export default withRouter(
	connect(mapStateToProps, { logIn, hideLoginError })(Actualites),
);
