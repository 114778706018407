import React from 'react';
import { TCompteFournisseur } from '../../../../../Fournisseur/CompteFournisseur/types';
import inputStyles from '../../../../Form/Input/Input.module.scss';
import { CompteSectionProps } from '../../../types';
import styles from './HeureLimite.module.scss';

function addLeadingZero(str: string) {
	return ('00' + str).substr(-2, 2);
}

type HourInputProps = {
	max: number;
	value: string;
	loading: boolean;
	onChange: (val: string) => void;
};
export function HourInput({ max, onChange, value, loading }: HourInputProps) {
	return (
		<input
			min={0}
			required
			max={max}
			type="number"
			readOnly={loading}
			value={addLeadingZero(value)}
			className={[styles.input, inputStyles.input].join(' ')}
			onChange={({ target: { value } }) => onChange(addLeadingZero(value))}
		/>
	);
}

type Props = CompteSectionProps<TCompteFournisseur>;

function HeureLimite({ user, setUser, loading }: Props) {
	const [hh, mm] = (user.heureLimite / 100).toFixed(2).split('.');

	return (
		<label className={[inputStyles.label, styles.wrapper].join(' ')}>
			<span className={inputStyles.text}>Heure de changement de jour</span>

			<div className={styles.inputsWrapper}>
				<HourInput
					max={23}
					value={hh}
					loading={loading}
					onChange={(h) => setUser({ ...user, heureLimite: parseInt(h + mm) })}
				/>
				&nbsp;:&nbsp;
				<HourInput
					max={59}
					value={mm}
					loading={loading}
					onChange={(m) => setUser({ ...user, heureLimite: parseInt(hh + m) })}
				/>
			</div>
		</label>
	);
}

export default HeureLimite;
