import React, { ComponentProps } from 'react';
import Contact from '../../Common/Contact/Contact';
import MentionsLegales from '../../Common/MentionsLegales/MentionsLegales';
import Onglets from '../../Common/Onglets/Onglets';
import Actualites from '../Actualites/Actualites';
import CommandeEnCours from '../CommandeEnCours/CommandeEnCours';
import Commandes from '../Commandes/Commandes';
import CompteClient from '../CompteClient/CompteClient';
import DetailCommande from '../DetailCommande/DetailCommande';
import ProduitsFavoris from '../ProduitsFavoris/ProduitsFavoris';
import ResultatsRecherche from '../ResultatsRecherche/ResultatsRecherche';

export const onglets: {
	shownOnPaths: string[];
	pages: ComponentProps<typeof Onglets>['pages'];
} = {
	shownOnPaths: [
		'/commande-en-cours',
		'/commandes',
		'/commandes-favorites',
		'/produits-favoris',
		'/commande/:id',
		'/mes-factures',
	],
	pages: [
		{ title: 'Commande en cours', to: '/commande-en-cours' },
		{ title: 'Mes commandes', to: '/commandes', subRoutes: ['/commande/:id'] },
		{ title: 'Mes commandes favorites', to: '/commandes-favorites' },
		{ title: 'Mes produits favoris', to: '/produits-favoris' },
		{ title: 'Mes factures', to: '/mes-factures' },
	],
};

export const switchRoutes: {
	path: string;
	exact?: boolean;
	component: JSX.Element;
}[] = [
	{ path: '/recherche', exact: true, component: <ResultatsRecherche /> },
	{ path: '/', exact: true, component: <Actualites /> },
	{ path: '/mentions-legales', component: <MentionsLegales /> },
	{ path: '/commande-en-cours', exact: true, component: <CommandeEnCours /> },
	{ path: '/commandes', component: <Commandes /> },
	{ path: '/commandes-favorites/:id', component: <DetailCommande /> },
	{ path: '/commandes-favorites', component: <Commandes favorites /> },
	{ path: '/contact', component: <Contact /> },
	{ path: '/produits-favoris', exact: true, component: <ProduitsFavoris /> },
	// { path: '/commande/favorites/:id', component: <DetailCommandeFavorite /> },
	{ path: '/commande/:id', component: <DetailCommande /> },
	{ path: '/compte', component: <CompteClient /> },
];
