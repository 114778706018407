export function generateRandomId() {
	return Math.random().toString(16).slice(-6);
}

export function randomString(length: number = 15) {
	let result = '';
	const characters =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789       ';
	let i = length;
	while (i--) {
		result += characters.charAt(randomInteger(characters.length));
	}
	return result;
}

export function randomInteger(max: number = 100) {
	return Math.floor(Math.random() * max);
}

export function randomFloat(max: number = 100) {
	return Math.floor(Math.random() * max * 100) / 100;
}

export function randomBoolean() {
	return !!randomInteger(2);
}
