import formatDate from 'dateformat';
import React from 'react';
import Button from '../../../../Button/Button';
import DatePicker from '../../../../DatePicker/DatePicker';
import inputStyles from '../../../../Form/Input/Input.module.scss';
import { CompteSectionProps } from '../../../types';
import styles from './DatesFermetureExceptionnelle.module.scss';

type Props = CompteSectionProps & { className: string };

function DatesFermetureExceptionnelle({
	user,
	setUser,
	loading,
	className,
}: Props) {
	return (
		<div className={[inputStyles.label, styles.wrapper, className].join(' ')}>
			<span className={inputStyles.text}>
				Dates de fermeture exceptionnelle
			</span>

			<ul className={styles.list}>
				{user.datesFermetureExceptionnelle.map((date, index) => (
					<li key={date + index} className={styles.item}>
						<DatePicker
							isClearable
							readOnly={loading}
							selected={date ? new Date(date) : null}
							onChange={(val) => {
								const dates = [...user.datesFermetureExceptionnelle];
								if (val) dates[index] = formatDate(val!, 'yyyy-mm-dd');
								else dates.splice(index, 1);
								setUser({ ...user, datesFermetureExceptionnelle: dates });
							}}
						/>
					</li>
				))}
				{[...new Array(4)].map((_, i) => (
					<li key={i} className={styles.item} />
				))}
			</ul>

			<Button
				outline
				className={styles.btnAddDate}
				disabled={user.datesFermetureExceptionnelle.includes('')}
				onClick={() => {
					const dates = [...user.datesFermetureExceptionnelle, ''];
					setUser({ ...user, datesFermetureExceptionnelle: dates });
				}}
			>
				Ajouter une date
			</Button>
		</div>
	);
}

export default DatesFermetureExceptionnelle;
