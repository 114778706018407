import { ThunkAction } from 'redux-thunk';

interface Alerte {
	id: string;
	createdAt: Date;
	updatedAt: Date;

	texte: string;
	url?: string;
	lu: boolean;

	deleting: boolean;
}

export interface AlertesState {
	error?: string;
	loading: boolean;
	isOpen: boolean;
	list: Omit<Alerte, 'updatedAt'>[];
}

export const OPEN_ALERTES = 'OPEN_ALERTES';
export const CLOSE_ALERTES = 'CLOSE_ALERTES';

export const GET_ALERTES_START = 'GET_ALERTES_START';
export const GET_ALERTES_SUCCESS = 'GET_ALERTES_SUCCESS';
export const GET_ALERTES_FAIL = 'GET_ALERTES_FAIL';

export const READ_ALERTE_START = 'READ_ALERTE_START';

export const DELETE_ALERTE_START = 'DELETE_ALERTE_START';
export const DELETE_ALERTE_SUCCESS = 'DELETE_ALERTE_SUCCESS';
export const DELETE_ALERTE_FAIL = 'DELETE_ALERTE_FAIL';

export const DELETE_ALL_ALERTES_START = 'DELETE_ALL_ALERTES_START';
export const DELETE_ALL_ALERTES_SUCCESS = 'DELETE_ALL_ALERTES_SUCCESS';
export const DELETE_ALL_ALERTES_FAIL = 'DELETE_ALL_ALERTES_FAIL';

// Open / Close
interface OpenAlertesAction {
	type: typeof OPEN_ALERTES;
}
interface CloseAlertesAction {
	type: typeof CLOSE_ALERTES;
}

// Get
interface GetAlertesStartAction {
	type: typeof GET_ALERTES_START;
}
interface GetAlertesSuccessAction {
	type: typeof GET_ALERTES_SUCCESS;
	list: AlertesState['list'];
}
interface GetAlertesFailAction {
	type: typeof GET_ALERTES_FAIL;
	error: string;
}

// Read one
interface ReadAlerteStartAction {
	type: typeof READ_ALERTE_START;
	id: string;
}

// Delete one
interface DeleteAlerteStartAction {
	type: typeof DELETE_ALERTE_START;
	id: string;
}
interface DeleteAlerteSuccessAction {
	type: typeof DELETE_ALERTE_SUCCESS;
	id: string;
}
interface DeleteAlerteFailAction {
	type: typeof DELETE_ALERTE_FAIL;
	error: string;
	id: string;
}

// Delete all
interface DeleteAllAlertesStartAction {
	type: typeof DELETE_ALL_ALERTES_START;
}
interface DeleteAllAlertesSuccessAction {
	type: typeof DELETE_ALL_ALERTES_SUCCESS;
}
interface DeleteAllAlertesFailAction {
	type: typeof DELETE_ALL_ALERTES_FAIL;
	error: string;
}

export type AlertesActionTypes =
	| OpenAlertesAction
	| CloseAlertesAction
	| GetAlertesStartAction
	| GetAlertesSuccessAction
	| GetAlertesFailAction
	| ReadAlerteStartAction
	| DeleteAlerteStartAction
	| DeleteAlerteSuccessAction
	| DeleteAlerteFailAction
	| DeleteAllAlertesStartAction
	| DeleteAllAlertesSuccessAction
	| DeleteAllAlertesFailAction;

export type AlertesThunkResult = ThunkAction<
	any,
	AlertesState,
	{},
	AlertesActionTypes
>;
