import formatDate from 'dateformat';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import request from '../../../../utils/request';
import Button from '../../../Common/Button/Button';
import Loader from '../../../Common/Loader/Loader';
import CenteredPopin from '../../../Common/Popin/CenteredPopin/CenteredPopin';
import { QueryResponse } from '../DetailCommande';
import styles from './PopinRepeatCommande.module.scss';

const duplicateCommandeMutation = loader('./duplicateCommande.graphql');

export interface Props {
	isOpen: boolean;
	validee: boolean;
	closePopin: () => void;
	recurrence: { id: string; date: Date };
	commande: QueryResponse['commande'];
}

function PopinRepeatCommande({
	isOpen,
	validee,
	commande,
	recurrence,
	closePopin,
}: Props) {
	const commandeTitle = commande.nom || 'N°' + commande.numero;

	const produitsInvalides = commande.lignes.find(
		l => l.produit.disabled || !l.produit.tarifs.length,
	);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [done, setDone] = useState(false);

	const date = formatDate(recurrence.date, 'dd/mm/yyyy');

	const passedDate = recurrence.date < new Date();

	async function confirm() {
		setError('');
		setLoading(true);
		try {
			await request(duplicateCommandeMutation, {
				idRecurrence: recurrence.id,
				dateLivraison: recurrence.date,
				nom: (commande.nom || 'Commande N°' + commande.numero) + ' (copie)',
				lignes: commande.lignes.map(l => ({
					quantite: l.quantite,
					idProduit: l.produit.id,
					prixUnitaireFinal: l.produit.tarifs[l.produit.tarifs.length-  1].prix,
				})),
			});
			setDone(true);
		} catch (err) {
			setError(err.message);
		} finally {
			setLoading(false);
		}
	}

	return (
		<CenteredPopin
			isOpen={isOpen}
			onCacheClick={() => {
				// On ne permet pas de fermer la popin si c'est loading
				if (!loading) closePopin();
			}}
		>
			<div className={styles.wrapper}>
				{passedDate ? (
					<>
						<h1 className={styles.title}>Date de récurrence dépassée</h1>

						<p className={styles.text}>
							Votre commande récurrente était prévue pour le{' '}
							<strong>{date}</strong>. Il est trop tard pour la valider.
						</p>

						<Button onClick={closePopin}>Fermer</Button>
					</>
				) : validee ? (
					<>
						<h1 className={styles.title}>Commande déjà passée</h1>

						<p className={styles.text}>
							Votre commande récurrente pour le <strong>{date}</strong> à déjà
							été validée. Vous pouvez la retrouver dans vos commandes et suivre
							son statut.
						</p>

						<Link to="/commandes" onClick={closePopin}>
							<Button>Mes commandes</Button>
						</Link>
					</>
				) : done ? (
					<>
						<h1 className={styles.title}>Commande passée</h1>

						<p className={styles.text}>
							Votre commande à bien été passée. Vous pouvez la retrouver dans
							vos commandes et suivre son statut.
						</p>

						<Link to="/commandes" onClick={closePopin}>
							<Button>Mes commandes</Button>
						</Link>
					</>
				) : (
					<>
						<h1 className={styles.title}>Valider la commande récurrente</h1>

						{loading ? (
							<Loader className={styles.loader} />
						) : error ? (
							<>
								<p className={[styles.text, styles.error].join(' ')}>{error}</p>
								<Button onClick={closePopin}>Fermer</Button>
							</>
						) : produitsInvalides ? (
							<>
								<p className={[styles.text, styles.error].join(' ')}>
									Certains produits de votre commande{' '}
									<strong>{commandeTitle}</strong> ne sont plus disponibles. La
									commande ne peut donc être passée. Nous vous conseillons de
									désactiver la récurrence de votre commande afin de ne plus
									recevoir d'alerte.
								</p>
								<Button onClick={closePopin}>Fermer</Button>
							</>
						) : (
							<>
								<p className={styles.text}>
									Vous allez relancer votre commande{' '}
									<strong>{commandeTitle}</strong> pour le{' '}
									<strong>{date}</strong>.
									<br />
									Si des produits ne sont plus disponibles, la commande ne
									pourra pas être passée.
								</p>

								<Button onClick={confirm}>Je confirme la commande</Button>
							</>
						)}
					</>
				)}
			</div>
		</CenteredPopin>
	);
}

export default PopinRepeatCommande;
