import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../store';
import { User } from '../../../../store/compte/types';
import { StatutCommandeLigne } from '../../../../types';
import { convertStatus, statusList, thinSpace } from '../../../../utils/french';
import request from '../../../../utils/request';
import Button from '../../../Common/Button/Button';
import CenteredPopin from '../../../Common/Popin/CenteredPopin/CenteredPopin';
import styles from './PopinUpdateStatus.module.scss';

const mutation = loader('./updateLignesCommande.graphql');

interface Props {
	isOpen: boolean;
	close: () => void;
	lignesIds: string[];
	idFournisseur: User['id'];
	statut?: StatutCommandeLigne;
	setCommandeStatus: (statut: StatutCommandeLigne) => void;
}

export function _PopinUpdateStatus({
	close,
	isOpen,
	statut,
	lignesIds,
	idFournisseur,
	setCommandeStatus,
}: Props) {
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);

	const index = statusList.indexOf(statut as any);
	const nextStatut = index > -1 ? statusList[index + 1] : undefined;

	async function updateStatus() {
		if (!nextStatut) return;

		setLoading(true);

		try {
			await request(mutation, { lignesIds, idFournisseur, nextStatut });
			close();
			// le timeout permet d'attendre que la popin ferme avant de changer le texte
			setTimeout(() => setCommandeStatus(nextStatut), 300);
		} catch (err) {
			setError(err.message);
		} finally {
			setLoading(false);
		}
	}

	return (
		<CenteredPopin isOpen={isOpen} onCacheClick={close}>
			<div className={styles.wrapper}>
				{error ? (
					<p className={styles.error}>
						Erreur lors de la mise à jour{thinSpace}: {error}
					</p>
				) : (
					<>
						<p>
							Mettre à jour le statut de la commande en
							<br />
							<strong>
								«{thinSpace}
								{nextStatut && convertStatus(nextStatut)}
								{thinSpace}»{thinSpace}?
							</strong>
						</p>

						<Button
							outline
							onClick={close}
							loading={loading}
							className={styles.button}
						>
							Annuler
						</Button>

						<Button
							loading={loading}
							onClick={updateStatus}
							className={styles.button}
						>
							Mettre à jour
						</Button>
					</>
				)}
			</div>
		</CenteredPopin>
	);
}

function mapStateToProps(state: AppState) {
	return { idFournisseur: state.compte.id };
}

export default connect(mapStateToProps)(_PopinUpdateStatus);
