import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppState } from '../../../../store';
import { CategoriesState } from '../../../../store/categories/types';
import { SelectionsState } from '../../../../store/selections/types';
import BtnBack from '../../../Common/BtnBack/BtnBack';
import Logo from '../../../Common/Logo/Logo';
import styles from './RecapRechercheOrLogo.module.scss';

export interface Props extends RouteComponentProps {
	selections: SelectionsState['list'];
	categories: CategoriesState['list'];
	onClick: () => void;
}

export function _RecapRechercheOrLogo({
	location: { pathname, search },
	selections,
	categories,
	onClick,
}: Props) {
	const urlParams = new URLSearchParams(search);

	let type: string = '';
	let value: string = '';

	const sousCategories = categories.map((c) => c.sousCategories).flat();

	const query = urlParams.get('query');
	const selection = selections.find(
		(s) => s.slug === urlParams.get('selection'),
	);
	const categorie = categories.find(
		(c) => c.slug === urlParams.get('categorie'),
	);
	const sousCategorie = sousCategories.find(
		(c) => c.slug === urlParams.get('sous-categorie'),
	);

	let backLink = '/recherche';

	if (query) {
		type = 'Recherche :';
		value = query.charAt(0).toUpperCase() + query.slice(1);
	} else if (selection) {
		type = 'Sélection :';
		value = selection.nom;
	} else if (categorie) {
		type = 'Catégorie :';
		value = categorie.nom;
	} else if (sousCategorie) {
		type = 'Sous-catégorie :';
		value = sousCategorie.nom;
		backLink += `?categorie=${sousCategorie.slug.split('_')[0]}`;
	}

	return pathname === '/recherche' && value ? (
		<>
			<BtnBack to={backLink} />

			<hr className={styles.separator} />

			<div className={styles.wrapper} onClick={onClick}>
				<p className={styles.type}>{type}</p>
				<p className={styles.value}>{value}</p>
			</div>
		</>
	) : (
		<Logo className={styles.logo} />
	);
}

function mapStateToProps(state: AppState) {
	return {
		selections: state.selections.list,
		categories: state.categories.list,
	};
}

export default withRouter(connect(mapStateToProps)(_RecapRechercheOrLogo));
