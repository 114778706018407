import { ThunkAction } from 'redux-thunk';

export interface ConditionsPaiement {
	id: string;
	nom: string;
	slug: string;
}

export interface ConditionsPaiementState {
	loading: boolean;
	error: string | null;
	list: ConditionsPaiement[];
}

export const GET_CONDITIONS_PAIEMENT_START = 'GET_CONDITIONS_PAIEMENT_START';
export const GET_CONDITIONS_PAIEMENT_SUCCESS =
	'GET_CONDITIONS_PAIEMENT_SUCCESS';
export const GET_CONDITIONS_PAIEMENT_FAIL = 'GET_CONDITIONS_PAIEMENT_FAIL';

interface GetConditionsPaiementStartAction {
	type: typeof GET_CONDITIONS_PAIEMENT_START;
}

interface GetConditionsPaiementSuccessAction {
	type: typeof GET_CONDITIONS_PAIEMENT_SUCCESS;
	list: ConditionsPaiement[];
}

interface GetConditionsPaiementFailAction {
	type: typeof GET_CONDITIONS_PAIEMENT_FAIL;
	error: string;
}

export type ConditionsPaiementActionTypes =
	| GetConditionsPaiementStartAction
	| GetConditionsPaiementSuccessAction
	| GetConditionsPaiementFailAction;

export type ConditionsPaiementThunkResult = ThunkAction<
	any,
	ConditionsPaiementState,
	{},
	ConditionsPaiementActionTypes
>;
