import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useTitle } from '../../../hooks/useTitle';
import request from '../../../utils/request';
import Button from '../../Common/Button/Button';
import TableWrapper from '../../Common/Table/TableWrapper/TableWrapper';
import styles from './Commentaires.module.scss';
import Table from '../../Common/Table/Table';
import { french } from '../../../utils/french';
import BtnConnexion from '../../Common/BtnConnexion/BtnConnexion';
import BtnPoubelle from '../../Common/BtnPoubelle/BtnPoubelle';
import { RowProps } from '../../Common/Table/types';
import { hideLoginError, logIn } from '../../../store/compte/actions';
import { UserResponse } from '../../../store/compte/types';
import { connect } from 'react-redux';
import { _Connexion } from '../../Common/AuthenticationRoutes/Connexion/Connexion';
import { AppState } from '../../../store';
import Filtre from '../../Client/Filtres/Filtre/Filtre';
import PopinsFiltres from '../../Client/Filtres/PopinsFiltres/PopinsFiltres';

const queryComm = loader('./queries/getComm.graphql');
const queryCommProduct = loader('./queries/getCommProduct.graphql')
const queryCommCmd = loader('./queries/getCommCmd.graphql')
const loginMutation = loader('./queries/login.graphql');
const loginWithId = loader('./queries/loginWithId.graphql');
// const mutationDeleteActu = loader('./queries/deleteActualite.graphql');
// const mutationChangeOrderActu = loader(
// 	'./queries/changeOrderActualite.graphql',
// );
//
export interface Actualite {
	id: string;
	url: string;
	titre: string;
	ordre: number;
	contenu: string;
	createdAt: Date;
	imageSrc: string;
}

function Commentaires() {
	useTitle('Commentaires');

	const [clients, setClients] = useState<[]>([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');
const [filtre, setFilter] = useState(false)
	useEffect(() => {
		let isMounted = true;
		setLoading(true);
		setError('');

		type TResponse = { commentaires: [], fournisseurComm: [] };
		request<TResponse>(queryComm)
			.then(res => {
				isMounted && setClients([...res.commentaires, ...res.fournisseurComm]);
			})
			.catch(err => setError(err.message))
			.finally(() => setLoading(false));

		return () => {
			isMounted = false;
		};
	}, []);

	function deleteActu(id: string) {
		setError('');

		// request(mutationDeleteActu, { id })
		// 	.then(() => setActualites(actualites.filter(a => a.id !== id)))
		// 	.catch(err => setError(err.message));
	}

	function invertActus(index1: number, index2: number) {
		// On récupère les actus
		// const actu1: Actualite | null = actualites[index1];
		// const actu2: Actualite | null = actualites[index2];

		// Annule si une des deux n'existe pas (donc qu'on est à une extrémité de la liste)
		// if (!actu1 || !actu2) return;

		// On récupère les ids et ordres de chaque actu (nécessaire pour casser la référence objet)
		// const { id: id1, ordre: ordre1 } = actu1;
		// const { id: id2, ordre: ordre2 } = actu2;

		setError('');

		// request(mutationChangeOrderActu, { id1, id2, ordre1, ordre2 })
		// 	.then(() => {
		// 		const actus = actualites.map(a => {
		// 			if (a.id === actu1.id) a.ordre = ordre2;
		// 			else if (a.id === actu2.id) a.ordre = ordre1;
		// 			return a;
		// 		});
		// 		setActualites(actus.sort((a, b) => a.ordre - b.ordre));
		// 	})
		// 	.catch(err => setError(err.message));
	}
function all(){
	setFilter(false)
	type TResponse = { commentaires: [], fournisseurComm: [] };
	request<TResponse>(queryComm)
		.then(res => {
			setClients([...res.commentaires, ...res.fournisseurComm]);
		})
		.catch(err => setError(err.message))
		.finally(() => setLoading(false));

}
	function openFiltre(slug: any) {
		setFilter(true)
		if (slug === 'produits') {
			type TResponse = { commentaires: [] };
			request<TResponse>(queryCommProduct)
				.then(res => {
					setClients([...res.commentaires]);
				})
				.catch(err => setError(err.message))
				.finally(() => setLoading(false));
		}

		if (slug === 'commande') {
			type TResponse = { fournisseurComm: [] };
			request<TResponse>(queryCommCmd)
				.then(res => {
					setClients([...res.fournisseurComm]);
				})
				.catch(err => setError(err.message))
				.finally(() => setLoading(false));

		}
	}

	return (
		<TableWrapper>
			<div className={styles.contaier}>
				<div className={styles.wrapper}>
					<p className={styles.title}>Filtrer par</p>

					{/* Loading */}


					{/* Error */}
					{/* Filtres */}
					<div

						onClick={() => openFiltre('produits')}
						className={styles.wrapper}
					>
						Commentaires produits
					</div>

					<div

						onClick={() => openFiltre('commande')}
						className={styles.wrapper}
					>
						Commentaires commandes
					</div>

					{filtre ? <div onClick={() => all()}> Reinitialiser
					</div> : <></>}

					{/* Popins */}
				</div>
			</div>
			<Table
				error={error}
				loading={loading}
				noResultMessage={'Aucun commentaires'}
				columns={[
					{ content: 'id', center: true },
					{ content: 'type', center: true },
					{ content: 'Produit / Commande', center: true },
					{ content: 'commentaire', center: true },
					{ content: 'Fournisseur', center: true },
				]}
				rows={clients.map(
					(data: any) => {
						const sr: String = data?.commentaire;
						const id = data.id;
						return {
							id,
							mobileTitle: (
								<div className={styles.mobileTitle}>
									{french(data.id)}
								</div>
							),
							cells: [
								{ content: french(data.id), className: styles.actuTitle },
								{
									content: french(!!data.produit ? 'Commentaire Produit' : 'Commentaire Commande'),
									className: styles.actuTitle,
								},

								{ content: (!!data?.produit ? data?.produit.nom : data?.commande.nom), className: styles.actuTitle },
								{
									content: french(!!data?.commentaire ? data?.commentaire : data?.content),
									className: styles.actuTitle,
								},
								{ content: !!data?.fournisseur ? data?.fournisseur.raisonSociale : '', className: styles.actuTitle },
							],
						} as RowProps;
					},
				)}
			/>

			{/*<div className={styles.buttonWrapper}>*/}
			{/*	<Link to={{ pathname: '/actualites/creation' }}>*/}
			{/*		<Button>Ajouter une actualité</Button>*/}
			{/*	</Link>*/}
			{/*</div>*/}
		</TableWrapper>
	);
}

function mapStateToProps(state: AppState) {
	return {
		isLoggingIn: state.compte.isLoggingIn,
		isLoggedIn: state.compte.isLoggedIn,
		loginError: state.compte.error,
	};
}

// export default Clients;
export default withRouter(
	connect(mapStateToProps, { logIn, hideLoginError })(Commentaires),
);

