import React from 'react';
import Button from '../../Button/Button';
import { CompteSectionProps, CompteSections } from '../types';
import Adresses from './Adresses/Adresses';
import Contacts from './Contacts/Contacts';
import styles from './Formulaire.module.scss';
import Horaires from './Horaires/Horaires';
import InformationsGenerales from './InformationsGenerales/InformationsGenerales';
import InformationsLegales from './InformationsLegales/InformationsLegales';

type Props = Omit<CompteSectionProps, 'fields'> & {
	reset: () => void;
	hasChanged: boolean;
	onSubmit: () => void;
	sections: CompteSections;
};

function Formulaire({
	user,
	reset,
	loading,
	setUser,
	onSubmit,
	sections,
	hasChanged,
}: Props) {
	const props = { user, loading, setUser };

	return (
		<form
			className={styles.wrapper}
			onSubmit={(e) => {
				e.preventDefault();
				onSubmit();
			}}
		>
			<InformationsGenerales
				{...props}
				fields={sections.informationsGenerales}
			/>
			<InformationsLegales {...props} fields={sections.informationsLegales} />
			<Horaires {...props} fields={sections.horaires} />
			<Adresses {...props} fieldGroups={sections.adresses} />
			<Contacts {...props} />

			{/* SUBMIT / RESET */}
			<div
				className={[styles.buttons, !hasChanged && styles.hidden]
					.filter(Boolean)
					.join(' ')}
			>
				<Button outline onClick={reset} className={styles.reset}>
					Annuler
				</Button>

				<Button submit className={styles.submit}>
					Enregistrer
				</Button>
			</div>
		</form>
	);
}

export default Formulaire;
