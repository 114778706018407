import styles from './breakpoints.module.scss';

export type Breakpoint =
	| '<mobile'
	| '<=mobile'
	| '>=mobile'
	| '>mobile'
	| '<tablet'
	| '<=tablet'
	| '>=tablet'
	| '>tablet'
	| '<laptop'
	| '<=laptop'
	| '>=laptop'
	| '>laptop'
	| '<desktop'
	| '<=desktop'
	| '>=desktop'
	| '>desktop'
	| '<xxl'
	| '<=xxl'
	| '>=xxl'
	| '>xxl';

const breakpoints: any = {};

Object.entries(styles).forEach(([key, value]) => {
	const px = parseInt(value);
	breakpoints[`<${key}`] = `(max-width: ${px - 1}px)`;
	breakpoints[`<=${key}`] = `(max-width: ${px}px)`;
	breakpoints[`>=${key}`] = `(min-width: ${px}px)`;
	breakpoints[`>${key}`] = `(min-width: ${px + 1}px)`;
});

export const media: {
	'<mobile': string;
	'<=mobile': string;
	'>=mobile': string;
	'>mobile': string;
	'<tablet': string;
	'<=tablet': string;
	'>=tablet': string;
	'>tablet': string;
	'<laptop': string;
	'<=laptop': string;
	'>=laptop': string;
	'>laptop': string;
	'<desktop': string;
	'<=desktop': string;
	'>=desktop': string;
	'>desktop': string;
	'<xxl': string;
	'<=xxl': string;
	'>=xxl': string;
	'>xxl': string;
} = breakpoints;
