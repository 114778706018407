import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import MediaQuery from 'react-responsive';
import { media } from '../../../../utils/breakpoints/breakpoints';
import { convertStatus, thinSpace } from '../../../../utils/french';
import request from '../../../../utils/request';
import BtnBack from '../../../Common/BtnBack/BtnBack';
import BtnFavori from '../../../Common/BtnFavori/BtnFavori';
import DatePicker from '../../../Common/DatePicker/DatePicker';
import NomCommande from '../../NomCommande/NomCommande';
import Recurrence, {
	RecurrenceMois,
	RecurrenceSemaine,
} from '../../Recurrence/Recurrence';
import { QueryResponse } from '../DetailCommande';
import styles from './Header.module.scss';
import { NewCommande } from '../../../../types';

const mutationUpdateCommande = loader('./updateCommande.graphql');
const mutation = loader('./createCommande.graphql');

export interface Props {
	from: string;
	initLoading: boolean;
	commande?: QueryResponse['commande'];
	updateCommandeState: (params: {
		favorite?: QueryResponse['commande']['favorite'];
		recurrenceMois?: QueryResponse['commande']['recurrenceMois'];
		recurrenceSemaine?: QueryResponse['commande']['recurrenceSemaine'];
	}) => void;
}

function Header({ from, commande, initLoading, updateCommandeState }: Props) {
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);

	async function updateCommandeRecurrence(
		mois?: RecurrenceMois,
		semaine?: RecurrenceSemaine,
	) {
		try {
			updateCommandeState({
				recurrenceMois: mois ? { date: mois } : undefined,
				recurrenceSemaine: semaine
					? semaine.map((s) => ({ jour: s }))
					: undefined,
			});
			await request(mutationUpdateCommande, {
				id: commande!.id,
				...{
					recurrenceMois: {
						...(commande!.recurrenceMois && { delete: true }),
						...(mois && {
							upsert: { update: { date: mois }, create: { date: mois } },
						}),
					},
					recurrenceSemaine: {
						deleteMany: { jour_not_in: semaine },
						...(semaine && { create: semaine.map((s) => ({ jour: s })) }),
					},
				},
			});
		} catch (err) {
			setError(err.message);
		} finally {
			setLoading(false);
		}
	}

	async function updateCommande(params: { nom?: string; favorite?: boolean }) {
		setLoading(true);
		try {
			await placeOrder(commande);
			// await request(mutationUpdateCommande, { id: commande!.id, ...params });
		} catch (err) {
			setError(err.message);
		} finally {
			setLoading(false);
		}
	}

	async function placeOrder(cmd: any) {
		// let localPanier = loadStateFromLocalStorage('panier');
		try {
			setError('');
			setLoading(true);
			const newCommande = {
				nom: cmd.nom,
				dateLivraison: '',
				comments: [],
				boat: true,
				cmdFav: cmd.id,
				lignes: cmd.lignes.map((l: any, index: number) => {
					const tarif = l.produit.tarifs[0].prix;
					return {
						quantite: l.quantite,
						idProduit: l.produit.id,
						prixUnitaireAConfirmer: tarif,
						idFournisseur: l.produit.tarifs[0].fournisseur.id,
						commentaire: l.commentaire ? l.commentaire : '',
					};
				}) as NewCommande['lignes'],
			};
			await request(mutation, newCommande);
		} catch (err) {
			setError(err.message);
		} finally {
			setLoading(false);
		}
	}

	return (
		<>
			{/* Erreur à l'update */}
			{error && (
				<p className={styles.error}>
					Erreur lors de la mise à jour de la commande{thinSpace}: {error}
				</p>
			)}

			{/* 1ère ligne */}
			<div className={styles.line}>
				<MediaQuery query={media['>=tablet']}>
					<BtnBack to={from} withText />
				</MediaQuery>

				{initLoading ? (
					<>
						<div className={[styles.skeleton, styles.nom].join(' ')} />
						<div className={styles.skeleton} />
					</>
				) : (
					commande && (
						<>
							<NomCommande
								nom={commande.nom}
								loading={loading}
								className={styles.nom}
								changeNom={(nom: string) => updateCommande({ nom })}
							/>

							<p className={styles.statutCommande}>
								État{thinSpace}: {convertStatus(commande.statut!)}
							</p>

							<BtnFavori
								checked={commande.favorite!}
								onClick={() => {
									updateCommande({ favorite: !commande.favorite });
									updateCommandeState({ favorite: !commande.favorite });
								}}
								title={
									commande.favorite
										? 'Enlever la commande des favoris'
										: 'Ajouter la commande aux favoris'
								}
							/>
						</>
					)
				)}
			</div>

			{/* 2ème ligne */}
			<div className={styles.line}>
				{initLoading ? (
					<>
						<div className={[styles.skeleton, styles.livraison].join(' ')} />
						<div className={styles.skeleton} />
					</>
				) : (
					commande && (
						<>
							<DatePicker
								readOnly
								selected={new Date(commande.dateLivraison)}
								className={styles.livraison}
								label={<strong>Date de livraison désirée{thinSpace}:</strong>}
							/>

							<Recurrence
								className={styles.recurrence}
								recurrenceMois={commande.recurrenceMois?.date}
								recurrenceSemaine={commande.recurrenceSemaine?.map(
									(r) => r.jour,
								)}
								setRecurrence={({ mois, semaine }) =>
									updateCommandeRecurrence(mois, semaine)
								}
							/>
						</>
					)
				)}
			</div>

			{initLoading ? (
				<>
					<div className={[styles.skeleton, styles.livraison].join(' ')} />
					<div className={styles.skeleton} />
				</>
			) : (
				commande && (
					<div className={styles.line}>
						<span className={styles.wrapper}>
							<b>Commentaires :</b>
							<div className={'d-flex flex-column mt-3'}>
								{(commande as any).commentaires ? (
									(commande as any).commentaires.map((item: any) => {
										return (
											<div className={'d-flex flex-column '}>
												<span>Fournisseur : {item.fournisseur.enseigne}</span>
												<span>Commentaire : {item.content}</span>
												<div className={'divid'}></div>
											</div>
										);
									})
								) : (
									<></>
								)}
							</div>
						</span>
					</div>
				)
			)}
		</>
	);
}

export default Header;
