import React, { useEffect, useState } from 'react';
import MenuLink from '../MenuLink/MenuLink';
import { HOCProps, withCategories } from '../withCategories';

export function _SousCategories({
	activeCategorie,
	activeSousCategorieSlug,
}: HOCProps) {
	const [list, setList] = useState(
		activeCategorie ? activeCategorie.sousCategories : [],
	);

	useEffect(() => {
		if (activeCategorie?.sousCategories.length) {
			setList(activeCategorie.sousCategories);
		}
	}, [activeCategorie]);

	return (
		<>
			{list.map(({ nom, slug }) => (
				<MenuLink
					key={slug}
					to={`/recherche?sous-categorie=${slug}`}
					isActive={slug === activeSousCategorieSlug}
				>
					{nom}
				</MenuLink>
			))}
		</>
	);
}

export default withCategories(_SousCategories);
