import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Loupe from '../Header/Loupe/Loupe';
import styles from './OmniSearch.module.scss';

export interface Props extends RouteComponentProps {
	className?: string;
	onSubmit?: Function;
}

export function _OmniSearch({ className, history, location, onSubmit }: Props) {
	const [search, setSearch] = useState('');

	const input = useRef<HTMLInputElement>(null);

	useEffect(() => {
		// Faut utiliser ça plutôt que autoFocus sinon ça annule l'animation
		if (input.current) input.current.focus();
	}, []);

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		setSearch(searchParams.get('query') || '');
	}, [location.search]);

	function handleSubmit(e: FormEvent) {
		e.preventDefault();
		if (!search.trim()) return;
		let keywords = search.split(/[\s,]+/);
		keywords = keywords.filter((s, i) => s && keywords.indexOf(s) === i);
		history.push(`/recherche?query=${keywords.join('+')}`);
		if (onSubmit) onSubmit();
	}

	return (
		<form
			onSubmit={handleSubmit}
			className={[styles.form, className].filter(Boolean).join(' ')}
		>
			<label className={styles.label}>
				<input
					ref={input}
					type="text"
					placeholder="Que recherchez-vous ?"
					inputMode="search"
					autoCapitalize="none"
					className={styles.input}
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					title="Rechercher parmi tous les produits et les fournisseurs"
					required
				/>
				<div
					title="Vider la recherche"
					onClick={() => setSearch('')}
					className={[styles.reset, search === '' && styles.disabled]
						.filter(Boolean)
						.join(' ')}
				/>
				<Loupe type="submit" disabled={!search} className={styles.loupe} />
			</label>
		</form>
	);
}

export default withRouter(_OmniSearch);
