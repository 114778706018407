import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { ProduitForPanier } from '../../../fragments/produitForPanier/type';
import { useTitle } from '../../../hooks/useTitle';
import { AppState } from '../../../store';
import { User } from '../../../store/compte/types';
import { PanierState } from '../../../store/panier/types';
import { Produit } from '../../../types';
import request from '../../../utils/request';
import OrderBy from '../../Common/OrderBy/OrderBy';
import TableHeader from '../../Common/Table/TableHeader/TableHeader';
import TablePagination from '../../Common/Table/TablePagination/TablePagination';
import TableWrapper from '../../Common/Table/TableWrapper/TableWrapper';
import ListeProduits from './ListeProduits/ListeProduits';

const query = loader('./getFavoriteProducts.graphql');
const mutationSetFavorite = loader('./setProduitFavorite.graphql');
const itemsPerPage = 10;

export interface QueryResponse {
	countProduits: number;
	produits: (ProduitForPanier & Pick<Produit, 'id'>)[];
}

export interface Props extends RouteComponentProps {
	idClient?: User['id'];
	dateLivraison?: PanierState['dateLivraison'];
}

export function _ProduitsFavoris({ idClient, location, dateLivraison }: Props) {
	useTitle('Mes produits favoris');

	const [produits, setProduits] = useState<QueryResponse['produits']>([]);
	const [totalCount, setTotalCount] = useState(0);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');

	const searchParams = new URLSearchParams(location.search);
	const pageIndex = +(searchParams.get('page') || 1);
	const orderBy = searchParams.get('orderBy') || '';
	const order = searchParams.get('order') || '';

	function setProduitFavori(idProduit: string, isFavorite: boolean) {
		const newProduits = produits.map(p => {
			if (idProduit === p.id) p.isFavoriteForMe = !isFavorite;
			return p;
		});
		setProduits(newProduits);
		request(mutationSetFavorite, { favori: !isFavorite, idProduit });
	}

	// Fetch des produits
	useEffect(() => {
		if (!orderBy || !order) return;

		let isMounted = true;
		setLoading(true);

		const params = {
			idClient,
			dateLivraison,
			first: itemsPerPage,
			skip: itemsPerPage * (pageIndex - 1),
			orderBy: `${orderBy}_${order}`,
		};

		request<QueryResponse>(query, params)
			.then(({ produits, countProduits }) => {
				if (!isMounted) return;
				setProduits(produits);
				setTotalCount(countProduits);
				setError('');
			})
			.catch(err => setError(err.message))
			.finally(() => setLoading(false));

		return () => {
			isMounted = false;
		};
	}, [location.key, order, orderBy, idClient, pageIndex, dateLivraison]);

	if (!orderBy || !order) {
		return <Redirect to="/produits-favoris?orderBy=search&order=ASC" />;
	}

	return (
		<TableWrapper>
			<TableHeader>
				<OrderBy fields={[{ name: 'Nom', slug: 'slug' }]} />
			</TableHeader>

			<ListeProduits
				error={error}
				loading={loading}
				produits={produits}
				setProduitFavori={setProduitFavori}
			/>

			<TablePagination
				error={!!error}
				loading={loading}
				totalItemsCount={totalCount}
				itemsCountPerPage={itemsPerPage}
			/>
		</TableWrapper>
	);
}

function mapStateToProps(state: AppState) {
	return {
		idClient: state.compte.id,
		dateLivraison: state.panier.dateLivraison,
	};
}

export default withRouter(connect(mapStateToProps)(_ProduitsFavoris));
