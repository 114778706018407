import React from 'react';
import { Fournisseur } from '../../../../types';
import Section from '../Section/Section';
import Skeleton from '../Skeleton/Skeleton';

interface Props {
	data: null | Pick<Fournisseur, 'description'>;
}

function Presentation({ data }: Props) {
	return (
		<Section title="Présentation">
			{data ? (
				data.description
			) : (
				<>
					<Skeleton size="full" />
					<Skeleton size="full" />
					<Skeleton size="full" />
					<Skeleton size="full" />
					<Skeleton size="small" />
				</>
			)}
		</Section>
	);
}

export default Presentation;
