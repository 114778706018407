import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Logo.module.scss';
import logo from './eMarketplace.png';

interface Props {
	className?: string;
}

function Logo({ className }: Props) {
	return (
		<Link
			to="/"
			title="Aller à l'accueil"
			className={[styles.link, className].filter(Boolean).join(' ')}
		>
			{/* <LogoSVG className={styles.logo} /> */}
			<img src={logo} alt="logo" className={styles.logoPosition} />
		</Link>
	);
}

export default Logo;
