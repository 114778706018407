import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useTitle } from '../../../hooks/useTitle';
import { useUnmount } from '../../../hooks/useUnmount';
import request from '../../../utils/request';
import FormulaireAdmin from '../FormulaireAdmin/FormulaireAdmin';
const getAdminQuery = loader('./getAdmin.graphql');

// const getClientsQuery = loader('./getClient.graphql');
const updateAdminMutation = loader('./updateAdmin.graphql');

interface Props {
	id: string;
}

function EditionAdmin({ id }: Props) {
	useTitle(`Édition d'un admin`);

	const { isMounted } = useUnmount();

	const [error, setError] = useState('');
	const [loading, setLoading] = useState(true);
	const [done, setDone] = useState(false);
	const [data, setData] = useState([]);

	const [login, changeLogin] = useState('');
	const [email, changeEmail] = useState('');
	const [nom, changeNom] = useState('');
	const [prenom, changePrenom] = useState('');
	const [password, changePassword] = useState('');

	useEffect(() => {
		setError('');
		setLoading(true);

		let isMounted = true;
		type TResponse = { admin: [] };

		request<TResponse>(getAdminQuery, { id })
			.then((res) => {
				if (res) {
					setData(res.admin);
				}
			})
			.catch((err) => {
				setError(err.message);
			})
			.finally(() => setLoading(false));

		return () => {
			isMounted = false;
		};
	}, [id, isMounted]);

	useEffect(() => {
		if (data) {
			// @ts-ignore
			changeEmail(data.email);
			// @ts-ignore
			changeLogin(data.login);
			// @ts-ignore
			changeNom(data.nom);
			// @ts-ignore
			changePrenom(data.prenom);
		}
	}, [data]);

	async function submit() {
		if (password.length > 7) {
			try {
				setError('');
				setLoading(true);
				await request(updateAdminMutation, {
					id,
					login,
					nom,
					prenom,
					email,
					password,
				});
				if (isMounted.current) setDone(true);
			} catch (err) {
				setError(err.message);
				setLoading(false);
			}
		} else {
			try {
				setError('');
				setLoading(true);
				await request(updateAdminMutation, {
					id,
					login,
					nom,
					prenom,
					email,
				});
				if (isMounted.current) setDone(true);
			} catch (err) {
				setError(err.message);
				setLoading(false);
			}
		}
	}

	return done ? (
		<Redirect to="/admin/list" />
	) : (
		<FormulaireAdmin
			type="Édition"
			{...{
				error,
				loading,
				login,
				email,
				password,
				nom,
				prenom,
				changePrenom,
				changeNom,
				changeLogin,
				changeEmail,
				changePassword,
				submit,
			}}
		/>
	);
}

export default EditionAdmin;
