import React from 'react';
import styles from './Background.module.scss';

function Background() {
	return (
		<div className={styles.wrapper}>
			<div className={styles.ligne} />
			<div className={styles.ligne2} />
			<div className={styles.losange} />
			<div className={styles.bigLosange} />
			<div className={styles.ligne3} />
			<div className={styles.ligne4} />
		</div>
	);
}

export default Background;
