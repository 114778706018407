export function parseDelaiLivraison(a: string) {
	const array = a.split('->');
	const position = alphabetPosition(array[array.length - 1]);
	return parseInt(position) - 1;
}

export function alphabetPosition(text: string) {
	let result = '';
	for (let i = 0; i < text.length; i++) {
		const code = text.toUpperCase().charCodeAt(i);
		if (code > 64 && code < 91) result += (code - 64) + ' ';
	}

	return result.slice(0, result.length - 1);
}

const alphabet = [
	'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
];

export function getCharaAlphabetPos(number: number) {
	return alphabet[number];
}
