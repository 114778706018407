import React from 'react';
import { Route, RouteProps, Switch } from 'react-router';
import Logo from '../Logo/Logo';
import styles from './AuthenticationRoutes.module.scss';
import Background from './Background/Background';
import Connexion from './Connexion/Connexion';
import Dispatch from './Dispatch/Dispatch';
import LienSite from './LienSite/LienSite';
import MotDePasseOublie from './MotDePasseOublie/MotDePasseOublie';
import NouveauMotDePasse from './NouveauMotDePasse/NouveauMotDePasse';

export const authRoutes: RouteProps[] = [
	{ path: '/connexion', children: <Dispatch /> },
	{ path: '/connexion-client', children: <Connexion type="Client" /> },
	{
		path: '/connexion-fournisseur',
		children: <Connexion type="Fournisseur" />,
	},
	{ path: '/connexion-admin', children: <Connexion type="Admin" /> },
	{
		path: '/mot-de-passe-client-oublie',
		children: <MotDePasseOublie type="Client" />,
	},
	{
		path: '/mot-de-passe-fournisseur-oublie',
		children: <MotDePasseOublie type="Fournisseur" />,
	},
	{ path: '/nouveau-mot-de-passe', children: <NouveauMotDePasse /> },
];

function AuthenticationRoutes() {
	return (
		<div className={styles.wrapper}>
			<Logo className={styles.logo} />

			<div className={styles.content}>
				<Switch>
					{authRoutes.map((route, i) => (
						<Route key={i} exact {...route} />
					))}
				</Switch>
			</div>

			<LienSite />

			<Background />
		</div>
	);
}

export default AuthenticationRoutes;
