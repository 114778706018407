import React from 'react';
import Input from '../../../Form/Input/Input';
import InputFile from '../../../Form/InputFile/InputFile';
import { CompteField, CompteSectionProps, CompteSections } from '../../types';
import sectionStyles from '../Formulaire.module.scss';

type Props = CompteSectionProps & {
	fields: CompteSections['informationsLegales'];
};

function InformationsLegales({ user, fields, setUser, loading }: Props) {
	const fileFields = fields.filter((f) => f.type === 'file');
	const notFileFields = (fields.filter(
		(f) => f.type !== 'file',
	) as unknown) as CompteField[];

	return (
		<section className={sectionStyles.section}>
			<h1 className={sectionStyles.title}>Informations légales</h1>

			<div className={sectionStyles.column}>
				{notFileFields.map(({ label, name, type, readOnly, required }, i) => (
					<Input
						key={i}
						type={type}
						loading={loading}
						required={required}
						readOnly={readOnly}
						value={user[name] as string}
						label={label + (required ? '*' : '')}
						setValue={(val) => setUser({ ...user, [name]: val })}
					/>
				))}
			</div>

			<div className={sectionStyles.column}>
				{fileFields.map(({ label, name }, i) => (
					<InputFile
						key={i}
						label={label}
						loading={loading}
						url={user[name] as string}
						file={(user as any)[name + 'File']}
						onChange={({ file }) => setUser({ ...user, [name + 'File']: file })}
					/>
				))}
			</div>
		</section>
	);
}

export default InformationsLegales;
