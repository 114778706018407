import {
	loadStateFromLocalStorage,
	saveStateToLocalStorage,
} from '../../../utils/localStorage';
import {
	ADD_PRODUIT_TO_PANIER,
	CHANGE_LIGNE_PANIER,
	EMPTY_PANIER,
	LocalStoragePanierLigne,
	PanierActionTypes,
	PanierLigne,
	REMOVE_PRODUIT_FROM_PANIER, SET_GLOBAL_COMMENT_FOR_FOURNISSEUR,
} from '../types';

// Add to Panier
export function addProduitToPanier(
	ligne: Omit<PanierLigne, 'ancienPrix'>,
): PanierActionTypes {
	let localPanier = loadStateFromLocalStorage('panier');
	const { quantite, produit } = ligne;
	console.log();
	const localStorageLigne: LocalStoragePanierLigne = {
		quantite,
		idProduit: produit.id,
		ancienPrix: produit.tarif!.prix,
		commentaire: null,
		uc: null,
		fournisseurId: produit?.tarif?.fournisseur.id.toString(),
	};

	if (localPanier) {
		if (!localPanier.lignes.find(l => l.idProduit === produit.id)) {
			localPanier.lignes.push(localStorageLigne);
		}
	} else {
		localPanier = { comments: [], favorite: false, lignes: [localStorageLigne] };
	}
	saveStateToLocalStorage('panier', localPanier);

	return {
		type: ADD_PRODUIT_TO_PANIER,
		ligne: { ...ligne, ancienPrix: produit.tarif!.prix },
	};
}

export function setGlobalCommentForFournisseur(idFournisseur: any, content: string) {
	const localPanier = loadStateFromLocalStorage('panier');
	if (localPanier) {
		const lignes = localPanier.lignes.map((l) => {
			return l;
		});
		if (localPanier.comments.filter((e) => e.fournisseur === idFournisseur).length) {
			const panier = localPanier.comments.map((e) => {
				if (e.fournisseur === idFournisseur) {
					e.content = content;
				}
				return e;
			});

			saveStateToLocalStorage('panier', { ...localPanier, lignes, comments: panier });
		} else {
			console.log(localPanier.comments.includes(idFournisseur));
			const index = localPanier.comments.findIndex(e => e.fournisseur === idFournisseur);
			console.log(idFournisseur, index);

			const comments = [...localPanier.comments, { fournisseur: idFournisseur, content }];
			saveStateToLocalStorage('panier', { ...localPanier, lignes, comments });
		}

		setTimeout(() => {
			const localPanier = loadStateFromLocalStorage('panier');
		}, 500);
		return { type: SET_GLOBAL_COMMENT_FOR_FOURNISSEUR };
	}
}

export function changeUnitPanier(idProduit: string,
																 unite: string,
) {
	const localPanier = loadStateFromLocalStorage('panier');
	console.log(localPanier);
	// if (localPanier) {
	// 	const lignes = localPanier.lignes.map((l) => {
	// 		if (l.idProduit === idProduit) {
	// 			l. = quantite;
	// 			l.commentaire = commentaire;
	// 		}
	// 		return l;
	// 	});
	//
	// 	saveStateToLocalStorage('panier', { ...localPanier, lignes, comments: [] });
	// }
	// return { type: CHANGE_LIGNE_PANIER, idProduit, quantite };
}

// Change quantity
export function changeLignePanier(
	idProduit: string,
	quantite: number,
	commentaire?: String | undefined | null,
	unite?: string,
): PanierActionTypes | null {
	const localPanier = loadStateFromLocalStorage('panier');
	console.log(commentaire);
	if (localPanier) {
		const lignes = localPanier.lignes.map((l) => {

			if (l.idProduit === idProduit) {
				l.quantite = quantite;
				l.uc = unite;
				// if (commentaire != null) {
					l.commentaire = commentaire;
				// }
			}
			return l;
		});

		saveStateToLocalStorage('panier', { ...localPanier, lignes });
	}
	return { type: CHANGE_LIGNE_PANIER, idProduit, quantite };
}

// Remove from Panier
export function removeProduitFromPanier(idProduit: string): PanierActionTypes {
	const localPanier = loadStateFromLocalStorage('panier');
	console.log('REMOVE');
	if (localPanier) {
		const index = localPanier.lignes.findIndex((l) => l.idProduit === idProduit);
		const four = localPanier.lignes[index].fournisseurId;
		const comm = localPanier.comments.filter((e) => e.fournisseur === four);
		const lignes = localPanier.lignes.filter(l => l.idProduit !== idProduit);
		const lignesRest = lignes.filter(e => e.fournisseurId === four);
		if (!lignesRest.length) {
			// DELETE COMM OF THIS FOURNISSEUR
			const rest = localPanier.comments.filter((e) => e.fournisseur !== four);
			saveStateToLocalStorage('panier', { ...localPanier, lignes, comments: rest });

		} else {
			saveStateToLocalStorage('panier', { ...localPanier, lignes });

		}


		// if (!newLocalPanier.lignes.length) {
		// 	newLocalPanier.comments = [];
		// }
		// TODO REMOVE COMMENT WHEN FOURNISSEUR DELETED
		// saveStateToLocalStorage('panier', { ...localPanier, lignes});

	}
	return { type: REMOVE_PRODUIT_FROM_PANIER, idProduit };
}

// Remove all produits
export function emptyPanier(): PanierActionTypes {
	const localPanier = loadStateFromLocalStorage('panier');
	if (localPanier) {
		saveStateToLocalStorage('panier', { ...localPanier, lignes: [], comments: [] });
	}
	return { type: EMPTY_PANIER };
}
