import React, { useEffect, useState } from 'react';
import styles from './Validate.module.scss';
import Logo from '../Logo/Logo';
import { loader } from 'graphql.macro';
import request from '../../../utils/request';
import { UserResponse } from '../../../store/compte/types';

export interface Props {
	commande: string;
}

// const queryActu = loader('./queries/validateCommande.graphql');
const loginWithId = loader('./queries/loginWithId.graphql');
const getClient = loader('./queries/getClient.graphql');

function RedirectCommande({ commande }: Props) {
	const [string, setString] = useState('');
	const [fourni, setFourni] = useState('');

	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const commandeId = commande.substr(commande.indexOf('/commande/') + 10, commande.length);
		console.log(commandeId);
		// const total = (fourni.length +(11 ))
		// const res = commande.substr(19, (fourni.length));
		// setFourni(fourni);
		// setString(res);
		type TResponse = { commande: any };
		request<TResponse>(getClient, { id: commandeId })
			.then(resQuery => {
				console.log(resQuery.commande.client.id);
				const id = resQuery.commande.client.id.toString();
				setLoading(false);

				type TResponse2 = { loginWithId: { id: string } };
				request<TResponse2>(loginWithId, { id, model: 'Client' }).then((es) => {
					window.location.href = '/commande/' + resQuery.commande.id + '?from=/commandes&reload=true';
				});
			});

	}, [string]);
	return (
		<div className={styles.content}>
			<Logo />
			<h1 className={styles.title}>{!loading ? 'Redirection en cours ! ' : '...'}</h1>
		</div>
	);

}

export default RedirectCommande;
