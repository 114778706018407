import React from 'react';
import { Fournisseur } from '../../../../types';
import { thinSpace } from '../../../../utils/french';
import Section from '../Section/Section';
import Skeleton from '../Skeleton/Skeleton';
import styles from './InformationsLegales.module.scss';

interface Props {
	data: null | Pick<
		Fournisseur,
		'raisonSociale' | 'numeroTVAIntracommunautaire' | 'franco'
	>;
}

function InformationsLegales({ data }: Props) {
	return (
		<>
			<Section title="Informations légales">
				<p className={styles.raisonSociale}>
					{data ? (
						<>
							Raison sociale{thinSpace}:<br />
							{data.raisonSociale}
						</>
					) : (
						<>
							<Skeleton size="big" />
							<Skeleton size="full" />
						</>
					)}
				</p>

				<p>
					{data ? (
						<>
							N° TVA intracommunautaire{thinSpace}:<br />
							{data.numeroTVAIntracommunautaire}
						</>
					) : (
						<>
							<Skeleton size="big" />
							<Skeleton size="full" />
						</>
					)}
				</p>
			</Section>
			<Section title="Franco">
				<p>
					{data ? data.franco : <Skeleton size="small" />}
					{data?.franco ? ' €' : ''}
				</p>
			</Section>
		</>
	);
}

export default InformationsLegales;
