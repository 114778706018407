import React from 'react';
import Table from '../../../Common/Table/Table';
import { Facture } from '../MesFactures';
import formatDate from 'dateformat';
import Button from '../../../Common/Button/Button';
import { downloadFile } from '../../../../utils/download';
import { TableProps } from '../../../Common/Table/types';

type Props = {
	factures: Facture[];
	loading: boolean;
	error: string;
	selectableRows: NonNullable<TableProps['selectableRows']>;
};

const ListeFactures = ({ factures, loading, error, selectableRows }: Props) => {
	return (
		<Table
			columns={[
				{ content: 'Date de facturation' },
				{ content: 'Numéro de facture', mobileHidden: true },
				{ content: '', center: true, isButton: true },
			]}
			rows={factures.map((facture: Facture) => {
				const dotIndex = facture.pdfName.lastIndexOf('.');
				const pdfName =
					facture.pdfName.substring(0, dotIndex) +
					`-${facture.numeroFacture}` +
					facture.pdfName.substring(dotIndex);

				return {
					id: facture.id,
					mobileTitle: `Facture ${facture.numeroFacture}`,
					cells: [
						{
							content: formatDate(facture.dateFacturation, 'dd/mm/yyyy'),
						},
						{
							content: facture.numeroFacture,
						},
						{
							content: (
								<Button
									outline
									title={`Télécharger la facture ${pdfName}`}
									onClick={() => {
										downloadFile({
											href: facture.pdfPath,
											name: pdfName,
										});
									}}
								>
									Télécharger
								</Button>
							),
						},
					],
				};
			})}
			selectableRows={selectableRows}
			noResultMessage={`Pas de factures`}
			error={error}
			loading={loading}
		/>
	);
};

export default ListeFactures;
