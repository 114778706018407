import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../store';
import { addProduitToPanier } from '../../../../store/panier/actions';
import { PanierState } from '../../../../store/panier/types';
import { photosProduitsSizes } from '../../../../utils/imgSizes';
import BtnFavori from '../../../Common/BtnFavori/BtnFavori';
import Img from '../../../Common/Img/Img';
import Label from '../../../Common/Label/Label';
import Prix from '../../../Common/Prix/Prix';
import Table from '../../../Common/Table/Table';
import { RowProps } from '../../../Common/Table/types';
import BtnAjoutPanier from '../../BtnAjoutPanier/BtnAjoutPanier';
import PlusMoinsPanier from '../../PlusMoinsPanier/PlusMoinsPanier';
import { QueryResponse } from '../ProduitsFavoris';
import styles from './ListeProduits.module.scss';

interface Props {
	error?: string;
	loading: boolean;
	produits: QueryResponse['produits'];
	lignesPanier: PanierState['lignes'];
	addProduitToPanier: typeof addProduitToPanier;
	setProduitFavori: (id: string, isFavorite: boolean) => void;
}

export function _ListeProduits({
	error,
	loading,
	produits,
	lignesPanier,
	setProduitFavori,
	addProduitToPanier,
}: Props) {
	return (
		<Table
			error={error}
			loading={loading}
			noResultMessage="Vous n'avez aucun produit favori"
			columns={[
				{ content: 'Photo', center: true, mobileHidden: true },
				{ content: 'Nom', mobileHidden: true },
				{ content: 'Code' },
				{ content: 'Labels' },
				{ content: 'Prix' },
				{ content: 'Panier', center: true, isButton: true },
				{ content: 'Favori', center: true, isButton: true },
			]}
			rows={produits.map((produit: any) => {
				const image = (
					<div className={styles.rela}>
						<Img
							src={produit.imageSrc}
							alt={produit.nom}
							fallback="jpg"
							className={styles.photo}
							otherSizes={photosProduitsSizes}
							mediaQueries={[{ width: 80 }]}
						/>
					</div>
				);

				const isInPanier = lignesPanier.find(
					(l) => l.produit.id === produit.id && l.quantite,
				);
				return {
					id: produit.id,
					mobileTitle: (
						<div className={styles.mobileTitle}>
							{image}
							{produit.nom}
						</div>
					),
					cells: [
						{ content: image },
						{ content: produit.nom, className: styles.nom },
						{ content: produit.codeFournisseur.map((item: any) => item.code) },
						{
							content: produit.labels.length
								? produit.labels.map((label: any, i: number) => (
										<Label key={i}>{label.abreviation}</Label>
								  ))
								: null,
						},
						{
							content: (
								<Prix
									afficherNA
									aucour={produit.aucour}
									unite={produit.unite}
									prix={produit.tarifs[produit.tarifs.length - 1]?.prix}
								/>
							),
						},
						{
							content: produit.tarifs[produit.tarifs.length - 1] ? (
								isInPanier ? (
									<PlusMoinsPanier idProduit={produit.id} />
								) : (
									<BtnAjoutPanier
										title="Ajouter le produit au panier"
										onClick={() => {
											const { tarifs, ...rest } = produit;
											// TODO
											addProduitToPanier({
												quantite: 1,
												commentaire: null,
												produit: {
													...rest,
													tarif: produit.tarifs[produit.tarifs.length - 1],
												},
											});
										}}
									/>
								)
							) : (
								<p className={styles.na}>N/A</p>
							),
						},
						{
							content: (
								<BtnFavori
									checked={produit.isFavoriteForMe}
									onClick={() => {
										setProduitFavori(produit.id, produit.isFavoriteForMe);
									}}
									title={
										produit.isFavoriteForMe
											? 'Enlever le produit de mes favoris'
											: 'Ajouter le produit à mes favoris'
									}
								/>
							),
						},
					],
				} as RowProps;
			})}
		/>
	);
}

function mapStateToProps(state: AppState) {
	return { lignesPanier: state.panier.lignes };
}

export default connect(mapStateToProps, { addProduitToPanier })(_ListeProduits);
