import { DocumentNode } from 'graphql';
import { User } from '../../../store/compte/types';
import request from '../../../utils/request';
import { Compte } from './types';

interface Args {
	user: Compte;
	idUser: User['id'];
	pristineUser: Compte;
	mutation: DocumentNode;
}

export async function updateUserAccount<T extends Compte>({
	user,
	idUser,
	mutation,
	pristineUser,
}: Args) {
	// On supprime les dates vides (si on a cliqué sur "ajouter une date" sans la spécifier après)
	const dates = user.datesFermetureExceptionnelle.filter(Boolean);

	// On clone le user et on s'en servira pour update le state après la mutation
	const newUser = { ...user, datesFermetureExceptionnelle: dates };

	// On supprime les fichiers de newUser car on n'en a plus besoin (ils sont dans user)
	// et on veut qu'ils disparaissent après la mutation
	delete newUser.ribFile;
	delete newUser.kbisFile;
	delete newUser.photoFile;

	// On prépare l'input graphql pour les contacts à ajouter / modifier
	const contactsToUpsert = user.contacts.map((contact) => {
		const { id, ...c } = contact;
		const c2 = { ...c, telephones: { set: c.telephones } };
		return { where: { id: contact.id }, update: c2, create: c2 };
	});

	// On prépare l'input graphql pour les contacts à supprimer
	const contactsToDelete = pristineUser.contacts
		.filter((c) => !user.contacts.find((c2) => c2.id === c.id))
		.map((c) => ({ id: c.id }));

	try {
		// on est obligés de récupérer les contacts de la mutation, vu qu'il faut map les ids pour la suppression
		// et pareil pour les fichiers, puisque les urls sont créées côté serveur
		type TRes = {
			user: Pick<Compte, 'rib' | 'kbis' | 'photo' | 'contacts'>;
		};

		// On remplace les rib, kbis et photo par les fichiers (qui seront ensuite parsés par request.ts)
		const res = await request<TRes>(mutation, {
			idUser,
			...newUser,
			rib: user.ribFile,
			kbis: user.kbisFile,
			photo: user.photoFile,
			secteurActiviteId: user.secteurActivite.id,
			...('conditionsPaiement' in user && {
				conditionsPaiementId: user.conditionsPaiement.id,
			}),
			contactsToUpsert,
			contactsToDelete,
		});

		// Enfin, on return le merge de newUser et pristineUser
		return { ...newUser, ...res.user } as T;
	} catch (err) {
		console.error(err.message);
		throw new Error(
			`Une erreur est survenue lors de la mise à jour de votre compte.`,
		);
	}
}
