import { loader } from 'graphql.macro';
import formatDate from 'dateformat';
import React, { useEffect, useState } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { useTitle } from '../../../hooks/useTitle';
import request from '../../../utils/request';
import BtnEdit from '../../Common/BtnEdit/BtnEdit';
import BtnPoubelle from '../../Common/BtnPoubelle/BtnPoubelle';
import Button from '../../Common/Button/Button';
import Table from '../../Common/Table/Table';
import TableWrapper from '../../Common/Table/TableWrapper/TableWrapper';
import { RowProps } from '../../Common/Table/types';
import styles from './ProduitsListe.module.scss';
import { Produit, Fournisseur } from '../../../types';
import Img from '../../Common/Img/Img';
import { photosProduitsSizes } from '../../../utils/imgSizes';
import { connect } from 'react-redux';
import { AppState } from '../../../store';
const queryProduits = loader('./getProduits.graphql');
const mutationDeleteProduit = loader('./deleteProduit.graphql');

export interface Client {
	id: string;
	enseigne: string;
	login: string;
	email: string;
	description: string;
	photo: string;
	edit: string;
	disabled: string;
	imageSrc: string;
}
export interface Props extends RouteComponentProps {
	idFournisseur: Fournisseur['id'];
}

function _ProduitsListe({idFournisseur} : Props) {
	useTitle('Produits');

	const [produits, setProduits] = useState<Produit[]>([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');

	useEffect(() => {
		let isMounted = true;
		setLoading(true);
		setError('');

		type TResponse = { produits: Produit[] };
		request<TResponse>(queryProduits, {
			where: {
				fournisseur: { id: idFournisseur },
			},
		})
			.then((res) => {
				isMounted && setProduits(res.produits);
				console.log('PRODUITS', res.produits);
			})
			.catch((err) => setError(err.message))
			.finally(() => setLoading(false));
		return () => {
			isMounted = false;
		};
	}, []);

	function deleteClient(id: string) {
		setError('');

		request(mutationDeleteProduit, { id })
			.then(() => setProduits(produits.filter((a) => a.id !== id)))
			.catch((err) => setError(err.message));
	}

	async function ProduitEdit(id: string) {
		setError('');
		// const response = await request<UserResponse>(loginWithId, { id, model: 'Client' });

		// window.location.href = '';
		window.location.href = `/produit/edition/${id}`;
		// window.location.reload()
	}

	return (
		<TableWrapper>
			<div>
				<div className={styles.buttonWrapper}>
					<Link to={{ pathname: '/produit/create' }}>
						<Button submit loading={loading}>
							Ajouter un produit
						</Button>
					</Link>
				</div>
			</div>
			<Table
				error={error}
				loading={loading}
				noResultMessage={'Aucun Clients'}
				columns={[
					{ content: 'image', center: true },
					{ content: 'nom', center: true },
					{ content: 'date de création', center: true },
					{ content: 'code', center: true },
					{ content: 'unite', center: true },
					{ content: 'tarif', center: true },
					{ content: 'Edit', isButton: true, center: true },
					{ content: 'Actif', center: true },
					{ content: 'Supprimer', center: true, isButton: true },
				]}
				rows={produits.map(
					(
						{ id, nom, code, unite, createdAt, disabled, tarifs, imageSrc },
						i,
					) => {
						// const sr: String = description;
						return {
							id,
							// mobileTitle: (
							// 	<div className={styles.mobileTitle}>{french(login)}</div>
							// ),
							cells: [
								{
									content: (
										<div className={styles.svgPanier}>
											<Img
												src={imageSrc}
												alt={nom}
												fallback="jpg"
												className={styles.img}
												otherSizes={photosProduitsSizes}
												mediaQueries={[
													{ breakpoint: '<=mobile', width: 100 },
													{ breakpoint: '<=tablet', width: 260 },
													{ breakpoint: '<=laptop', width: 360 },
													{ breakpoint: '>laptop', width: 100 },
												]}
											/>
										</div>
									),
									// className: styles.actuTitle,
								},
								{
									content: nom,
									className: styles.actuTitle,
								},
								{ content: formatDate(createdAt, 'dd/mm/yyyy') },
								{
									content: code,
									className: styles.actuTitle,
								},
								{
									content: unite,
									className: styles.actuTitle,
								},
								{
									content: tarifs.length > 0 ? tarifs[0].prix + '€' : null,
									className: styles.actuTitle,
								},
								{
									content: (
										<BtnEdit
											title="Edition"
											onClick={() => {
												ProduitEdit(id);
											}}
										/>
									),
								},
								{
									content: disabled ? 'Inactif' : 'Actif',
									className: styles.actuTitle,
								},
								{
									stopPropagation: true,
									content: (
										<BtnPoubelle
											title="Supprimer l'actualité"
											onClick={() => deleteClient(id)}
										/>
									),
								},
							],
						} as RowProps;
					},
				)}
			/>
		</TableWrapper>
	);
}

// export default ProduitsListe;
function mapStateToProps(state: AppState) {
	return { idFournisseur: state.compte.id };
}

export default withRouter(connect(mapStateToProps)(_ProduitsListe));
