import React from 'react';
import { PanierState } from '../../../store/panier/types';
import { connect } from 'react-redux';
import { _TableProduits } from '../../Client/CommandeEnCours/TableProduits/TableProduits';
import { AppState } from '../../../store';
import { _CommandeEnCours } from '../../Client/CommandeEnCours/CommandeEnCours';
import { addDays } from 'date-fns';
import { thinSpace } from '../../../utils/french';
import formatDate from 'dateformat';

export type CustomProps = {
	dayDelivery: any;
	optionLivraison: any;
	timeLivraison: any;
	panier: any;
	closedDay: any;
};
export type Props = Pick<
	PanierState,
	'lignes' | 'dateLivraison' | 'errorInfoProduits' | 'loadingInfoProduits'
> &
	CustomProps;

export function _LivraisonDate({
	dateLivraison,
	closedDay,
	dayDelivery,
	optionLivraison,
	timeLivraison,
}: Props) {
	let dateLivraisonVal = new Date();
	const now = new Date().getHours();

	const disblaedDate = closedDay.map((e: string) => new Date(e).toDateString());
	const days = [
		'DIMANCHE',
		'LUNDI',
		'MARDI',
		'MERCREDI',
		'JEUDI',
		'VENDREDI',
		'SAMEDI',
	];
	const disabled = [
		'LUNDI',
		'MARDI',
		'MERCREDI',
		'JEUDI',
		'VENDREDI',
		'SAMEDI',
		'DIMANCHE',
	];
	const posible = dayDelivery
		.split(',')
		.map((e: string) => {
			if (disabled.includes(e)) {
				return e;
			}
		})
		.filter((e: any) => e != undefined);
	if (dateLivraison) {
		if (
			now > timeLivraison &&
			formatDate(dateLivraison, 'dd/mm/yyyy') ===
				formatDate(dateLivraisonVal, 'dd/mm/yyyy')
		) {
			dateLivraisonVal = addDays(new Date(dateLivraison), 1);
		} else {
			dateLivraisonVal = new Date(dateLivraison);
		}
	}
	// }

	let newDate = new Date();

	let theDate = new Date(dateLivraisonVal);

	if (dateLivraison) {
		let dayTrue = false;
		let closeTrue = false;
		while (!dayTrue && !closeTrue) {
			const day = days[theDate.getDay()];
			if (
				!posible.includes(day) ||
				disblaedDate.includes(theDate.toDateString())
			) {
				dayTrue = false;
				closeTrue = false;
				theDate = addDays(theDate, 1);
			} else {
				if (theDate.getDate() - newDate.getDate() >= optionLivraison) {
					theDate = theDate;
					dayTrue = true;
					closeTrue = true;
				} else {
					const daysToAdd = theDate.getDate() - newDate.getDate();
					theDate =
						daysToAdd >= 0 && theDate.getMonth() === newDate.getMonth()
							? addDays(theDate, optionLivraison - daysToAdd)
							: theDate;
					dayTrue = true;
					closeTrue = true;
				}
			}
		}
	}

	// const [dateDIsplay, setDateDisplay] = useState(date);

	// useEffect(() => {
	// 	const day = days[dateDIsplay.getDay()];

	// 	console.log(dateDIsplay, '--');
	// });
	return (
		<div>
			{dateLivraison && (
				<p>
					Date de livraison estimée{thinSpace}:{' '}
					{dateLivraison ? (
						<strong>{formatDate(theDate, 'dd/mm/yyyy')}</strong>
					) : (
						<></>
					)}
				</p>
			)}
		</div>
	);
}

function mapStateToProps(state: AppState) {
	return state.panier;
}

export default connect(mapStateToProps)(_LivraisonDate);
