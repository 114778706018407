import React from 'react';
import { Pick2 } from 'ts-multipick';
import { Adresse, Fournisseur } from '../../../../types';
import Section from '../Section/Section';
import Skeleton from '../Skeleton/Skeleton';

interface Props {
	data:
		| null
		| (Pick2<Fournisseur, 'adresse', 'voie' | 'codePostal' | 'ville'> & {
				adresseFacturation?: Pick<Adresse, 'voie' | 'codePostal' | 'ville'>;
		  });
}

function AdresseFacturation({ data }: Props) {
	const adresse = data && (data.adresseFacturation || data.adresse);

	return (
		<Section title="Adresse de facturation">
			{adresse ? (
				<>
					{adresse.voie},<br />
					{adresse.codePostal} {adresse.ville}
				</>
			) : (
				<>
					<Skeleton size="big" />
					<br />
					<Skeleton size="small" />
				</>
			)}
		</Section>
	);
}

export default AdresseFacturation;
