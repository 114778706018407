import React from 'react';
import { joursDeLaSemaine } from '../../../../../../utils/french';
import CheckboxRecurrence from '../../../../../Client/Recurrence/CheckboxRecurrence/CheckboxRecurrence';
import { TCompteFournisseur } from '../../../../../Fournisseur/CompteFournisseur/types';
import inputStyles from '../../../../Form/Input/Input.module.scss';
import { CompteSectionProps } from '../../../types';
import styles from './JoursLivraison.module.scss';

type Props = Omit<CompteSectionProps<TCompteFournisseur>, 'loading'>;

function JoursLivraison({ user, setUser }: Props) {
	const { joursLivraison } = user;

	return (
		<div className={inputStyles.label + ' ' + styles.wrapper}>
			<span className={inputStyles.text}>Jours de livraison</span>

			<div className={styles.list}>
				{joursDeLaSemaine.map((day) => {
					const checked = joursLivraison.includes(day);
					return (
						<CheckboxRecurrence
							key={day}
							checked={checked}
							onClick={() => {
								let set = [...joursLivraison];
								if (checked) set = set.filter((d) => d !== day);
								else set.push(day);
								setUser({ ...user, joursLivraison: set });
							}}
						>
							{day.charAt(0)}
						</CheckboxRecurrence>
					);
				})}
			</div>
		</div>
	);
}

export default JoursLivraison;
