import {
	CategoriesActionTypes,
	CategoriesState,
	GET_CATEGORIES_FAIL,
	GET_CATEGORIES_START,
	GET_CATEGORIES_SUCCESS,
} from './types';

const initialState: CategoriesState = {
	list: [],
	loading: true,
};

export function categoriesReducer(
	state = initialState,
	action: CategoriesActionTypes,
): CategoriesState {
	switch (action.type) {
		case GET_CATEGORIES_START:
			return {
				...state,
				loading: true,
				error: undefined,
			};

		case GET_CATEGORIES_SUCCESS:
			return {
				...state,
				loading: false,
				list: action.list,
			};

		case GET_CATEGORIES_FAIL:
			return {
				...state,
				loading: false,
				error: action.error,
			};

		default:
			return state;
	}
}
