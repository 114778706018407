import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useTitle } from '../../../hooks/useTitle';
import { useUnmount } from '../../../hooks/useUnmount';
import { photosActualitesSizes } from '../../../utils/imgSizes';
import request from '../../../utils/request';
import { Actualite } from '../Actualites/Actualites';
import FormulaireActualite from '../FormulaireActualite/FormulaireActualite';

const getActualiteQuery = loader('./getActualite.graphql');
const updateActualiteMutation = loader('./updateActualite.graphql');

interface Props {
	id: string;
}

function EditionActualite({ id }: Props) {
	useTitle(`Édition d'une actualité`);

	const { isMounted } = useUnmount();

	const [error, setError] = useState('');
	const [loading, setLoading] = useState(true);
	const [done, setDone] = useState(false);
const [embedVal, setEmbedVal] = useState('');
	const [titre, changeTitre] = useState('');
	const [contenu, changeContenu] = useState('');
	const [url, changeUrl] = useState('');
	const [imageSrc, changeImageSrc] = useState('');
	const [imageFile, setImageFile] = useState<File | undefined>();

	useEffect(() => {
		setError('');
		setLoading(true);

		type TResponse = { actualite: Actualite };
		request<TResponse>(getActualiteQuery, { id })
			.then(({ actualite }) => {
				if (!isMounted.current) return;
				changeUrl(actualite.url);
				changeTitre(actualite.titre);
				changeContenu(actualite.contenu);
				const biggestSize =
					photosActualitesSizes[photosActualitesSizes.length - 1];
				changeImageSrc(
					actualite.imageSrc.replace(/@.+.webp$/, `@${biggestSize}.webp`),
				);
			})
			.catch((err) => setError(err.message))
			.finally(() => setLoading(false));
	}, [id, isMounted]);

	async function submit() {
		try {
			setError('');
			setLoading(true);
			await request(updateActualiteMutation, {
				id,
				url,
				titre,
				contenu,
				image: imageFile,
			});
			if (isMounted.current) setDone(true);
		} catch (err) {
			setError(err.message);
			setLoading(false);
		}
	}

	return done ? (
		<Redirect to="/actualites" />
	) : (
		<FormulaireActualite
			type="Édition"
			{...{
				error,
				loading,
				url,
				titre,
				contenu,
				imageSrc,
				changeUrl,
				changeTitre,
				setImageFile,
				changeContenu,
				changeImageSrc,
				embedVal,
				setEmbedVal,
				submit,
			}}
		/>
	);
}

export default EditionActualite;
