import formatDate from 'dateformat';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DatePicker from '../../Common/DatePicker/DatePicker';
import styles from './FilterByDateRange.module.scss';

interface Props extends RouteComponentProps {
	label: string;
}

export function _FilterByDateRange({
	label,
	history,
	location: { search, pathname },
}: Props) {
	const searchParams = new URLSearchParams(search);
	const start = searchParams.get('dateStart');
	const end = searchParams.get('dateEnd');

	const dateStart = start && Date.parse(start) ? new Date(start) : null;
	const dateEnd = end && Date.parse(end) ? new Date(end) : null;

	const format = (date: Date) => formatDate(date, 'yyyy-mm-dd');

	function changeDateStart(dateStart: Date | null) {
		if (dateStart) searchParams.set('dateStart', format(dateStart));
		else searchParams.delete('dateStart');
		searchParams.delete('page');
		history.push(pathname + '?' + searchParams);
	}

	function changeDateEnd(dateEnd: Date | null) {
		if (dateEnd) searchParams.set('dateEnd', format(dateEnd));
		else searchParams.delete('dateEnd');
		searchParams.delete('page');
		history.push(pathname + '?' + searchParams);
	}

	return (
		<div className={styles.wrapper}>
			<DatePicker
				label={label + ' du'}
				selectsStart
				selected={dateStart}
				startDate={dateStart}
				endDate={dateEnd}
				maxDate={dateEnd}
				onChange={changeDateStart}
				className={styles.datePicker}
				isClearable
			/>

			<DatePicker
				label=" au"
				selectsEnd
				selected={dateEnd}
				startDate={dateStart}
				endDate={dateEnd}
				minDate={dateStart}
				onChange={changeDateEnd}
				className={styles.datePicker}
				isClearable
			/>
		</div>
	);
}

export default withRouter(_FilterByDateRange);
