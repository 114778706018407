import React from 'react';
import BtnPoubelle from '../../../BtnPoubelle/BtnPoubelle';
import Button from '../../../Button/Button';
import Input from '../../../Form/Input/Input';
import { Compte, CompteField, CompteSectionProps } from '../../types';
import sectionStyles from '../Formulaire.module.scss';
import styles from './Contacts.module.scss';

export const fields: (CompteField<Omit<Compte['contacts'][0], 'telephones'>> & {
	size: 'small' | 'big';
})[] = [
	{ label: 'Type', type: 'text', name: 'type', required: true, size: 'small' },
	{ label: 'Nom', type: 'text', name: 'nom', size: 'small' },
	{ label: 'Prénom', type: 'text', name: 'prenom', size: 'small' },
	{ label: 'E-mail', type: 'email', name: 'email', size: 'big' },
];

function Contacts({ user, loading, setUser }: CompteSectionProps) {
	function setContact(index: number, contact: Compte['contacts'][0]) {
		const contacts = [...user.contacts];
		contacts[index] = contact;
		setUser({ ...user, contacts });
	}

	const hasNoType = !!user.contacts.find((c) => !c.type);

	return (
		<section className={sectionStyles.section}>
			<h1 className={sectionStyles.title}>Contacts</h1>

			{user.contacts.map((contact, index) => (
				<div key={contact.id} className={styles.contact}>
					<h2 className={sectionStyles.subtitle}>
						{contact.type ? `Contact ${contact.type}` : 'Nouveau contact'}

						<BtnPoubelle
							title="Supprimer le contact"
							onClick={() => {
								const contacts = [...user.contacts];
								contacts.splice(index, 1);
								setUser({ ...user, contacts });
							}}
						/>
					</h2>

					{fields.map((field, i) => (
						<Input
							key={i}
							loading={loading}
							type={field.type}
							required={field.required}
							value={contact[field.name]}
							className={styles[field.size]}
							label={field.label + (field.required ? '*' : '')}
							setValue={(val) =>
								setContact(index, { ...contact, [field.name]: val })
							}
						/>
					))}

					<Input
						type="text"
						loading={loading}
						className={styles.big}
						label="Téléphones (séparés par des virgules)"
						value={contact.telephones.join(', ')}
						setValue={(val) => {
							const telephones = val
								.replace(/,(\S)/g, ', $1') // on ajoute une espace après les virgules
								.replace(/ ,/g, ',') // on enlève les espèces avant les virgules
								.replace(/ +/g, ' ') // on enlève les doublons d'espaces
								.split(', '); // on split le tout pour récupérer tous les tels
							setContact(index, { ...contact, telephones });
						}}
					/>
				</div>
			))}

			<Button
				outline
				disabled={hasNoType}
				className={styles.addBtn}
				title={
					hasNoType ? "Il manque un type sur l'un de vos contacts" : undefined
				}
				onClick={() => {
					const newContact = {
						id: new Date().getTime().toString(),
						nom: '',
						type: '',
						email: '',
						prenom: '',
						telephones: [],
					};
					const contacts = [...user.contacts, newContact];
					setUser({ ...user, contacts });
				}}
			>
				Ajouter un contact
			</Button>
		</section>
	);
}

export default Contacts;
