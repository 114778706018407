import React, { memo, useEffect, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { Breakpoint, media } from '../../../utils/breakpoints/breakpoints';
import styles from './Img.module.scss';

export interface Props {
	/**
	 * Must have the image's width at the end of the name
	 * @example `/media/uploads/bla@250.webp`
	 */
	src: string;
	alt: string;
	/** Fallback for browsers that doesn't know webp */
	fallback: 'png' | 'jpg';
	/**
	 * All the other widths of the image
	 * Note: the images must have the width in their filename.
	 * @example `image@250.webp / image@500.webp / image@750.webp`
	 */
	otherSizes: number[];
	className?: string;
	/**
	 * All the widths the image will have relative to window width.
	 * @warning The order matters!
	 * The browser goes over each media query until it finds one that matches.
	 */
	mediaQueries: {
		breakpoint?: Breakpoint;
		/** width of the img (in px, %, vw...) */
		width: string | number;
	}[];
}

export function _Img({
	src,
	alt,
	fallback,
	className,
	otherSizes,
	mediaQueries,
}: Props) {
	const [error, setError] = useState(false);
	src = src.replace(/ /g, '%20');
	const bareFilePath = src.substr(0, src.lastIndexOf('@'));
	const defaultSize = parseInt(src.substr(src.lastIndexOf('@') + 1));
	const ext = src.substr(src.lastIndexOf('.') + 1);
	const err = useRef(false);
	const [isfav, setFav] = useState(
		window.location.pathname === '/produits-favoris',
	);
	useEffect(() => {
		err.current = error;
	}, [error]);
	useEffect(() => {
		if (err.current) setError(false);
	}, [src]);

	if (!defaultSize) {
		// throw new Error(`L'image ${src} doit avoir sa width dans son filename`);
	}

	if (ext !== 'webp') {
		// throw new Error(`L'image' '${src}' doit être au format Webp`);
	}

	function generateSrcSet(extension: string) {
		return [defaultSize, ...otherSizes]
			.map((size) => `${bareFilePath}@${size}.${extension} ${size}w`)
			.join(', ');
	}

	const sizes = mediaQueries
		.map(({ breakpoint, width }) => {
			const imgWidth = typeof width === 'number' ? width + 'px' : width;
			if (breakpoint) {
				return media[breakpoint] + ' ' + imgWidth;
			} else {
				return imgWidth;
			}
		})
		.join(', ');

	return ext === 'svg' ? (
		<div className={styles.divSVG}>
			<div className={styles.test}>
				{src && <ReactSVG src={src} className={styles.picto} />}
			</div>
		</div>
	) : (
		<div>
			<figure className={[styles.figure, className].filter(Boolean).join(' ')}>
				{!error ? (
					<picture>
						<source
							type="image/webp"
							srcSet={generateSrcSet('webp')}
							sizes={sizes}
						/>
						<img
							src={src.replace('.webp', '.' + fallback)}
							alt={alt}
							srcSet={generateSrcSet(fallback)}
							sizes={sizes}
							onError={() => setError(true)}
							className={styles.img}
							loading="lazy"
							width={defaultSize}
						/>
					</picture>
				) : (
					<svg
						viewBox="0 0 16 9"
						className={styles.imgNotFound}
						textAnchor="middle"
						fontSize="2"
					>
						<text x="8" y="3.7">
							Image non
						</text>
						<text x="8" y="6.2">
							trouvée
						</text>
						<desc>{src}</desc>
					</svg>
				)}
			</figure>
		</div>
	);
}

export default memo(_Img, (prev, next) => prev.src === next.src);
