import { DocumentNode } from 'graphql';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useUnmount } from '../../../hooks/useUnmount';
import { AppState } from '../../../store';
import { getLoggedInUser } from '../../../store/compte/actions';
import { User } from '../../../store/compte/types';
import request from '../../../utils/request';
import styles from './CompteUser.module.scss';
import Formulaire from './Formulaire/Formulaire';
import { Compte, CompteSections } from './types';
import { updateUserAccount } from './update';

interface Props {
	idUser: User['id'];
	query: DocumentNode;
	initialState: Compte;
	mutation: DocumentNode;
	sections: CompteSections;
	refetchUser: typeof getLoggedInUser;
}

export function _CompteUser({
	query,
	idUser,
	sections,
	mutation,
	refetchUser,
	initialState,
}: Props) {
	const { isMounted } = useUnmount();

	const [error, setError] = useState('');
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState(initialState);
	const [pristineUser, setPristineUser] = useState(initialState);

	// FETCH USER AT INIT
	useEffect(() => {
		request<{ user: Compte }>(query, { idUser })
			.then((res) => {
				if (!isMounted.current) return;
				setUser(res.user);
				setPristineUser(res.user);
			})
			.catch((err) => isMounted.current && setError(err.message))
			.finally(() => isMounted.current && setLoading(false));
	}, [idUser, isMounted, query]);

	// UPDATE USER
	function submit() {
		setError('');
		setLoading(true);

		const params = { user, idUser, mutation, pristineUser };
		updateUserAccount<Compte>(params)
			.then((newUser) => {
				if (!isMounted.current) return;
				setUser(newUser);
				setPristineUser(newUser);
				refetchUser(); // On refetch les infos de base du user pour actualiser l'interface (bouton menu et tout)
			})
			.catch((err) => isMounted.current && setError(err.message))
			.finally(() => isMounted.current && setLoading(false));
	}

	return error ? (
		<p className={styles.error}>{error}</p>
	) : (
		<Formulaire
			user={user}
			loading={loading}
			onSubmit={submit}
			setUser={setUser}
			sections={sections}
			reset={() => setUser(pristineUser)}
			hasChanged={JSON.stringify(user) !== JSON.stringify(pristineUser)}
		/>
	);
}

function mapStateToProps(state: AppState) {
	return { idUser: state.compte.id };
}

export default connect(mapStateToProps, { refetchUser: getLoggedInUser })(
	_CompteUser,
);
