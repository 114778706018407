import { ThunkAction } from 'redux-thunk';

interface Actualite {
	id: string;
	createdAt: Date;
	updatedAt: Date;

	titre: string;
	contenu: string;
	imageSrc: string;
	url?: string;

	ordre: number;
}

export interface ActualitesState {
	error?: string;
	loading: boolean;
	list: Pick<Actualite, 'id' | 'titre' | 'contenu' | 'imageSrc' | 'url'>[];
}

export const GET_ACTUALITES_START = 'GET_ACTUALITES_START';
export const GET_ACTUALITES_SUCCESS = 'GET_ACTUALITES_SUCCESS';
export const GET_ACTUALITES_FAIL = 'GET_ACTUALITES_FAIL';

interface GetActualitesStartAction {
	type: typeof GET_ACTUALITES_START;
}

interface GetActualitesSuccessAction {
	type: typeof GET_ACTUALITES_SUCCESS;
	list: ActualitesState['list'];
}

interface GetActualitesFailAction {
	type: typeof GET_ACTUALITES_FAIL;
	error: string;
}

export type ActualitesActionTypes =
	| GetActualitesStartAction
	| GetActualitesSuccessAction
	| GetActualitesFailAction;

export type ActualitesThunkResult = ThunkAction<
	any,
	ActualitesState,
	{},
	ActualitesActionTypes
>;
