import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useTitle } from '../../../../hooks/useTitle';
import { passwordMinLength } from '../../../../utils/password';
import request from '../../../../utils/request';
import Button from '../../Button/Button';
import DoneMessage from '../../Form/DoneMessage/DoneMessage';
import ErrorMessage from '../../Form/ErrorMessage/ErrorMessage';
import Form from '../../Form/Form';
import Input from '../../Form/Input/Input';
import SmallLink from '../../Form/SmallLink/SmallLink';

const mutation = loader('./updatePassword.graphql');

export function _NouveauMotDePasse({ location }: RouteComponentProps) {
	useTitle('Nouveau mot de passe');

	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [done, setDone] = useState(false);

	const searchParams = new URLSearchParams(location.search);
	const tempToken = searchParams.get('token') || '';

	async function onSubmit() {
		setLoading(true);
		try {
			await request(mutation, { newPassword, tempToken });
			setDone(true);
		} catch (err) {
			setError(err.message);
		} finally {
			setLoading(false);
		}
	}

	function handleNewPassword(val: string) {
		setError('');
		setNewPassword(val);
	}

	function handleConfirmPassword(val: string) {
		setError('');
		setConfirmPassword(val);
	}

	return !done ? (
		<Form onSubmit={onSubmit}>
			<Input
				label="Nouveau mot de passe"
				type="password"
				value={newPassword}
				setValue={handleNewPassword}
				minLength={passwordMinLength}
				required
				autoFocus
			/>

			<Input
				label="Confirmer nouveau mot de passe"
				type="password"
				value={confirmPassword}
				setValue={handleConfirmPassword}
				minLength={passwordMinLength}
				pattern={newPassword}
				title="Vous devez saisir le même mot de passe que dans le 1er champ"
				required
			/>

			<ErrorMessage>{error}</ErrorMessage>

			<Button
				submit
				loading={loading}
				disabled={!newPassword || newPassword !== confirmPassword}
			>
				Confirmer
			</Button>

			<SmallLink to="/connexion">Retour à l'écran de connexion</SmallLink>
		</Form>
	) : (
		<DoneMessage
			link={{ to: '/connexion', text: "Retourner à l'écran de connexion" }}
		>
			Votre mot de passe a été changé avec succès.
		</DoneMessage>
	);
}

export default withRouter(_NouveauMotDePasse);
