import React, { ReactNode } from 'react';
import styles from './SideMenuWrapper.module.scss';

interface Props {
	children: ReactNode;
	className?: string;
}

function SideMenuWrapper({ children, className }: Props) {
	return (
		<div className={[styles.wrapper, className].filter(Boolean).join(' ')}>
			{children}
		</div>
	);
}

export default SideMenuWrapper;
