import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../store';
import { closeAlertes, openAlertes } from '../../../store/alertes/actions';
import Count from '../Count/Count';
import { ReactComponent as SVG } from './bell.svg';
import styles from './BtnAlertes.module.scss';

export const btnAlertesId = 'btnAlertesId';

export interface Props {
	countNonLues: number;
	isAlertesOpen: boolean;
	openAlertes: typeof openAlertes;
	closeAlertes: typeof closeAlertes;
}

export function _BtnAlertes({
	countNonLues,
	isAlertesOpen,
	openAlertes,
	closeAlertes,
}: Props) {
	return (
		<button
			id={btnAlertesId}
			title={`${isAlertesOpen ? 'Fermer' : 'Ouvrir'} les alertes`}
			onClick={() => (isAlertesOpen ? closeAlertes() : openAlertes())}
			className={[styles.btn, isAlertesOpen && styles.isAlertesOpen]
				.filter(Boolean)
				.join(' ')}
		>
			<SVG className={styles.svg} />
			<Count
				count={countNonLues}
				inverted={isAlertesOpen}
				style={{ marginLeft: -10, marginRight: -5 }}
			/>
		</button>
	);
}

function mapStateToProps(state: AppState) {
	return {
		countNonLues: state.alertes.list.filter(a => !a.lu).length,
		isAlertesOpen: state.alertes.isOpen,
	};
}

export default connect(mapStateToProps, { openAlertes, closeAlertes })(
	_BtnAlertes,
);
