import React from 'react';
import styles from './Label.module.scss';

export interface Props {
	children: string;
}

function Label({ children }: Props) {
	let labels = children.split(/\n/g);
	let labelsWithDash = children.split(/-/g);
	let tiret = children.indexOf('-');

	return (
		<>
			{tiret > 0
				? labelsWithDash.map((label) => {
						return (
							<span className={styles.label} key={label}>
								{label}
							</span>
						);
				  })
				: labels.map((label) => {
						return (
							<span className={styles.label} key={label}>
								{label}
							</span>
						);
				  })}
		</>
	);
	// return <span className={styles.label}>{children}</span>;
}

export default Label;
