import React from 'react';
import Gradient from '../../../Common/Gradient/Gradient';
import SideMenuWrapper from '../../../Common/SideMenuWrapper/SideMenuWrapper';
import Categories from '../Common/Categories/Categories';
import Selections from '../Common/Selections/Selections';
import styles from './SelectionsCategoriesMobile.module.scss';

function SelectionsCategoriesMobile() {
	return (
		<div className={styles.wrapper}>
			<Gradient />

			<SideMenuWrapper>
				<Categories />
				<Selections />
			</SideMenuWrapper>
		</div>
	);
}

export default SelectionsCategoriesMobile;
