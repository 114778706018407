import React, { ComponentProps, useState } from 'react';
import { Link } from 'react-router-dom';
import { Pick2 } from 'ts-multipick';
import { Fournisseur } from '../../../../types';
import {
	photosFournisseursSizes,
	placeholderSizes,
} from '../../../../utils/imgSizes';
import Button from '../../../Common/Button/Button';
import Img from '../../../Common/Img/Img';
import PopinContacts from '../../../Common/PopinContacts/PopinContacts';
import Skeleton from '../Skeleton/Skeleton';
import styles from './InfosGenerales.module.scss';

interface Props {
	data:
		| null
		| (Pick<Fournisseur, 'enseigne' | 'raisonSociale' | 'photo'> &
				Pick2<Fournisseur, 'secteurActivite', 'nom'> &
				Pick<ComponentProps<typeof PopinContacts>, 'contacts'>);
}

function InfosGenerales({ data }: Props) {
	const [popinContactsOpen, setPopinContactsOpen] = useState(false);

	return (
		<section className={styles.wrapper}>
			<h1 className={styles.title}>
				{data ? data.enseigne : <Skeleton size="full" />}
			</h1>

			<p className={styles.subtitle}>
				{data ? data.secteurActivite.nom : <Skeleton size="small" />}
			</p>

			{data ? (
				<Img
					alt={data.enseigne}
					className={styles.photo}
					fallback={data.photo ? 'jpg' : 'png'}
					mediaQueries={[{ width: styles.photoWidth }]}
					src={data.photo || '/media/misc/placeholder@120.webp'}
					otherSizes={data.photo ? photosFournisseursSizes : placeholderSizes}
				/>
			) : (
				<Skeleton className={styles.photo} size="full" />
			)}

			<Link
				style={!data ? { pointerEvents: 'none' } : {}}
				to={
					data
						? `/recherche?query=${data.enseigne
								.toLowerCase()
								.split(/[\s,]+/)
								.join('+')}`
						: ''
				}
			>
				<Button disabled={!data}>Voir la boutique</Button>
			</Link>

			<Button
				outline
				disabled={!data}
				className={styles.btnContacts}
				onClick={() => setPopinContactsOpen(true)}
			>
				Voir les contacts
			</Button>

			{data && (
				<PopinContacts
					contacts={data.contacts}
					isOpen={popinContactsOpen}
					close={() => setPopinContactsOpen(false)}
				/>
			)}
		</section>
	);
}

export default InfosGenerales;
