import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useTitle } from '../../../../hooks/useTitle';
import { UserType } from '../../../../store/compte/types';
import request from '../../../../utils/request';
import BtnBack from '../../BtnBack/BtnBack';
import Button from '../../Button/Button';
import DoneMessage from '../../Form/DoneMessage/DoneMessage';
import ErrorMessage from '../../Form/ErrorMessage/ErrorMessage';
import Form from '../../Form/Form';
import Input from '../../Form/Input/Input';
import styles from './MotDePasseOublie.module.scss';

const mutation = loader('./askForNewPassword.graphql');

type Props = { type: Exclude<UserType, 'Admin'> } & RouteComponentProps;

export function _MotDePasseOublie({ location, type }: Props) {
	useTitle('Mot de passe oublié');

	const searchParams = new URLSearchParams(location.search);

	const [login, setLogin] = useState(searchParams.get('login') || '');
	const [loading, setLoading] = useState(false);
	const [done, setDone] = useState(false);
	const [error, setError] = useState('');

	async function onSubmit() {
		setLoading(true);
		try {
			await request(mutation, { login, type });
			setDone(true);
		} catch (err) {
			setError(err.message);
		} finally {
			setLoading(false);
		}
	}

	return !done ? (
		<Form onSubmit={onSubmit}>
			<BtnBack
				withText
				className={styles.back}
				to={`/connexion-${
					type.toLowerCase() + (login ? '?login=' + login : '')
				}`}
			/>

			<h1 className={styles.title}>Mot de passe {type.toLowerCase()} oublié</h1>

			<Input
				label="Votre identifiant"
				value={login}
				setValue={(val) => setLogin(val)}
				required
				autoFocus
			/>

			<ErrorMessage>{error}</ErrorMessage>

			<Button submit loading={loading} disabled={!login}>
				Envoyer
			</Button>
		</Form>
	) : (
		<DoneMessage
			link={{ to: `/connexion-${type.toLowerCase()}`, text: 'Se connecter' }}
		>
			Un login avec un lien temporaire pour changer votre mot de passe vous a
			été envoyé. Vérifiez votre boîte de réception ainsi que vos spams.
		</DoneMessage>
	);
}

export default withRouter(_MotDePasseOublie);
