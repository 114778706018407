import React from 'react';
import {
	Link,
	NavLink,
	RouteComponentProps,
	withRouter,
} from 'react-router-dom';
import styles from './Pagination.module.scss';

export interface Props extends RouteComponentProps {
	loading: boolean;
	showCount?: boolean;
	totalItemsCount: number;
	itemsCountPerPage: number;
	className?: string;
}

export function _Pagination({
	loading,
	showCount,
	totalItemsCount,
	itemsCountPerPage,
	className,
	location: { pathname, search },
}: Props) {
	const pagesCount = Math.ceil((totalItemsCount / itemsCountPerPage) );
	 const ar = [...new Array(pagesCount)];
	const list = ar;
// .slice(0, ar.length -1);
	const searchParams = new URLSearchParams(search);
	const activePage = +(searchParams.get('page') || 1);

	function getPage(page: number) {
		searchParams.set('page', page.toString());
		return pathname + '?' + searchParams;
	}

	return (
		<div className={[styles.wrapper, className].filter(Boolean).join(' ')}>
			{/* Texte qui montre le nombre de résultats */}
			{loading ? (
				<p className={styles.count}>Chargement...</p>
			) : (
				showCount && (
					<p className={styles.count}>
						{totalItemsCount < 1
							? 'Aucun'
							: totalItemsCount <= itemsCountPerPage && totalItemsCount}
						{pagesCount > 1 &&
							`${(activePage - 1) * itemsCountPerPage + 1} - ${Math.min(
								totalItemsCount,
								activePage * itemsCountPerPage,
							)} sur ${totalItemsCount}`}{' '}
						résultat{totalItemsCount > 1 && 's'}
					</p>
				)
			)}

			{/* On n'affiche la navigation que s'il y a plus d'une page */}
			{pagesCount > 1 && (
				<nav className={styles.nav}>
					{/* Prev */}
					<Link
						to={getPage(activePage - 1)}
						className={[
							styles.link,
							styles.prev,
							activePage <= 1 && styles.disabled,
						]
							.filter(Boolean)
							.join(' ')}
					/>

					{/* Next */}
					<Link
						to={getPage(activePage + 1)}
						className={[
							styles.link,
							styles.next,
							activePage >= pagesCount && styles.disabled,
						]
							.filter(Boolean)
							.join(' ')}
					/>

					{/* Numéros */}
					{list.map((_, i) => {
						i = i + 1;
						if (
							![
								1,
								activePage - 1,
								activePage,
								activePage + 1,
								activePage + 2,
								pagesCount,
							].includes(i)
						) {
							if (i === 2 || i === pagesCount - 1) {
								return (
									<span
										key={i}
										className={[styles.link, styles.disabled].join(' ')}
									>
										...
									</span>
								);
							}
							return null;
						}
						return (
							<NavLink
								key={i}
								to={getPage(i)}
								className={styles.link}
								activeClassName={styles.active}
								isActive={() => activePage === i}
							>
								{i}
							</NavLink>
						);
					})}
				</nav>
			)}
		</div>
	);
}

export default withRouter(_Pagination);
