import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router';
import { useTitle } from '../../../hooks/useTitle';
import { useUnmount } from '../../../hooks/useUnmount';
import request from '../../../utils/request';
import FormulaireClient from '../FormulaireClient/FormulaireClient';
import { AppState } from '../../../store';
import { connect } from 'react-redux';
import { CompteMinimalist } from '../../Common/CompteUser/types';

const createMutation = loader('./createClient.graphql');
const createContact = loader('./createClientContact.graphql');
const fournisseurs = loader('./getFournisseur.graphql');

type Props = {
	compte: CompteMinimalist;
};

function CreationClient({ compte }: Props) {
	const { id: idFournisseur } = compte;
	useTitle('Nouveau client');

	const { isMounted } = useUnmount();

	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [done, setDone] = useState(false);

	const [disabled, changeDisabled] = useState('');
	const [login, changeLogin] = useState('');
	const [email, changeEmail] = useState('');

	const [raisonSociale, changeRaisonSociale] = useState('');
	const [enseigne, changeEnseigne] = useState('');
	const [siret, changeSiret] = useState('');
	const [description, changeDescription] = useState('');
	const [search, changeSearch] = useState('');
	const [prelevementAuto, changePrelevementAuto] = useState('');
	const [kbis, changeKbis] = useState('');
	const [rib, changeRib] = useState('');
	const [horairesOuverture, changeHorairesOuverture] = useState('');
	const [nomContactReferent, changeNomContactReferent] = useState('');
	const [prenomContactReferent, changePrenomContactReferent] = useState('');
	const [emailContactReferent, changeEmailContactReferent] = useState('');
	const [telephone1ContactReferent, changeTelephone1ContactReferent] = useState(
		'',
	);
	const [telephone2ContactReferent, changeTelephone2ContactReferent] = useState(
		'',
	);
	const [nomContactCuisine, changeNomContactCuisine] = useState('');
	const [prenomContactCuisine, changePrenomContactCuisine] = useState('');
	const [emailContactCuisine, changeEmailContactCuisine] = useState('');
	const [telephoneContactCuisine, changeTelephoneContactCuisine] = useState('');
	const [nomContactCompta, changeNomContactCompta] = useState('');
	const [prenomContactCompta, changePrenomContactCompta] = useState('');
	const [emailContactCompta, changeEmailContactCompta] = useState('');
	const [telephoneContactCompta, changeTelephoneContactCompta] = useState('');
	const [secteurActivite, changeSecteurActivite] = useState('');
	const [numeroVoieLivraison, changeNumeroVoieLivraison] = useState('');
	const [codePostalLivraison, changeCodePostalLivraison] = useState('');
	const [villeLivraison, changeVilleLivraison] = useState('');
	const [numeroVoieFacturation, changeNumeroVoieFacturation] = useState('');
	const [codePostalFacturation, changeCodePostalFacturation] = useState('');
	const [villeFacturation, changeVilleFacturation] = useState('');
	const [
		dateFermetureExceptionnelle,
		changeDateFermetureExceptionnelle,
	] = useState('');
	const [fournisseursAutorise, changeFournisseursAutorise] = useState([]);
	const [fournisseursAutoriseVal, changeFournisseursAutoriseVal] = useState('');
	const [imgKbisSrc, setKbis] = useState<File | undefined>();
	const [imgRibSrc, setRib] = useState<File | undefined>();

	const [photo, changePhoto] = useState('');
	const [imgSrc, setPhoto] = useState<File | undefined>();

	const [iban, changeIban] = useState('');
	const [bic, changeBic] = useState('');
	const [banque, changeBanque] = useState('');
	const [contacts, changeContacts] = useState('');
	const [clients, changeClients] = useState([
		{ id: '', email: '', nom: '', first: '', type: '', tel: '' },
	]);
	const [backLink, setBackLink] = useState<string>('');

	useEffect(() => {
		setError('');
		setLoading(true);
		let isMounted = true;
		type TResponse2 = { fournisseurs: [] };
		request<TResponse2>(fournisseurs)
			.then((res: any) => {
				if (res) {
					changeFournisseursAutorise(
						res.fournisseurs.filter((e: any) => !e.disabled),
					);
				}
			})
			.catch((err) => {
				setError(err.message);
			})
			.finally(() => setLoading(false));

		const link = idFournisseur.includes('.ADMIN')
			? '/admin/clients'
			: '/clients';
		setBackLink(link);

		return () => {
			isMounted = false;
		};
	}, [isMounted, idFournisseur]);

	async function submit() {
		try {
			setError('');
			setLoading(true);
			const dataResponse = await request(createMutation, {
				raisonSociale,
				enseigne,
				siret,
				description,
				secteurActivite,
				numeroVoieFacturation,
				villeFacturation,
				codePostalFacturation,
				login,
				email,
				search,
				prelevementAuto,
				photo: imgSrc,
				kbis: imgKbisSrc,
				rib: imgRibSrc,
				horairesOuverture,
				dateFermetureExceptionnelle,
				banque,
				fournisseursAutoriseVal,
			});
			if (
				clients.length > 0 &&
				clients[0].email !== '' &&
				clients[0].nom !== '' &&
				clients[0].first !== ''
			) {
				clients.forEach(async (item) => {
					await request(createContact, {
						clientId: (dataResponse as any).createClient.id,
						type: item.type,
						tel: item.tel,
						nom: item.nom,
						prenom: item.first,
						email: item.email,
					});
				});

				// if (fournisseursAutoriseVal.length > 0) {
				// 	fournisseursAutoriseVal.forEach(async (item) => {
				// 		// console.log(item);
				// 		await request(associateFournisseurs, {
				// 			id: (dataResponse as any).createClient.id,
				// 			fournisseurId: item,
				// 		});
				// 	});
				// }

				if (isMounted.current) setDone(true);
			} else {
				if (isMounted.current) setDone(true);
			}
		} catch (err) {
			setError(err.message);
			setLoading(false);
		}
	}

	return done ? (
		<Redirect to={backLink} />
	) : (
		<FormulaireClient
			type="Création"
			backLink={backLink}
			{...{
				error,
				loading,
				disabled,
				login,
				clients,
				changeClients,
				email,
				raisonSociale,
				enseigne,
				nomContactReferent,
				prenomContactReferent,
				emailContactReferent,
				telephone1ContactReferent,
				telephone2ContactReferent,
				nomContactCuisine,
				prenomContactCuisine,
				emailContactCuisine,
				telephoneContactCuisine,
				nomContactCompta,
				prenomContactCompta,
				emailContactCompta,
				telephoneContactCompta,
				secteurActivite,
				numeroVoieLivraison,
				codePostalLivraison,
				villeLivraison,
				numeroVoieFacturation,
				codePostalFacturation,
				villeFacturation,
				siret,
				description,
				search,
				prelevementAuto,
				kbis,
				rib,
				setRib,
				iban,
				bic,
				banque,
				photo,
				contacts,
				horairesOuverture,
				dateFermetureExceptionnelle,
				fournisseursAutorise,
				changeDisabled,
				changeLogin,
				changeEmail,
				changeRaisonSociale,
				fournisseursAutoriseVal,
				changeFournisseursAutoriseVal,
				changeEnseigne,
				changeSiret,
				changeNomContactReferent,
				changePrenomContactReferent,
				changeEmailContactReferent,
				changeTelephone1ContactReferent,
				changeTelephone2ContactReferent,
				changeNomContactCuisine,
				changePrenomContactCuisine,
				changeEmailContactCuisine,
				changeTelephoneContactCuisine,
				changeNomContactCompta,
				changePrenomContactCompta,
				changeEmailContactCompta,
				changeTelephoneContactCompta,
				changeSecteurActivite,
				changeNumeroVoieLivraison,
				changeCodePostalLivraison,
				changeVilleLivraison,
				changeNumeroVoieFacturation,
				changeCodePostalFacturation,
				changeVilleFacturation,

				changeDescription,
				changeSearch,
				changePrelevementAuto,
				changeKbis,
				changeBanque,
				changeBic,
				changeIban,
				setKbis,
				changeRib,
				changePhoto,
				setPhoto,
				changeHorairesOuverture,
				changeDateFermetureExceptionnelle,
				changeFournisseursAutorise,
				changeContacts,
				submit,
			}}
		/>
	);
}

const mapStateToProps = (state: AppState) => ({
	compte: state.compte,
});

export default withRouter(connect(mapStateToProps)(CreationClient));
