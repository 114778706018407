import { combineReducers } from 'redux';
import { actualitesReducer } from './actualites/reducers';
import { alertesReducer } from './alertes/reducers';
import { categoriesReducer } from './categories/reducers';
import { compteReducer } from './compte/reducers';
import { conditionsPaiementReducer } from './conditionsPaiement/reducers';
import { filtresReducer } from './filtres/reducers';
import { isOnlineReducer } from './isOnline/reducers';
import { panierReducer } from './panier/reducers';
import { secteursActiviteReducer } from './secteursActivite/reducers';
import { selectionsReducer } from './selections/reducers';

export const rootReducer = combineReducers({
	actualites: actualitesReducer,
	alertes: alertesReducer,
	categories: categoriesReducer,
	compte: compteReducer,
	filtres: filtresReducer,
	isOnline: isOnlineReducer,
	panier: panierReducer,
	selections: selectionsReducer,
	secteursActivite: secteursActiviteReducer,
	conditionsPaiement: conditionsPaiementReducer,
});
