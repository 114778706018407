import formatDate from 'dateformat';
import React from 'react';
import MediaQuery from 'react-responsive';
import { StatutCommandeLigne } from '../../../../types';
import { media } from '../../../../utils/breakpoints/breakpoints';
import { convertStatus, statusList } from '../../../../utils/french';
import BtnBack from '../../../Common/BtnBack/BtnBack';
import Button from '../../../Common/Button/Button';
import { QueryResponse } from '../DetailCommande';
import styles from './Header.module.scss';
import Info from './Info/Info';

type Props = { openPopin: () => void } & Partial<
	Pick<QueryResponse['commande'], 'client' | 'dateLivraison'> & {
		statut: StatutCommandeLigne;
	}
>;

function Header({ client, statut, openPopin, dateLivraison }: Props) {
	const index = statusList.indexOf(statut as any);
	const nextStatut = index > -1 ? statusList[index + 1] : undefined;

	const contact = client?.contacts[0];

	return (
		<>
			{/* 1ère ligne */}
			<div className={styles.line}>
				<MediaQuery query={media['>=tablet']}>
					<BtnBack to="/commandes" withText />
				</MediaQuery>

				<Info
					label="Date de livraison souhaitée"
					className={styles.dateLivraison}
				>
					{dateLivraison && formatDate(dateLivraison, 'dd/mm/yyyy')}
				</Info>

				<Info label="Statut">{statut && convertStatus(statut)}</Info>

				{nextStatut && (
					<Button onClick={openPopin} className={styles.buttonUpdateStatut}>
						Mettre à jour le statut
					</Button>
				)}
			</div>

			<div className={styles.line}>
				<Info label="Client" className={styles.client}>
					{client && `${client.enseigne} - ${client.raisonSociale}`}
				</Info>

				{/* Si on n'a pas encore chargé le client ou qu'il y a un contact */}
				{(!client || contact) && (
					<>
						<Info label="Contact">
							{contact && `${contact.prenom} ${contact.nom}`}
						</Info>

						<Info label="E-mail">
							{contact && (
								<a
									target="_blank"
									rel="noopener noreferrer"
									href={`mailto:${contact.email}`}
								>
									{contact.email}
								</a>
							)}
						</Info>

						<Info
							label={`Téléphone${
								contact && contact.telephones.length > 1 ? 's' : ''
							}`}
						>
							{contact &&
								contact.telephones.map((t, i) => (
									<a key={i} href={`tel:${t}`} className={styles.telephone}>
										{t.replace(/ /g, '\u202f')}
									</a>
								))}
						</Info>
					</>
				)}
			</div>
		</>
	);
}

export default Header;
