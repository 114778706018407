import formatDate from 'dateformat';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { convertStatus, convertUnite, french } from '../../../../utils/french';
import Table from '../../../Common/Table/Table';
import { RowProps } from '../../../Common/Table/types';
import { QueryResponse } from '../Commandes';
import styles from './ListeCommandes.module.scss';

interface Props extends RouteComponentProps {
	error?: string;
	loading: boolean;
	noResultMessage: string;
	commandes: QueryResponse['commandes'];
}

export function _ListeCommandes({
	error,
	loading,
	commandes,
	noResultMessage,
	location: { pathname },
}: Props) {
	return (
		<Table
			error={error}
			loading={loading}
			noResultMessage={noResultMessage}
			columns={[
				{ content: 'numero' },
				{ content: 'Date de livraison' },
				{ content: 'Client' },
				{ content: 'Contact' },
				{ content: 'Statut' },
				{ content: 'Produits' },
				{ content: 'Factures' },
			]}
			rows={commandes.map((c) => {
				const produits = c.lignes
					.map((l) => {
						const unite = convertUnite(l.produit.unite, l.quantite);
						return `${l.produit.nom} (${l.quantite} ${unite})`;
					})
					.join(' ; ');

				const contact = c.client.contacts[0] || {};

				return {
					id: c.id,
					href: `/commande/${c.id}?from=${pathname}`,
					mobileTitle: <p>Commande de {c.client.enseigne}</p>,
					cells: [
						{ content: c.numero },
						{ content: formatDate(c.dateLivraison, 'dd/mm/yyyy') },
						{
							className: styles.client,
							content: (
								<>
									<strong>{c.client.enseigne}</strong>
									<br />
									{c.client.raisonSociale}
								</>
							),
						},
						{ content: c.client.email },
						{ content: convertStatus(c.lignes[0].statut) },
						{ content: <p className={styles.produits}>{french(produits)}</p> },
						{
							content: c.facture?.id ? (
								<img
									src={'/media/misc/facture.png'}
									className={styles.facture}
									alt=""
								/>
							) : (
								''
							),
						},
					] as RowProps['cells'],
				} as RowProps;
			})}
		/>
	);
}

export default withRouter(_ListeCommandes);
