import { ReactComponent as SVG } from './account.svg';
import React from 'react';
import styles from './BtnConnexion.module.scss';

interface Props {
	title: string;
	onClick: Function;
	className?: string;
}

function BtnConnexion({ className, title, onClick }: Props) {
	return (
		<button
			type="button"
			title={title}
			onClick={() => onClick()}
			className={[styles.wrapper, className].filter(Boolean).join(' ')}
		>
			<SVG className={styles.picto} />
		</button>
	);
}


export default BtnConnexion;
