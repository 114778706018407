import {
	CompteActionTypes,
	CompteState,
	GET_LOGGED_IN_USER_FAIL,
	GET_LOGGED_IN_USER_SUCCESS,
	HIDE_LOGIN_ERROR,
	LOGIN_FAIL,
	LOGIN_START,
	LOGIN_SUCCESS,
	LOGOUT_FAIL,
	LOGOUT_START,
	LOGOUT_SUCCESS,
} from './types';

const initialState: CompteState = {
	id: '',
	nom: '',
	type: 'Client',
	isLoggedIn: false,
	isLoggingIn: false,
	isLoggingOut: false,
	isGettingLoggedInUser: true,
};

export function compteReducer(
	state = initialState,
	action: CompteActionTypes,
): CompteState {
	switch (action.type) {
		case GET_LOGGED_IN_USER_SUCCESS:
			if (action.response.user) {
				return {
					...state,
					isGettingLoggedInUser: false,
					isLoggedIn: true,
					...action.response.user,
				};
			} else {
				return {
					...state,
					isGettingLoggedInUser: false,
				};
			}

		case GET_LOGGED_IN_USER_FAIL:
			return {
				...state,
				isGettingLoggedInUser: false,
				error: action.error,
			};

		case LOGIN_START:
			return {
				...state,
				isLoggingIn: true,
				error: undefined,
			};

		case LOGIN_SUCCESS:
			return {
				...state,
				...action.response.user,
				isLoggingIn: false,
				isLoggedIn: true,
			};

		case LOGIN_FAIL:
			return {
				...state,
				isLoggingIn: false,
				error: action.error,
			};

		case HIDE_LOGIN_ERROR:
			return {
				...state,
				error: undefined,
			};

		case LOGOUT_START:
			return {
				...state,
				isLoggingOut: true,
				error: undefined,
			};

		case LOGOUT_SUCCESS:
			return {
				...state,
				isLoggedIn: false,
				isLoggingOut: false,
			};

		case LOGOUT_FAIL:
			return {
				...state,
				isLoggingOut: false,
				error: action.error,
			};

		default:
			return state;
	}
}
