import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AppState } from '../../../../store';
import { User } from '../../../../store/compte/types';
import {
	changeDateLivraisonPanier,
	changeNomPanier,
	emptyPanier,
	getInfoProduitsPanier,
	removePanierRecurrence,
	setPanierAsCommandeFavorite,
	setPanierRecurrence,
} from '../../../../store/panier/actions';
import { PanierFav, PanierState } from '../../../../store/panier/types';
import { NewCommande } from '../../../../types';
import { thinSpace } from '../../../../utils/french';
import { loadStateFromLocalStorage } from '../../../../utils/localStorage';
import request from '../../../../utils/request';
import BtnFavori from '../../../Common/BtnFavori/BtnFavori';
import BtnPoubelle from '../../../Common/BtnPoubelle/BtnPoubelle';
import DatePicker from '../../../Common/DatePicker/DatePicker';
import NomCommande from '../../NomCommande/NomCommande';
import Recurrence from '../../Recurrence/Recurrence';
import styles from './Header.module.scss';

export interface Props extends PanierFav {
	idClient: User['id'];
	nom?: PanierState['nom'];
	favorite: PanierState['favorite'];
	lignesCount: number;
	dateLivraison?: PanierState['dateLivraison'];
	recurrenceMois?: PanierState['recurrenceMois'];
	recurrenceSemaine?: PanierState['recurrenceSemaine'];

	emptyPanier: typeof emptyPanier;
	changeNomPanier: typeof changeNomPanier;
	setPanierRecurrence: typeof setPanierRecurrence;
	getInfoProduitsPanier: typeof getInfoProduitsPanier;
	removePanierRecurrence: typeof removePanierRecurrence;
	changeDateLivraisonPanier: typeof changeDateLivraisonPanier;
	setPanierAsCommandeFavorite: typeof setPanierAsCommandeFavorite;
}

const mutation = loader('./createCommandeFav.graphql');

export function _Header({
	// State
	nom,
	favorite,
	idClient,
	lignesCount,
	dateLivraison,
	recurrenceMois,
	recurrenceSemaine,

	// Actions
	emptyPanier,
	changeNomPanier,
	setPanierRecurrence,
	getInfoProduitsPanier,
	removePanierRecurrence,
	changeDateLivraisonPanier,
	setPanierAsCommandeFavorite,
	...panier
}: Props) {
	const dateIn3Months = new Date();
	dateIn3Months.setMonth(dateIn3Months.getMonth() + 3);
	const [loading, setLoading] = useState(false);
	const [commandeCree, setCommandeCree] = useState(false);
	const [error, setError] = useState('');
	const [local, setLoacl] = useState({});
	const [crea, setCrea] = useState(false);
	const [rdirect, setRedirect] = useState(false);
	// const diabled = 'Lundi Mardi Mercredi ';
	// const [disabledDate, setDisabledDate] = useState(returnDateNotInArray(diabled.split(' ')).map((e) => {
	// 	return returnDateFormAcronyme(e.toString());
	// }).flat());

	// On reset la popin uniquement quand on ouvre la popin
	// useEffect(() => {
	// 	setLoading(false);
	// 	setCommandeCree(false);
	//
	// 	let localPanier = loadStateFromLocalStorage('panier');
	// 	setLoacl((localPanier as any));
	// 	setError('');
	// });

	async function placeOrder() {
		let localPanier = loadStateFromLocalStorage('panier');
		setLoacl(localPanier as any);
		try {
			setError('');
			setLoading(true);
			const newCommande = {
				...(panier as any).panier,
				dateLivraison: '',
				comments: [],
				cmdFav: '',
				lignes: (panier as any).panier.lignes.map((l: any, index: number) => {
					const tarif = (panier as any).panier.lignes[index].produit.tarif!
						.fournisseur.id;
					const prix = (panier as any).panier.lignes[index].produit.tarif!.prix;
					const unite = (panier as any).panier.lignes[index].uc;
					return {
						quantite: l.quantite,
						idProduit: l.produit.id,
						prixUnitaireAConfirmer: prix,
						idFournisseur: tarif,
						commentaire: l.commentaire ? l.commentaire : '',
					};
				}) as NewCommande['lignes'],
			};

			await request(mutation, newCommande);

			setTimeout(() => {
				setRedirect(true);
			}, 500);
		} catch (err) {
			setError(err.message);
		} finally {
			setLoading(false);
		}
	}

	async function favoriteFn() {
		setPanierAsCommandeFavorite(!favorite);
		await placeOrder();

		// if (!favorite && !crea) {
		// 	setCrea(true);
		// }
		// try {
		// 	const pan = (panier as any).panier;
		// 	console.log(pan);
		// 	const newCommande: any = {
		// 		...panier,
		// 		dateLivraison: formatDate(pan.dateLivraison!, 'yyyy-mm-dd'),
		// 		comments: [],
		// 		favorite: favorite,
		// 		lignes: (pan as any).lignes.map((l: any, index: number) => {
		// 			const tarif = pan.lignes[index].produit.tarif!.fournisseur.id;
		// 			const prix = pan.lignes[index].produit.tarif!.prix;
		// 			return {
		// 				quantite: l.quantite,
		// 				idProduit: l.idProduit ? l.idProduit : l.produit.id,
		// 				prixUnitaireAConfirmer: prix,
		// 				idFournisseur: tarif,
		// 				commentaire: l.commentaire ? l.commentaire : '',
		// 			};
		// 		}) as NewCommande['lignes'],
		// 	};
		//
		//
		// 	const { createCommande } = await request(mutation, newCommande);
		// 	console.log(createCommande.id);
		// 	await saveStateToLocalStorage('favCmd', createCommande.id)
		//
		// } catch (err) {
		// 	console.log(err);
		// 	setError(err.message);
		// } finally {
		// 	setLoading(false);
		// }
	}

	function emp() {
		setCrea(false);
		setPanierAsCommandeFavorite(false);
		emptyPanier();
	}

	return rdirect ? (
		<Redirect to="/commandes-favorites?orderBy=numero&order=DESC" />
	) : (
		<>
			<div className={styles.line}>
				<NomCommande
					nom={nom}
					loading={false}
					changeNom={changeNomPanier}
					className={styles.nom}
				/>

				<BtnFavori
					className={styles.favori}
					checked={false}
					onClick={() => favoriteFn()}
					title={
						favorite
							? 'Enlever la commande des favoris'
							: 'Ajouter la commande aux favoris'
					}
				/>

				<BtnPoubelle title="Vider le panier" onClick={() => emp()} />
			</div>

			<div className={[styles.line, styles.last].join(' ')}>
				<DatePicker
					required
					selected={dateLivraison}
					minDate={new Date()}
					maxDate={dateIn3Months}
					className={styles.livraison}
					label={<strong>Date de livraison désirée{thinSpace}:</strong>}
					onChange={(date) => {
						changeDateLivraisonPanier(date!);
						if (lignesCount) getInfoProduitsPanier(idClient);
					}}
				/>
				<Recurrence
					className={styles.recurrence}
					recurrenceMois={recurrenceMois}
					recurrenceSemaine={recurrenceSemaine}
					setRecurrence={({ mois, semaine }) => {
						if (mois) setPanierRecurrence({ mois });
						else if (semaine) setPanierRecurrence({ semaine });
						else removePanierRecurrence();
					}}
				/>
			</div>
		</>
	);
}

function mapStateToProps(state: AppState) {
	return {
		nom: state.panier.nom,
		idClient: state.compte.id,
		favorite: state.panier.favorite,
		lignesCount: state.panier.lignes.length,
		dateLivraison: state.panier.dateLivraison,
		recurrenceMois: state.panier.recurrenceMois,
		recurrenceSemaine: state.panier.recurrenceSemaine,
		panier: state.panier,
	};
}

export default connect(mapStateToProps, {
	emptyPanier,
	changeNomPanier,
	setPanierRecurrence,
	getInfoProduitsPanier,
	removePanierRecurrence,
	changeDateLivraisonPanier,
	setPanierAsCommandeFavorite,
})(_Header);
