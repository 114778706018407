import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../../store';
import { ConditionsPaiement } from '../../../../../store/conditionsPaiement/types';
import { SecteurActivite } from '../../../../../store/secteursActivite/types';
import Checkbox from '../../../Checkbox/Checkbox';
import Dropzone from '../../../Dropzone/Dropzone';
import Input from '../../../Form/Input/Input';
import inputStyles from '../../../Form/Input/Input.module.scss';
import Select from '../../../Form/Select/Select';
import { CompteSectionProps, CompteSections } from '../../types';
import sectionStyles from '../Formulaire.module.scss';

type Props = CompteSectionProps & {
	secteursActivite: SecteurActivite[];
	conditionsPaiement: ConditionsPaiement[];
	fields: CompteSections['informationsGenerales'];
};

export function _InformationsGenerales({
	user,
	fields,
	loading,
	setUser,
	...options
}: Props) {
	return (
		<section className={sectionStyles.section}>
			<h1 className={sectionStyles.title}>Informations générales</h1>

			<Dropzone
				imageWidthToDisplay={600}
				imageSrc={user.photo || undefined}
				className={[sectionStyles.column, inputStyles.label].join(' ')}
				onChange={({ file, tempURL }) =>
					setUser({ ...user, photo: tempURL, photoFile: file })
				}
			/>

			<div className={sectionStyles.column}>
				{fields.map((f, i) => {
					return f.type === 'boolean' ? (
						<label key={i} className={inputStyles.label}>
							<Checkbox
								loading={loading}
								checked={!!user[f.name]}
								onClick={() => setUser({ ...user, [f.name]: !user[f.name] })}
							>
								{user[f.name] ? 'Activé' : 'Désactivé'}
							</Checkbox>

							<p className={inputStyles.text}>{f.label}</p>
						</label>
					) : f.type === 'select' ? (
						<Select
							key={i}
							label={f.label}
							value={user[f.name] as any}
							setValue={(option) => setUser({ ...user, [f.name]: option })}
							options={options[f.options]}
						/>
					) : (
						<Input
							key={i}
							type={f.type}
							loading={loading}
							readOnly={f.readOnly}
							required={f.required}
							value={user[f.name] as string}
							label={f.label + (f.required ? '*' : '')}
							setValue={(val) => setUser({ ...user, [f.name]: val })}
						/>
					);
				})}
			</div>
		</section>
	);
}

function mapStateToProps(state: AppState) {
	return {
		secteursActivite: state.secteursActivite.list,
		conditionsPaiement: state.conditionsPaiement.list,
	};
}

export default connect(mapStateToProps)(_InformationsGenerales);
