import React from 'react';
import PositionedPopin from '../../../Common/Popin/PositionedPopin/PositionedPopin';
import OmniSearch from '../../OmniSearch/OmniSearch';
import styles from './PopinOmniSearch.module.scss';

export interface Props {
	isOpen: boolean;
	close: () => void;
}

function PopinOmniSearch({ isOpen, close }: Props) {
	return (
		<PositionedPopin
			isOpen={isOpen}
			onClose={close}
			noMarginOnMobile
			position={{ width: window.innerWidth }}
		>
			<OmniSearch className={styles.omniSearchOnMobile} onSubmit={close} />
		</PositionedPopin>
	);
}

export default PopinOmniSearch;
