import React from 'react';
import { Link } from 'react-router-dom';
import { CellProps, ColumnProps, RowProps } from '../../types';
import styles from './Buttons.module.scss';
import { ReactComponent as OeilSVG } from './oeil.svg';

type Props = Pick<RowProps, 'href'> & {
	cells: Pick<CellProps, 'content'>[];
	columns: Pick<ColumnProps, 'isButton' | 'mobileHidden'>[];
};

function Buttons({ href, columns, cells }: Props) {
	const cols = columns.filter(c => c.isButton && !c.mobileHidden);

	return href || !!cols.length ? (
		<div className={styles.wrapper}>
			{/* Tous les boutons */}
			{columns.map(
				// on doit filtrer ici aussi car le "i" correspond à chaque colonne, y compris celles qui ne sont pas des boutons
				(c, i) =>
					c.isButton &&
					!c.mobileHidden && <div key={i}>{cells[i].content}</div>,
			)}

			{/* Bouton pour accéder à l'url */}
			{href && (
				<Link to={href} className={styles.oeil}>
					<OeilSVG />
				</Link>
			)}
		</div>
	) : null;
}

export default Buttons;
