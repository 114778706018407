import { useEffect, useRef } from 'react';

export function useScrollToTop() {
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (ref.current) ref.current.scrollTop = 0;
	});

	return ref;
}
