import React from 'react';
import { useTitle } from '../../../hooks/useTitle';
import { thinSpace } from '../../../utils/french';
import Button from '../Button/Button';
import styles from './Contact.module.scss';
import { ReactComponent as LinkedInSVG } from './linkedin.svg';

const telephone = '01 83 75 62 00';
const email = 'contact@thebuyer.fr';
const adresse = '81 rue Réaumur, 75002 Paris';
const gmap = 'https://goo.gl/maps/3RYq3BwKyys';
const site = 'https://thebuyer.fr/';
const linkedin = 'https://www.linkedin.com/company/thebuyer.fr/';

export function Contact() {
	useTitle('Contact');

	return (
		<div className={styles.wrapper}>
			<h1 className={styles.title}>Nous contacter</h1>

			<div className={styles.separator}>
				<p>
					<strong>Téléphone Jean-Charles{thinSpace}:</strong>{' '}
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={'tel:' + '06 07 49 50 02'}
					>
						06 07 49 50 02
					</a>
				</p>
				<p>
					<strong>Par mail{thinSpace}:</strong>{' '}
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={'mailto:' + 'jcvetois@thebuyer.fr'}
					>
						jcvetois@thebuyer.fr
					</a>
				</p>
			</div>

			{/* SEPARATOR */}

			<div className={styles.separator}>
				<p>
					<strong>Téléphone Dino Dos Santos{thinSpace}:</strong>{' '}
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={'tel:' + '07 61 18 93 33'}
					>
						07 61 18 93 33
					</a>
				</p>
				<p>
					<strong>Par mail{thinSpace}:</strong>{' '}
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={'mailto:' + 'ddossantos@thebuyer.fr'}
					>
						ddossantos@thebuyer.fr
					</a>
				</p>
			</div>
			{/* NEW SEPARATOR */}

			<div className={styles.separator}>
				<p>
					<strong>Téléphone David Poumès{thinSpace}:</strong>{' '}
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={'tel:' + '06 64 90 64 09'}
					>
						06 64 90 64 09
					</a>
				</p>
				<p>
					<strong>Par mail{thinSpace}:</strong>{' '}
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={'mailto:' + 'dpoumes@thebuyer.fr'}
					>
						dpoumes@thebuyer.fr
					</a>
				</p>
			</div>
			<p>
				<strong>Par courrier{thinSpace}:</strong>{' '}
				<a target="_blank" rel="noopener noreferrer" href={gmap}>
					{adresse}
				</a>
			</p>

			<p className={styles.links}>
				<a
					href={site}
					target="_blank"
					rel="noopener noreferrer"
					className={styles.websiteLink}
				>
					<Button outline>Découvrez notre site internet</Button>
				</a>

				<a
					href={linkedin}
					target="_blank"
					rel="noopener noreferrer"
					title="LinkedIn"
					className={styles.social}
				>
					<LinkedInSVG />
				</a>
			</p>
		</div>
	);
}

export default Contact;
