import { ThunkAction } from 'redux-thunk';

interface Selection {
	// id: string;
	// createdAt: Date;
	// updatedAt: Date;

	nom: string;
	slug: string;
	pictoSrc: string;
}

export interface SelectionsState {
	loading: boolean;
	error?: string;
	list: Selection[];
}

export const GET_SELECTIONS_START = 'GET_SELECTIONS_START';
export const GET_SELECTIONS_SUCCESS = 'GET_SELECTIONS_SUCCESS';
export const GET_SELECTIONS_FAIL = 'GET_SELECTIONS_FAIL';

interface GetSelectionsStartAction {
	type: typeof GET_SELECTIONS_START;
}

interface GetSelectionsSuccessAction {
	type: typeof GET_SELECTIONS_SUCCESS;
	list: SelectionsState['list'];
}

interface GetSelectionsFailAction {
	type: typeof GET_SELECTIONS_FAIL;
	error: string;
}

export type SelectionsActionTypes =
	| GetSelectionsStartAction
	| GetSelectionsSuccessAction
	| GetSelectionsFailAction;

export type SelectionsThunkResult = ThunkAction<
	any,
	SelectionsState,
	{},
	SelectionsActionTypes
>;
