import React from 'react';
import { connect } from 'react-redux';
import { removeProduitFromPanier } from '../../../../store/panier/actions';
import { Produit } from '../../../../types';
import { thinSpace } from '../../../../utils/french';
import Button from '../../../Common/Button/Button';
import styles from './ProduitsIndisponibles.module.scss';

interface Props {
	lignes: Pick<Produit, 'id' | 'nom'>[];
	removeProduitFromPanier: typeof removeProduitFromPanier;
}

export function _ProduitsIndisponibles({
	lignes,
	removeProduitFromPanier,
}: Props) {
	return lignes.length ? (
		<div className={styles.wrapper}>
			<p className={styles.title}>
				Les produits suivants ne sont plus disponibles{thinSpace}:{' '}
				{lignes.map(l => l.nom).join(', ')}.
			</p>

			<p>
				Vous devez les enlever de votre panier avant de valider votre commande.
			</p>

			<Button
				inverted
				className={styles.button}
				title="Enlever ces produits du panier"
				onClick={() => lignes.forEach(l => removeProduitFromPanier(l.id))}
			>
				Enlever ces produits du panier
			</Button>
		</div>
	) : null;
}

export default connect(null, { removeProduitFromPanier })(
	_ProduitsIndisponibles,
);
