import React, { useEffect, useRef, useState } from 'react';
import inputStyles from '../Input/Input.module.scss';
import styles from './Select.module.scss';

type Option = { id: string; nom: string };

interface Props {
	label: string;
	value: Option;
	options: Option[];
	className?: string;
	setValue: (option: Option) => void;
}

function Select({ className, label, setValue, value, options }: Props) {
	const [open, setOpen] = useState(false);
	const ref = useRef<HTMLLabelElement>(null);

	useEffect(() => {
		function blur(e: MouseEvent) {
			if (
				e.target !== ref.current &&
				!ref.current?.contains(e.target as Node)
			) {
				setOpen(false);
			}
		}

		window.addEventListener('click', blur);

		return () => {
			window.removeEventListener('click', blur);
		};
	}, []);

	return (
		<label
			ref={ref}
			className={[
				inputStyles.label,
				styles.label,
				open && styles.open,
				className,
			]
				.filter(Boolean)
				.join(' ')}
		>
			<input
				readOnly
				value={value.nom}
				onClick={() => setOpen(!open)}
				className={inputStyles.input + ' ' + styles.input}
			/>

			<ul className={styles.list}>
				{options.map((option) => (
					<li
						key={option.id}
						onClick={() => setValue(option)}
						className={[styles.item, value.id === option.id && styles.active]
							.filter(Boolean)
							.join(' ')}
					>
						{option.nom}
					</li>
				))}
			</ul>

			<span className={inputStyles.text}>{label}</span>
		</label>
	);
}

export default Select;
