import React, { ReactNode } from 'react';
import styles from './TableHeader.module.scss';

interface Props {
	children: ReactNode;
	className?: string;
}

function TableHeader({ children, className }: Props) {
	const classNames = [styles.wrapper, className].filter(Boolean).join(' ');
	return <div className={classNames}>{children}</div>;
}

export default TableHeader;
