import {
	ActualitesActionTypes,
	ActualitesState,
	GET_ACTUALITES_FAIL,
	GET_ACTUALITES_START,
	GET_ACTUALITES_SUCCESS,
} from './types';

const initialState: ActualitesState = { list: [], loading: true };

export function actualitesReducer(
	state = initialState,
	action: ActualitesActionTypes,
): ActualitesState {
	switch (action.type) {
		case GET_ACTUALITES_START:
			return {
				...state,
				loading: true,
			};

		case GET_ACTUALITES_SUCCESS:
			return {
				...state,
				loading: false,
				list: action.list,
			};

		case GET_ACTUALITES_FAIL:
			return {
				...state,
				loading: false,
				error: action.error,
			};

		default:
			return state;
	}
}
