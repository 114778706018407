import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../store';
import { closePanier, openPanier } from '../../../store/panier/actions';
import Count from '../../Common/Count/Count';
import styles from './BtnPanier.module.scss';
import { ReactComponent as PanierSVG } from './panier-the-buyer.svg';

export interface Props {
	count: number;
	isPanierOpen: boolean;
	openPanier: typeof openPanier;
	closePanier: typeof closePanier;
}

export function _BtnPanier({
	count,
	isPanierOpen,
	openPanier,
	closePanier,
}: Props) {
	const btnClasses = [styles.btn, isPanierOpen && styles.isPanierOpen]
		.filter(Boolean)
		.join(' ');

	return (
		<button
			className={btnClasses}
			title={`${isPanierOpen ? 'Fermer' : 'Ouvrir'} le panier`}
			onClick={() => (isPanierOpen ? closePanier() : openPanier())}
		>
			<PanierSVG className={styles.svg} />
			<Count
				count={count}
				inverted={isPanierOpen}
				style={{ marginLeft: -10 }}
			/>
		</button>
	);
}

function mapStateToProps(state: AppState) {
	return {
		count: state.panier.lignes.length,
		isPanierOpen: state.panier.isOpen,
	};
}

export default connect(mapStateToProps, { openPanier, closePanier })(
	_BtnPanier,
);
