import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../store';
import { closePanier } from '../../../../store/panier/actions';
import CenteredPopin from '../../../Common/Popin/CenteredPopin/CenteredPopin';
import Panier from '../Panier';

export interface Props {
	isOpen: boolean;
	closePanier: typeof closePanier;
}

export function _PopinPanier({ isOpen, closePanier }: Props) {
	return (
		<CenteredPopin isOpen={isOpen} onCacheClick={closePanier}>
			<Panier />
		</CenteredPopin>
	);
}

function mapStateToProps(state: AppState) {
	return {
		isOpen: state.panier.isOpen,
	};
}

export default connect(mapStateToProps, { closePanier })(_PopinPanier);
