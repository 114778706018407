import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../store';
import {
	changeLignePanier,
	removeProduitFromPanier,
} from '../../../store/panier/actions';
import { Panier } from '../../../store/panier/types';
import { convertUnite } from '../../../utils/french';
import styles from './PlusMoinsPanier.module.scss';
import { Unite } from '../../../types';
import { loadStateFromLocalStorage } from '../../../utils/localStorage';

const qteMax = 9999;

export interface Props {
	idProduit: string;
	className?: string;
	lignesPanier: Panier['lignes'];
	removeProduitFromPanier: typeof removeProduitFromPanier;
	changeLignePanier: typeof changeLignePanier;
}

export function _PlusMoinsPanier({
	idProduit,
	className,
	lignesPanier,
	removeProduitFromPanier,
	changeLignePanier,
}: Props) {
	const { produit, quantite } = lignesPanier.find(
		(l) => l.produit.id === idProduit,
	)!;
	const [uc, setNewUc] = useState(
		lignesPanier.find((l) => l.produit.id === idProduit)!.produit.unite,
	);

	// const uc = "KG";
	const unites: { u: Unite; w: number }[] = [
		{ u: 'KG', w: 30 },
		{ u: 'COLIS', w: 38 },
		{ u: 'PIECE', w: 42 },
		{ u: 'LOT', w: 30 },
	];
	const isLot = produit.unite === 'LOT';
	const [inputQte, setInputQte] = useState<string>(
		isLot ? (produit as any).unite_multiple.toString() : quantite.toString(),
	);
	const [inputWidth, setInputWidth] = useState<number>();
	const [pr, setPr] = useState();
	const hidden = useRef<HTMLSpanElement>(null);
	const [setCurrentLotValue] = useState({
		id: (produit as any).unite_multiple.toString(),
		nom: (produit as any).unite_multiple.toString(),
	});
	const [clicked, setClicked] = useState(1);
	useEffect(() => {
		const localPanier = loadStateFromLocalStorage('panier');
		const produit = localPanier?.lignes.find((e) => e.idProduit === idProduit);
		if (produit?.uc) {
			setNewUc(produit?.uc as Unite);
		}
		if (produit?.commentaire) {
			setPr((produit as any).commentaire);
		}
		// setNewUc(produit?.uc ? produit?.uc.toString() : unite);

		if (isLot) {
			// @ts-ignore
			setCurrentLotValue({
				id: quantite === 1 ? '1' : quantite.toString(),
				nom: quantite === 1 ? '1' : quantite.toString(),
			});
		}

		if (!isLot) setInputQte(quantite.toString());
	}, [quantite, idProduit, isLot]);

	useEffect(() => {
		if (!hidden.current) return;
		const { width } = hidden.current.getBoundingClientRect();
		setInputWidth(width);
	}, [inputQte]);

	const input = useRef<HTMLInputElement>(null);

	const isKg = produit.unite === 'KG';

	async function setQuantite(qte: number, direction?: string) {
		if (isLot) {
			let quanti = null;
			if (direction === 'up') {
				quanti = (clicked + 1) * (produit as any).unite_multiple;
				await setClicked(clicked + 1);
			} else {
				quanti = (clicked - 1) * (produit as any).unite_multiple;
				await setClicked(clicked - 1);
			}
			setInputQte(quanti > 0 ? quanti.toString() : '0');

			if (quanti === 0) {
				setClicked(1);
				removeProduitFromPanier(idProduit);
			}
		} else {
			if (!isKg) qte = Math.ceil(qte);
			changeLignePanier(idProduit, qte, pr);
			setInputQte(qte.toString());
		}
	}

	// function changeSelectValue(value: any) {
	// 	setCurrentLotValue(value);
	// 	changeLignePanier(idProduit, value.id, pr);
	// }
	const unite = unites.find((u) => u.u === uc);
	return (
		<div className={[styles.wrapper, className].filter(Boolean).join(' ')}>
			{/* Moins */}

			<button
				className={styles.moins}
				onClick={() => {
					if (isLot) {
						setQuantite(Math.ceil(quantite - 1), 'down');
					} else {
						if (quantite - 1 > 0) setQuantite(Math.ceil(quantite - 1), 'down');
						else removeProduitFromPanier(idProduit);
					}
				}}
			/>
			<label className={styles.labelInput}>
				{/* Hidden for size */}
				<span ref={hidden} className={styles.hiddenInput}>
					{inputQte.replace('.', ',')}
				</span>

				{/* Input */}
				<input
					ref={input}
					type="text"
					inputMode="decimal"
					value={inputQte.replace('.', ',')}
					className={styles.inputQuantite}
					style={{ width: inputWidth }}
					onChange={(e) => setInputQte(e.target.value.replace(' ', ''))}
					onKeyDown={(e) => e.key === 'Enter' && input.current!.blur()}
					onBlur={() => {
						let qte = +inputQte.replace(',', '.');
						qte = Math.floor(qte * 100) / 100;
						qte = Math.min(qteMax, Math.max(0, qte));
						// Si la qte est > 0, on update le panier
						// Si la qte est 0, on enlève le produit
						// Si la qte n'est pas valide, on remet la quantite précédente
						if (qte > 0) setQuantite(qte);
						else if (qte === 0) removeProduitFromPanier(idProduit);
						else setInputQte(quantite.toString());
					}}
				/>

				{/* Dash */}
				<div className={styles.dash} />
			</label>

			<label className={styles.uc}>
				<select
					value={uc}
					className={styles.select}
					style={{ width: unite ? unite.w : 0 }}
					onChange={(e) => {
						const uc = e.target.value as Unite;
						setNewUc(e.target.value as Unite);
						// const qte =
						// 	uc === 'K'
						// 		? quantite
						// 		: uc === 'U'
						// 		? Math.ceil(getMultiple(quantite))
						// 		: Math.ceil(quantite);
						changeLignePanier(idProduit, quantite, pr, uc);
					}}
				>
					{unites.map(({ u }) => (
						<option key={u} value={u}>
							{convertUnite(u, quantite)}
						</option>
					))}
				</select>
			</label>

			{/* Plus */}
			<button
				className={styles.plus}
				disabled={quantite >= qteMax}
				onClick={() => setQuantite(Math.floor(quantite + 1), 'up')}
			/>
			{/* Input quantité */}
		</div>
	);
}

function mapStateToProps(state: AppState) {
	return { lignesPanier: state.panier.lignes };
}

export default connect(mapStateToProps, {
	removeProduitFromPanier,
	changeLignePanier,
})(_PlusMoinsPanier);
