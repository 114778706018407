import styles from '../../Admin/FormulaireActualite/FormulaireActualite.module.scss';
import React from 'react';

export interface Props {
	embedVal: string;
}

export function Iframe({ embedVal }: Props) {
	return (
		<div className={styles.videoresponsive}>

			<iframe
				className={styles.videoResponsiveIframe}
				src={embedVal.includes('youtu') ? `https://www.youtube.com/embed/${embedVal.replace('https://youtu.be/', '')}` : `https://www.youtube.com/embed/${embedVal}`}
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
				title="Embedded youtube"
			/>
		</div>)
		;
}

export default Iframe;
