import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppState } from '../../../../../store';
import { SelectionsState } from '../../../../../store/selections/types';
import MenuLink from '../MenuLink/MenuLink';
import SkeletonLine from '../SkeletonLine/SkeletonLine';
import TitleAndError from '../TitleAndError/TitleAndError';

type Props = SelectionsState & RouteComponentProps;

export function _Selections({
	list,
	error,
	loading,
	location: { search },
}: Props) {
	const searchParams = new URLSearchParams(search);
	const activeSelectionSlug = searchParams.get('selection');

	return (
		<>
			<TitleAndError error={error}>Nos Sélections</TitleAndError>
			{loading
				? [...new Array(6)].map((_, i) => <SkeletonLine key={i} />)
				: list.map(({ nom, slug, pictoSrc }) => (
						<MenuLink
							key={slug}
							pictoSrc={pictoSrc}
							to={`/recherche?selection=${slug}`}
							isActive={slug === activeSelectionSlug}
						>
							{nom}
						</MenuLink>
				  ))}
		</>
	);
}

function mapStateToProps(state: AppState) {
	return state.selections;
}

export default withRouter(connect(mapStateToProps)(_Selections));
