import formatDate from 'dateformat';
import React from 'react';
import { Fournisseur } from '../../../../types';
import { thinSpace } from '../../../../utils/french';
import Section from '../Section/Section';
import Skeleton from '../Skeleton/Skeleton';
import styles from './Livraison.module.scss';

interface Props {
	data: null | Pick<
		Fournisseur,
		'heureLimite' | 'joursLivraison' | 'datesFermetureExceptionnelle'
	>;
}

function convertHour(time: number) {
	const tmp = '000' + time;
	const timeArray = tmp.slice(tmp.length - 4, tmp.length);
	const h = timeArray.slice(0, 2);
	const min = timeArray.slice(2);
	return h + ':' + min;
}

function Livraison({ data }: Props) {
	return (
		<Section title="Livraison">
			<p className={styles.paragraph}>
				{data ? (
					<>
						À J+1 tous les{thinSpace}:{' '}
						<strong>
							{data.joursLivraison.map((j) => j.toLowerCase()).join(', ') + '.'}
						</strong>
					</>
				) : (
					<Skeleton size="big" />
				)}
			</p>

			<p className={styles.paragraph}>
				{data ? (
					<>
						Livraison à J+2 à partir de{' '}
						<strong>{convertHour(data.heureLimite)}.</strong>
					</>
				) : (
					<Skeleton size="full" />
				)}
			</p>

			<div>
				{data ? (
					!!data.datesFermetureExceptionnelle.length && (
						<>
							Fermetures exceptionnelles{thinSpace}:{' '}
							<ul className={styles.listDates}>
								{data.datesFermetureExceptionnelle.map((date, i) => {
									return <li key={i}>{formatDate(date, 'dd/mm/yyyy')}</li>;
								})}
							</ul>
						</>
					)
				) : (
					<Skeleton size="big" />
				)}
			</div>
		</Section>
	);
}

export default Livraison;
