import { loader } from 'graphql.macro';
import React from 'react';
import CompteUser from '../../Common/CompteUser/CompteUser';
import { sections } from './sections';
import { TCompteFournisseur } from './types';

const query = loader('./getFournisseur.graphql');
const mutation = loader('./updateFournisseur.graphql');

const initialState: TCompteFournisseur = {
	// Général
	photo: '',
	login: '',
	email: '',
	enseigne: '',
	description: '',
	secteurActivite: { id: '', nom: '' },
	conditionsPaiement: { id: '', nom: '' },

	// Légal
	raisonSociale: '',
	numeroTVAIntracommunautaire: '',
	rib: '',
	kbis: '',

	// Horaires
	horairesOuverture: '',
	heureLimite: 0,
	joursLivraison: [],
	datesFermetureExceptionnelle: [],

	// Adresses
	adresse: { codePostal: '', ville: '', voie: '' },
	adresseFacturation: { codePostal: '', ville: '', voie: '' },

	// Contacts
	contacts: [],
};

function CompteFournisseur() {
	return (
		<CompteUser
			{...{ query, mutation, initialState, sections: sections as any }}
		/>
	);
}

export default CompteFournisseur;
