import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from '../../Button/Button';
import Input from '../../Form/Input/Input';
import CenteredPopin from '../../Popin/CenteredPopin/CenteredPopin';
import BtnCommentaire from '../BtnCommentaire/BtnCommentaire';
import styles from './EditCommentaire.module.scss';
import LignePanier from '../../../Client/Panier/LignePanier/LignePanier';
import { AppState } from '../../../../store';
import { thinSpace } from '../../../../utils/french';
import { changeLignePanier } from '../../../../store/panier/actions';
import { PanierLigne } from '../../../../store/panier/types';
import { loadStateFromLocalStorage } from '../../../../utils/localStorage';

interface Props {
	codeProduit: string;
	lignesPanier: PanierLigne[];
	changeLignePanier: typeof changeLignePanier;
}

export function _EditCommentaire({
	changeLignePanier,
	codeProduit,
	lignesPanier,
}: Props) {
	const [isOpen, setIsOpen] = useState(false);
	const [commentaire, setCommentaire] = useState('');
	const { quantite } = lignesPanier.find((l) => l.produit.id === codeProduit)!;

	const commentaireFn = (val: any) => {
		setCommentaire(val);
		changeLignePanier(codeProduit, quantite, val);
	};

	useEffect(() => {
		const localPanier = loadStateFromLocalStorage('panier');
		const lignes = localPanier?.lignes.find((res) => {
			return res.idProduit === codeProduit;
		});
		// console.log('lignes', lignes);
		if (lignes?.commentaire) {
			setCommentaire(lignes?.commentaire.toString());
		}
	});
	return (
		<>
			<BtnCommentaire
				hasValue={!!commentaire}
				onClick={() => setIsOpen(true)}
				title={
					commentaire ? `Modifier le commentaire` : `Ajouter un commentaire`
				}
			/>

			<CenteredPopin isOpen={isOpen} onCacheClick={() => setIsOpen(false)}>
				<h1 className={styles.title}>Commentaire{thinSpace}:</h1>

				<Input
					label=""
					autoFocus
					type="textarea"
					value={commentaire?.toString()}
					className={styles.textarea}
					setValue={(val) => commentaireFn(val)}
				/>

				<div className={styles.validerWrapper}>
					<Button className={styles.valider} onClick={() => setIsOpen(false)}>
						Valider
					</Button>
				</div>
			</CenteredPopin>
		</>
	);
}

function mapStateToProps(state: AppState) {
	return { lignesPanier: state.panier.lignes };
}

export default connect(mapStateToProps, { changeLignePanier })(
	_EditCommentaire,
);
