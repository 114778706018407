import React from 'react';
import styles from './TitleAndError.module.scss';

interface Props {
	children: string;
	error?: string;
}

function TitleAndError({ children, error }: Props) {
	return (
		<>
			<h1 className={styles.title}>{children}</h1>

			{error && <p className={styles.error}>{error}</p>}
		</>
	);
}

export default TitleAndError;
