import React from 'react';
import Input from '../../../Form/Input/Input';
import { CompteField, CompteSectionProps } from '../../types';
import sectionStyles from '../Formulaire.module.scss';
import DatesFermetureExceptionnelle from './DatesFermetureExceptionnelle/DatesFermetureExceptionnelle';
import HeureLimite from './HeureLimite/HeureLimite';
import JoursLivraison from './JoursLivraison/JoursLivraison';

type Props = CompteSectionProps & { fields: CompteField[] };

function Horaires({ user, loading, setUser, fields }: Props) {
	return (
		<section className={sectionStyles.section}>
			<h1 className={sectionStyles.title}>Horaires</h1>

			<div className={sectionStyles.column}>
				{fields.map(({ label, name, type, readOnly, required }, i) => (
					<Input
						key={i}
						type={type}
						loading={loading}
						readOnly={readOnly}
						required={required}
						value={user[name] as string}
						label={label + (required ? '*' : '')}
						setValue={(val) => setUser({ ...user, [name]: val })}
					/>
				))}

				{'joursLivraison' in user && (
					<JoursLivraison setUser={setUser} user={user} />
				)}

				{'heureLimite' in user && (
					<HeureLimite setUser={setUser} user={user} loading={loading} />
				)}
			</div>

			{'datesFermetureExceptionnelle' in user && (
				<DatesFermetureExceptionnelle
					user={user}
					loading={loading}
					setUser={setUser}
					className={sectionStyles.column}
				/>
			)}
		</section>
	);
}

export default Horaires;
