import {
	ConditionsPaiementActionTypes,
	ConditionsPaiementState,
	GET_CONDITIONS_PAIEMENT_FAIL,
	GET_CONDITIONS_PAIEMENT_START,
	GET_CONDITIONS_PAIEMENT_SUCCESS,
} from './types';

const initialState: ConditionsPaiementState = {
	loading: true,
	error: null,
	list: [],
};

export function conditionsPaiementReducer(
	state = initialState,
	action: ConditionsPaiementActionTypes,
): ConditionsPaiementState {
	switch (action.type) {
		case GET_CONDITIONS_PAIEMENT_START:
			return {
				...state,
				loading: true,
				error: null,
			};

		case GET_CONDITIONS_PAIEMENT_SUCCESS:
			return {
				...state,
				loading: false,
				list: action.list,
			};

		case GET_CONDITIONS_PAIEMENT_FAIL:
			return {
				...state,
				loading: false,
				error: action.error,
			};

		default:
			return state;
	}
}
