import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Pick2 } from 'ts-multipick';
import { useTitle } from '../../../hooks/useTitle';
import { AppState } from '../../../store';
import { User } from '../../../store/compte/types';
import { PanierState } from '../../../store/panier/types';
import { Fournisseur, Produit } from '../../../types';
import Pagination from '../../Common/Pagination/Pagination';
import FicheFournisseur from '../FicheFournisseur/FicheFournisseur';
import styles from './ResultatsRecherche.module.scss';
import VignetteFournisseur from './VignetteFournisseur/VignetteFournisseur';
import VignetteProduit from './VignetteProduit/VignetteProduit';
import { searchInFournisseurs } from './searchInFournisseurs';
import { searchInProduits } from './searchInProduits';

const itemsPerPage = 15;

export interface QueryResponse {
	countProduits: number;
	produits: Produit[];
	countFournisseurs: number;
	fournisseurs: (Pick<Fournisseur, 'id' | 'photo' | 'enseigne'> &
		Pick2<Fournisseur, 'secteurActivite', 'nom'>)[];
}

interface Props extends RouteComponentProps {
	idClient: User['id'];
	dateLivraison?: PanierState['dateLivraison'];
}

export function _ResultatsRecherche({
	idClient,
	dateLivraison,
	location: { search },
}: Props) {
	useTitle('Recherche');

	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [countTotal, setCountTotal] = useState(0);
	const [produits, setProduits] = useState<QueryResponse['produits']>([]);
	const [fournisseurs, setFournisseurs] = useState<
		QueryResponse['fournisseurs']
	>([]);

	// GET params
	const searchParams = new URLSearchParams(search);
	const pageIndex = +(searchParams.get('page') || 1);
	const activeSelection = searchParams.get('selection');
	const activeSousCategorie = searchParams.get('sous-categorie');
	const activeLabel = searchParams.get('label');
	const activeOrigine = searchParams.get('origine');
	const activeMarque = searchParams.get('marks');
	const activeFournisseur = searchParams.get('fournisseur');
	let activeQuery = searchParams.get('query') || '';

	// À chaque changement de route
	useEffect(() => {
		let isMounted = true;

		setLoading(true);

		// Pour toujours avoir le même nombre d'items par page,
		// on est obligés de faire deux requêtes séparément :
		// 1. les fournisseurs s'il y a une activeQuery sans sélection, sousCategorie, label ou origine
		// 2. les produits, qu'on va offset en fonction du nombre de fournisseurs
		if (
			!!activeQuery &&
			!activeLabel &&
			!activeOrigine &&
			!activeMarque &&
			!activeSelection &&
			!activeSousCategorie
		) {
			searchInFournisseurs({ activeQuery, idClient, itemsPerPage, pageIndex })
				.then((data: any) => {
					if (!data.fournisseurs.length) {
						if (data.fournisseurSearchInId) {
							// const enseigneFind = data.fournisseurSearchInId.map(
							// 	(e: any) => e.enseigne,
							// );
							// const a = data.me.fournisseursAutorises.filter((e: any) =>
							// 	enseigneFind.includes(e.enseigne),
							// );
							// setFournisseurs(!a.length ? [] : data.fournisseurSearchInId);
						}
					} else {
						setFournisseurs(data.fournisseurs);
					}
					if (!isMounted) return;
					handleSearchInProduits({
						nbrF: data.fournisseurs.length,
						nbrTotalF: data.countFournisseurs,
					});
				})
				.catch((err) => setError(err.message));
		} else {
			setFournisseurs([]);
			handleSearchInProduits({ nbrF: 0, nbrTotalF: 0 });
		}

		type T = Pick<Parameters<typeof searchInProduits>[0], 'nbrF' | 'nbrTotalF'>;

		function handleSearchInProduits({ nbrF, nbrTotalF }: T) {
			searchInProduits({
				nbrF,
				idClient,
				nbrTotalF,
				pageIndex,
				activeLabel,
				activeQuery,
				itemsPerPage,
				dateLivraison,
				activeOrigine,
				activeMarque,
				activeSelection,
				activeSousCategorie,
			})
				.then(async (data: any) => {
					if (!isMounted) return;
					setError('');
					// console.log(data);
					/* if (data.productSearchId) {
						data.productSearchId.forEach((e: any) => {
							if (!data.produits.find((prod: any) => prod.id === e.id)) {
								data.produits.push(e);
							}
						});
					} */
					/* const produitFinals = data.produits.filter(
						(e: any) => e.tarifs.length,
					); */
					// if (data.productSearchId.length < 15) {
					// 	setCountTotal((data.countProduits + nbrTotalF) - (15 - data.productSearchId.length));
					// } else {
					// }

					if (activeQuery) {
						if (data.countProductByFournisseurOrByCode > 0) {
							setCountTotal(data.countProductByFournisseurOrByCode + nbrTotalF);
							const produitFinals = data.productSearchId.filter(
								(e: any) => e.tarifs.length,
							);
							setProduits(produitFinals);
						} else {
							setCountTotal(data.countProduits + nbrTotalF);
							setProduits(data.produits);
						}
					} else {
						setCountTotal(data.countProduits + nbrTotalF);
						setProduits(data.produits);
					}
				})
				.catch((err) => setError(err.message))
				.finally(() => setLoading(false));
		}

		return () => {
			isMounted = false;
		};
	}, [
		idClient,
		dateLivraison,
		pageIndex,
		activeQuery,
		activeLabel,
		activeOrigine,
		activeMarque,
		activeSelection,
		activeSousCategorie,
	]);

	return error ? (
		<p className={styles.warning}>{error}</p>
	) : (
		<>
			{activeFournisseur ? (
				<FicheFournisseur id={activeFournisseur} />
			) : (
				<>
					<Pagination
						showCount
						loading={loading}
						totalItemsCount={countTotal}
						className={styles.pagination}
						itemsCountPerPage={itemsPerPage}
					/>

					{/* Liste des fournisseurs + produits */}
					<ul className={styles.list}>
						{loading ? (
							[...new Array(itemsPerPage)].map((_, i) => (
								<li key={i} className={styles.item}>
									<div className={styles.skeleton} />
								</li>
							))
						) : (
							<>
								{fournisseurs.map((fournisseur) => (
									<li key={fournisseur.id} className={styles.item}>
										<VignetteFournisseur {...fournisseur} />
									</li>
								))}
								{produits.map((produit) => (
									<li key={produit.id} className={styles.item}>
										<VignetteProduit produit={produit} />
									</li>
								))}
							</>
						)}

						{/* Fakes (for flex display) */}
						{[...new Array(2)].map((_, i) => (
							<li key={i} className={[styles.item, styles.fake].join(' ')} />
						))}
					</ul>

					<Pagination
						loading={loading}
						className={styles.pagination}
						totalItemsCount={countTotal}
						itemsCountPerPage={itemsPerPage}
					/>
				</>
			)}
		</>
	);
}

function mapStateToProps(state: AppState) {
	return {
		idClient: state.compte.id,
		dateLivraison: state.panier.dateLivraison,
	};
}

export default withRouter(connect(mapStateToProps)(_ResultatsRecherche));
