import { ThunkAction } from 'redux-thunk';

export type UserType = 'Client' | 'Fournisseur' | 'Admin';

export type User = {
	id: string;
	type: UserType;
	nom: string;
	photo?: string;
};

export type CompteState = User & {
	error?: string;
	isLoggingIn: boolean;
	isLoggedIn: boolean;
	isGettingLoggedInUser: boolean;
	isLoggingOut: boolean;
};

export interface LoginInput {
	type: UserType;
	login: string;
	password: string;
}

export interface UserResponse {
	user: User;
}

export const GET_LOGGED_IN_USER_SUCCESS = 'GET_LOGGED_IN_USER_SUCCESS';
interface GetLoggedInUserSuccessAction {
	type: typeof GET_LOGGED_IN_USER_SUCCESS;
	response: UserResponse | { user: null };
}

export const GET_LOGGED_IN_USER_FAIL = 'GET_LOGGED_IN_USER_FAIL';
interface GetLoggedInUserFailAction {
	type: typeof GET_LOGGED_IN_USER_FAIL;
	error: string;
}

export const LOGIN_START = 'LOGIN_START';
interface LoginStartAction {
	type: typeof LOGIN_START;
}

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
interface LoginSuccessAction {
	type: typeof LOGIN_SUCCESS;
	response: UserResponse;
}

export const LOGIN_FAIL = 'LOGIN_FAIL';
interface LoginFailAction {
	type: typeof LOGIN_FAIL;
	error: string;
}

export const LOGOUT_START = 'LOGOUT_START';
interface LogoutStartAction {
	type: typeof LOGOUT_START;
}

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
interface LogoutSuccessAction {
	type: typeof LOGOUT_SUCCESS;
}

export const LOGOUT_FAIL = 'LOGOUT_FAIL';
interface LogoutFailAction {
	type: typeof LOGOUT_FAIL;
	error: string;
}

export const HIDE_LOGIN_ERROR = 'HIDE_LOGIN_ERROR';
interface HideLoginErrorAction {
	type: typeof HIDE_LOGIN_ERROR;
}

export type CompteActionTypes =
	| GetLoggedInUserSuccessAction
	| GetLoggedInUserFailAction
	| LoginStartAction
	| LoginSuccessAction
	| LoginFailAction
	| HideLoginErrorAction
	| LogoutStartAction
	| LogoutSuccessAction
	| LogoutFailAction;

export type CompteThunkResult = ThunkAction<
	any,
	CompteState,
	{},
	CompteActionTypes
>;
