import React from 'react';
import BtnBack from '../../Common/BtnBack/BtnBack';
import Button from '../../Common/Button/Button';
import ErrorMessage from '../../Common/Form/ErrorMessage/ErrorMessage';
import Form from '../../Common/Form/Form';
import Input from '../../Common/Form/Input/Input';
import TableHeader from '../../Common/Table/TableHeader/TableHeader';
import TableWrapper from '../../Common/Table/TableWrapper/TableWrapper';
import styles from './FormulaireAdmin.module.scss';

export interface Props {
	type: 'Édition' | 'Création';

	error?: string;
	loading?: boolean;

	login: string;
	email: string;
	nom: string;
	prenom: string;
	password: string;

	changeLogin: (val: string) => void;
	changeEmail: (val: string) => void;
	changeNom: (val: string) => void;
	changePrenom: (val: string) => void;
	changePassword: (val: string) => void;

	submit: () => void;
}

function FormulaireAdmin({
	type,
	error,
	loading,
	login,
	email,
	nom,
	prenom,
	password,

	changeLogin,
	changeEmail,
	changeNom,
	changePrenom,
	changePassword,
	submit,
}: Props) {
	return (
		<TableWrapper>
			<TableHeader>
				<BtnBack to="/admin/list" className={styles.btnBack} withText />
				<p className={styles.title}>{type} d'un Admin</p>
			</TableHeader>

			<Form onSubmit={submit} className={styles.newform}>
				<div>
					<Input
						label="Login"
						type="text"
						value={login}
						setValue={changeLogin}
						className=""
					/>
				</div>
				<div>
					<Input
						label="Email"
						type="text"
						value={email}
						setValue={changeEmail}
						className=""
					/>
				</div>
				<div>
					<Input
						label="Nom"
						type="text"
						value={nom}
						setValue={changeNom}
						className=""
					/>
				</div>
				<div>
					<Input
						label="Prénom"
						type="text"
						value={prenom}
						setValue={changePrenom}
						className=""
					/>
				</div>
				<div>
					<Input
						label="Password"
						type="password"
						minLength={8}
						value={password}
						setValue={changePassword}
						className=""
					/>
				</div>

				<ErrorMessage>{error}</ErrorMessage>
				<div>
					<Button submit className={styles.btnSubmit}>
						{type === 'Édition' ? 'Éditer un admin' : 'Publier un admin'}
					</Button>
				</div>
			</Form>
		</TableWrapper>
	);
}

export default FormulaireAdmin;
