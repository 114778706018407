import { loader } from 'graphql.macro';
import request from '../../../utils/request';
import { QueryResponse } from './ResultatsRecherche';

const queryFournisseurs = loader('./queryFournisseurs.graphql');

interface SearchInFournisseurs {
	idClient: string;
	pageIndex: number;
	activeQuery: string;
	itemsPerPage: number;
}

export async function searchInFournisseurs({
	activeQuery,
	idClient,
	pageIndex,
	itemsPerPage,
}: SearchInFournisseurs) {
	const params = {
		skip: itemsPerPage * (pageIndex - 1),
		first: itemsPerPage,
		search: activeQuery,
		where: {
			disabled: false,
			//clientsAutorises_some: { id: idClient },
			AND: activeQuery.split('-').map(s => {
				s = s.replace(/s$/, '');
				return {
					OR: [
						{ search_contains: s },
						{ adresse: { search_contains: s } },
						{ contacts_some: { search_contains: s } },
						{ secteurActivite: { slug_contains: s } },
						{ adresseFacturation: { search_contains: s } },
					],
				};
			}),
		},
	};
	type T = Pick<QueryResponse, 'countFournisseurs' | 'fournisseurs'>;
	console.log(queryFournisseurs, params);
	return request<T>(queryFournisseurs, params);
}
