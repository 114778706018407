import { loader } from 'graphql.macro';
import React, { ComponentProps, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { Pick2 } from 'ts-multipick';
import { useTitle } from '../../../hooks/useTitle';
import { AppState } from '../../../store';
import { User } from '../../../store/compte/types';
import {
	Commande,
	CommandeLigne,
	NewCommande,
	RecurrenceMois,
	RecurrenceSemaine,
} from '../../../types';
import request from '../../../utils/request';
import FilterByDateRange from '../../Common/FilterByDateRange/FilterByDateRange';
import OrderBy from '../../Common/OrderBy/OrderBy';
import TableHeader from '../../Common/Table/TableHeader/TableHeader';
import TablePagination from '../../Common/Table/TablePagination/TablePagination';
import TableWrapper from '../../Common/Table/TableWrapper/TableWrapper';
import ListeCommandes from './ListeCommandes/ListeCommandes';

const query = loader('./getCommandes.graphql');
const queryFav = loader('./getCommandesFav.graphql');

const itemsPerPage = 10;

const orderByFields: ComponentProps<typeof OrderBy>['fields'] = [
	{ name: 'Date de livraison', slug: 'dateLivraison' },
	{ name: 'Numéro', slug: 'numero' },
	{ name: 'Nom', slug: 'nom' },
	{ name: 'État', slug: 'statut' },
];

export interface QueryResponse {
	countCommandes: number;
	commandes: (Pick<
		Commande,
		| 'id'
		| 'dateLivraison'
		| 'favorite'
		| 'nom'
		| 'numero'
		| 'statut'
		| 'createdAt'
	> & {
		recurrenceMois?: Pick<RecurrenceMois, 'date'>;
		recurrenceSemaine?: Pick<RecurrenceSemaine, 'jour'>[];
		lignes: Pick2<CommandeLigne, 'produit', 'nom'>[];
	})[];
}

export interface Props extends RouteComponentProps {
	idClient?: User['id'];
	favorites?: boolean;
}

const mutation = loader('./createCommande.graphql');
const mutationDelete = loader('./deleteCommande.graphql');

export function _Commandes({ location, idClient, favorites }: Props) {
	useTitle('Mes commandes');

	const [commandes, setCommandes] = useState<QueryResponse['commandes']>([]);
	const [totalCount, setTotalCount] = useState(0);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');

	const searchParams = new URLSearchParams(location.search);
	const pageIndex = +(searchParams.get('page') || 1);
	const orderBy = searchParams.get('orderBy') || '';
	const order = searchParams.get('order') || '';
	const dateStart = searchParams.get('dateStart');
	const dateEnd = searchParams.get('dateEnd');
	async function updateFavori(id: string, isFavorite: boolean) {
		const newCommandes = commandes.map((c) => {
			if (c.id === id) c.favorite = !isFavorite;
			return c;
		});

		const comm = newCommandes.find((e) => e.id === id);

		await placeOrder(comm);

		setCommandes(newCommandes);
	}

	async function setNoCommandeFavorite(id: string, isFavorite: boolean) {
		const newCommandes = commandes.map((c) => {
			if (c.id === id) c.favorite = !isFavorite;
			return c;
		});

		await placeOrderNo(newCommandes[0]);

		setCommandes(newCommandes);
	}

	async function placeOrderNo(cmd: any) {
		await request(mutationDelete, { cmdFav: cmd.id });
	}

	async function placeOrder(cmd: any) {
		// let localPanier = loadStateFromLocalStorage('panier');
		try {
			setError('');
			setLoading(true);
			const newCommande = {
				nom: cmd.nom,
				dateLivraison: '',
				comments: [],
				boat: true,
				cmdFav: cmd.id,
				lignes: cmd.lignes.map((l: any, index: number) => {
					const tarif = l.produit.tarifs[0].prix;
					return {
						quantite: l.quantite,
						idProduit: l.produit.id,
						prixUnitaireAConfirmer: tarif,
						idFournisseur: l.produit.tarifs[0].fournisseur.id,
						commentaire: l.commentaire ? l.commentaire : '',
					};
				}) as NewCommande['lignes'],
			};
			await request(mutation, newCommande);
		} catch (err) {
			setError(err.message);
		} finally {
			setLoading(false);
		}
	}

	function updCmd() {
		if (favorites) {
			setLoading(true);
			const params = {
				where: {
					client: { id: idClient },
				},
				skip: itemsPerPage * (pageIndex - 1),
				first: itemsPerPage,
				orderBy: `${orderBy}_${order}`,
			};

			request<QueryResponse>(queryFav, params)
				.then((data: any) => {
					setCommandes(data.cmdFavClient);
					setTotalCount(data.cmdFavClient.length);
					setError('');
				})
				.catch((err) => setError(err.message))
				.finally(() => setLoading(false));
		} else {
			setLoading(true);
			const params = {
				where: {
					client: { id: idClient },
					...(dateStart && { dateLivraison_gte: dateStart }),
					...(dateEnd && { dateLivraison_lte: dateEnd }),
				},
				skip: itemsPerPage * (pageIndex - 1),
				first: itemsPerPage,
				orderBy: `${orderBy}_${order}`,
			};

			request<QueryResponse>(query, params)
				.then(({ commandes, countCommandes }) => {
					setCommandes(commandes);
					setTotalCount(countCommandes);
					setError('');
				})
				.catch((err) => setError(err.message))
				.finally(() => setLoading(false));
		}
	}

	useEffect(() => {
		if (!orderBy || !order) return;

		let isMounted = true;
		if (favorites) {
			setLoading(true);
			const params = {
				where: {
					client: { id: idClient },
				},
				skip: itemsPerPage * (pageIndex - 1),
				first: itemsPerPage,
				orderBy: `${orderBy}_${order}`,
			};

			request<QueryResponse>(queryFav, params)
				.then((data: any) => {
					if (!isMounted) return;
					setCommandes(data.cmdFavClient);
					setTotalCount(data.cmdFavClient.length);
					setError('');
				})
				.catch((err) => setError(err.message))
				.finally(() => setLoading(false));
		} else {
			setLoading(true);
			const params = {
				where: {
					client: { id: idClient },
					...(dateStart && { dateLivraison_gte: dateStart }),
					...(dateEnd && { dateLivraison_lte: dateEnd }),
				},
				skip: itemsPerPage * (pageIndex - 1),
				first: itemsPerPage,
				orderBy: `${orderBy}_${order}`,
			};

			request<QueryResponse>(query, params)
				.then(({ commandes, countCommandes }) => {
					if (!isMounted) return;
					setCommandes(commandes);
					setTotalCount(countCommandes);
					setError('');
				})
				.catch((err) => setError(err.message))
				.finally(() => setLoading(false));
		}

		return () => {
			isMounted = false;
		};
	}, [idClient, order, orderBy, pageIndex, favorites, dateEnd, dateStart]);

	if (!orderBy || !order) {
		return <Redirect to={`${location.pathname}?orderBy=numero&order=DESC`} />;
	}

	return (
		<TableWrapper>
			<TableHeader>
				<OrderBy fields={orderByFields} />
				<FilterByDateRange label="Date de livraison" />
			</TableHeader>

			<ListeCommandes
				error={error}
				loading={loading}
				setNoCommandeFavorite={setNoCommandeFavorite}
				commandes={commandes}
				favorites={favorites}
				setCommandeFavorite={updateFavori}
				updateCmd={updCmd}
				noResultMessage="Vous n'avez aucune commande"
			/>

			<TablePagination
				error={!!error}
				loading={loading}
				totalItemsCount={totalCount}
				itemsCountPerPage={itemsPerPage}
			/>
		</TableWrapper>
	);
}

function mapStateToProps(state: AppState) {
	return { idClient: state.compte.id };
}

export default withRouter(connect(mapStateToProps)(_Commandes));
