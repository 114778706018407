import { CompteSections } from '../../Common/CompteUser/types';
import { TCompteClient } from './types';

export const sections: CompteSections<TCompteClient> = {
	informationsGenerales: [
		{ label: 'Identifiant', type: 'text', name: 'login', readOnly: true },
		{ label: 'Email du compte', type: 'email', name: 'email', readOnly: true },
		{ label: 'Enseigne', type: 'text', name: 'enseigne', required: true },
		{ label: 'Description', type: 'textarea', name: 'description' },
		{
			label: 'Prélèvement automatique',
			type: 'boolean',
			name: 'prelevementAuto',
		},
		{
			label: "Secteur d'activité",
			name: 'secteurActivite',
			type: 'select',
			options: 'secteursActivite',
		},
	],

	informationsLegales: [
		{
			name: 'raisonSociale',
			type: 'text',
			label: 'Raison sociale',
			required: true,
		},
		{ name: 'siret', type: 'text', label: 'SIRET', required: true },
		{ name: 'rib', type: 'file', label: 'RIB' },
		{ name: 'kbis', type: 'file', label: 'KBIS' },
	],

	horaires: [
		{
			label: `Horaires d'ouverture`,
			type: 'text',
			name: 'horairesOuverture',
			required: true,
		},
	],

	adresses: [
		{ title: 'Adresse de livraison', name: 'adresseLivraison' },
		{ title: 'Adresse de facturation', name: 'adresseFacturation' },
	],
};
