import { loader } from 'graphql.macro';
import request from '../../utils/request';
import {
	CLOSE_FILTRES,
	FiltresActionTypes,
	FiltresThunkResult,
	GetFiltresResponse,
	GET_FILTRES_FAIL,
	GET_FILTRES_START,
	GET_FILTRES_SUCCESS,
	OPEN_FILTRE
} from './types';

const query = loader('./getFiltres.graphql');

export function getFiltres(): FiltresThunkResult {
	return async function(dispatch) {
		try {
			dispatch({ type: GET_FILTRES_START });
			const { labels, origines, marks } = await request<GetFiltresResponse>(query);
			dispatch({
				type: GET_FILTRES_SUCCESS,
				filtres: [
					{ nom: 'Labels', slug: 'label', isOpen: false, options: labels },
					{ nom: 'Marques', slug: 'marks', isOpen: false, options: marks },
					{
						nom: 'Origines',
						slug: 'origine',
						isOpen: false,
						options: origines,
					},
				],
			});
		} catch (err) {
			dispatch({ type: GET_FILTRES_FAIL, error: err.message });
		}
	};
}

export function openFiltre(filtreSlug: string): FiltresActionTypes {
	return { type: OPEN_FILTRE, filtreSlug };
}

export function closeFiltres(): FiltresActionTypes {
	return { type: CLOSE_FILTRES };
}
