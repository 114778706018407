import React from 'react';
import { Pick2 } from 'ts-multipick';
import { Fournisseur } from '../../../../types';
import Section from '../Section/Section';
import Skeleton from '../Skeleton/Skeleton';

interface Props {
	data: null | Pick2<Fournisseur, 'conditionsPaiement', 'nom'>;
}

function ConditionsPaiement({ data }: Props) {
	return (
			<Section title="Conditions de paiement">
				{data ? data.conditionsPaiement.nom : <Skeleton size="small" />}
			</Section>
	);
}

export default ConditionsPaiement;
