import { loader } from 'graphql.macro';
import request from '../../utils/request';
import {
	ActualitesState,
	ActualitesThunkResult,
	GET_ACTUALITES_FAIL,
	GET_ACTUALITES_START,
	GET_ACTUALITES_SUCCESS,
} from './types';

const query = loader('./getActualites.graphql');
const queryClient = loader('./getClient.graphql');

type Client = {
	fournisseur: {
		id: string;
	};
};

export function getActualites(id: string): ActualitesThunkResult {
	return async function (dispatch) {
		dispatch({ type: GET_ACTUALITES_START });
		try {
			const client = await getClient(id);
			const variables = { fournisseurId: client.fournisseur.id };
			const { list } = await request<{ list: ActualitesState['list'] }>(
				query,
				variables,
			);
			dispatch({ type: GET_ACTUALITES_SUCCESS, list });
		} catch (err) {
			dispatch({ type: GET_ACTUALITES_FAIL, error: err.message });
		}
	};
}

const getClient = async (id: string) => {
	const variables = { id };
	const response = await request<{ clients: Client[] }>(queryClient, variables);
	return response.clients[0];
};
