import React from 'react';
import { thinSpace } from '../../../../utils/french';
import Button from '../../Button/Button';
import inputStyles from '../Input/Input.module.scss';
import styles from './InputFile.module.scss';

interface Props {
	file?: File;
	url?: string;
	label: string;
	loading?: boolean;
	className?: string;
	onChange: (val: { file: File; blob: string }) => void;
}

function InputFile({ label, className, loading, file, url, onChange }: Props) {
	return (
		<label
			className={[
				inputStyles.label,
				styles.label,
				loading && styles.loading,
				className,
			]
				.filter(Boolean)
				.join(' ')}
		>
			<span className={inputStyles.text + ' ' + styles.text}>{label}</span>

			<input
				type="file"
				readOnly={loading}
				className={styles.input}
				onChange={({ target: { files } }) => {
					if (!files || !files.length) return;
					const file = files[0];
					if (file.size > 10000000) return; // max 10Mo
					onChange({ file, blob: URL.createObjectURL(file) });
				}}
			/>

			<Button outline className={styles.upload} loading={loading}>
				Envoyer un nouveau fichier{file && `${thinSpace}: ${file.name}`}
			</Button>

			{url && (
				<a
					href={url}
					target="_blank"
					className={styles.open}
					rel="noopener noreferrer"
				>
					<Button>Ouvrir le fichier existant</Button>
				</a>
			)}
		</label>
	);
}

export default InputFile;
