import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Media from 'react-responsive';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppState } from '../../store';
import { getCategories } from '../../store/categories/actions';
import { User } from '../../store/compte/types';
import { getInfoProduitsPanier } from '../../store/panier/actions';
import { getSecteursActivite } from '../../store/secteursActivite/actions';
import { getSelections } from '../../store/selections/actions';
import { media } from '../../utils/breakpoints/breakpoints';
import BtnNotifications from '../Common/BtnNotifications/BtnNotifications';
import Gradient from '../Common/Gradient/Gradient';
import styles from './Client.module.scss';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import Panier from './Panier/Panier';
import SelectionsCategoriesMobile from './Recherche/SelectionsCategoriesMobile/SelectionsCategoriesMobile';
import SideMenuDesktop from './Recherche/SideMenuDesktop/SideMenuDesktop';
import SousCategoriesMobile from './Recherche/SousCategoriesMobile/SousCategoriesMobile';
import Routes from './Routes/Routes';

interface Props extends RouteComponentProps {
	idClient: User['id'];
	isPanierOpen: boolean;
	getSelections: typeof getSelections;
	getCategories: typeof getCategories;
	getSecteursActivite: typeof getSecteursActivite;
	getInfoProduitsPanier: typeof getInfoProduitsPanier;
}

export function _Client({
	idClient,
	isPanierOpen,
	getSelections,
	getCategories,
	getSecteursActivite,
	getInfoProduitsPanier,
	location: { search, pathname },
}: Props) {
	useEffect(() => {
		getSelections(idClient);
		getCategories(idClient);
		getSecteursActivite('CLIENT');
		getInfoProduitsPanier(idClient);
	}, [
		getSelections,
		getCategories,
		getSecteursActivite,
		getInfoProduitsPanier,
		idClient,
	]);

	const params = new URLSearchParams(search);
	const isRootRechercheRoute = pathname === '/recherche' && !search;
	const isCategorieRoute = pathname === '/recherche' && params.has('categorie');

	return (
		<div className={styles.wrapper}>
			{/* Header */}
			<Header />

			<div className={styles.middle}>
				{/* Mobile */}
				<Media query={media['<laptop']}>
					{isRootRechercheRoute ? (
						<SelectionsCategoriesMobile />
					) : isCategorieRoute ? (
						<SousCategoriesMobile />
					) : (
						<Routes />
					)}
				</Media>

				{/* Desktop */}
				<Media query={media['>=laptop']}>
					<SideMenuDesktop />
					<Routes />
					{isPanierOpen && <Panier />}
				</Media>
			</div>

			{/* Footer */}
			<Media query={media['<laptop']}>
				<Gradient reversed />
				<Footer />
			</Media>

			<BtnNotifications offsetBottom />
		</div>
	);
}

function mapStateToProps(state: AppState) {
	return {
		idClient: state.compte.id,
		isPanierOpen: state.panier.isOpen,
	};
}

export default withRouter(
	connect(mapStateToProps, {
		getCategories,
		getSelections,
		getSecteursActivite,
		getInfoProduitsPanier,
	})(_Client),
);
