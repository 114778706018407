import React from 'react';
import styles from './OrigineMark.module.scss';

export interface Props {
	origine?: any;
	mark?: any;
}

function OrigineMark({ origine, mark }: Props) {
	return (
		<>
			<div className={styles.div}>
				{mark.map((e: any) => {
					return (
						<span className={styles.mark} key={e.nom}>
							{e.nom}
						</span>
					);
				})}

				{origine.map((e: any) => {
					return (
						<span className={styles.origine} key={e.nom}>
							{e.nom}
						</span>
					);
				})}
			</div>
		</>
	);
}

export default OrigineMark;
