import { ThunkAction } from 'redux-thunk';
import { Label, Marks, Origine } from '../../types';

export interface FiltresState {
	loading: boolean;
	error: string | null;
	filtres: (Labels | Origines | Marques)[];
}

interface Labels {
	nom: 'Labels';
	slug: 'label';
	isOpen: boolean;
	options: Label[];
}

interface Origines {
	nom: 'Origines';
	slug: 'origine';
	isOpen: boolean;
	options: Origine[];
}

interface Marques {
	nom: 'Marques';
	slug: 'marks';
	isOpen: boolean;
	options: Marks[];
}

export interface GetFiltresResponse {
	labels: Labels['options'];
	origines: Origines['options'];
	marks: Marques['options'];
}

export const GET_FILTRES_START = 'GET_FILTRES_START';
export const GET_FILTRES_SUCCESS = 'GET_FILTRES_SUCCESS';
export const GET_FILTRES_FAIL = 'GET_FILTRES_FAIL';
export const OPEN_FILTRE = 'OPEN_FILTRE';
export const CLOSE_FILTRES = 'CLOSE_FILTRES';

interface GetFiltresStartAction {
	type: typeof GET_FILTRES_START;
}

interface GetFiltresSuccessAction {
	type: typeof GET_FILTRES_SUCCESS;
	filtres: FiltresState['filtres'];
}

interface GetFiltresFailAction {
	type: typeof GET_FILTRES_FAIL;
	error: string;
}

interface OpenFiltreAction {
	type: typeof OPEN_FILTRE;
	filtreSlug: string;
}

interface CloseFiltresAction {
	type: typeof CLOSE_FILTRES;
}

export type FiltresActionTypes =
	| GetFiltresStartAction
	| GetFiltresSuccessAction
	| GetFiltresFailAction
	| OpenFiltreAction
	| CloseFiltresAction;

export type FiltresThunkResult = ThunkAction<
	any,
	FiltresState,
	{},
	FiltresActionTypes
>;
