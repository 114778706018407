import { loader } from 'graphql.macro';
import request from '../../utils/request';
import {
	CategoriesState,
	CategoriesThunkResult,
	GET_CATEGORIES_FAIL,
	GET_CATEGORIES_START,
	GET_CATEGORIES_SUCCESS,
} from './types';
import { Fournisseur, Produit, Client } from '../../types';

const query = loader('./getCategories.graphql');
const queryClient = loader('./getClient.graphql');

export function getCategories(id: string): CategoriesThunkResult {
	return async function (dispatch) {
		try {
			dispatch({ type: GET_CATEGORIES_START });

			// Old versions
			// type TResponse = { list: CategoriesState['list'] };
			// const { list } = await request<TResponse>(query);
			// console.log(list);
			// dispatch({ type: GET_CATEGORIES_SUCCESS, list });

			// Show founisseur categories only
			const client = await getClient(id);
			// recuperation de l'id du fournisseur
			const variables = { id: client.fournisseur.id };
			// recuperation donnees fournisseur
			type TResponse = { fournisseur: Fournisseur };
			const { fournisseur } = await request<TResponse>(query, variables);
			const sousCategorie = fournisseur.produits.map(
				(produit: Produit) => produit.sousCategorie,
			);

			const categorie = sousCategorie.map(
				(sousCategorie: any) => sousCategorie.categorie,
			);
			// recuperation des categories une seule fois
			const uniqueCategorie = categorie.filter(
				(cat: { nom: string }, index: number, self: any) =>
					index === self.findIndex((t: any) => t.nom === cat.nom),
			);
			// const uniqueSousCategorie: CategoriesState['list'] = sousCategorie.filter(
			// 	(sousCat: any, index: any, self: any) =>
			// 		index === self.findIndex((t: any) => t.nom === sousCat.nom),
			// );
			// console.log(uniqueSousCategorie);
			dispatch({ type: GET_CATEGORIES_SUCCESS, list: uniqueCategorie });
		} catch (err) {
			dispatch({ type: GET_CATEGORIES_FAIL, error: err.message });
		}
	};
}

export const getClient = async (id: string) => {
	const variables = { id };
	const { client } = await request<{ client: Client }>(queryClient, variables);
	return client;
};
