import { loader } from 'graphql.macro';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Pick2 } from 'ts-multipick';
import { ProduitForPanier } from '../../../fragments/produitForPanier/type';
import { AppState } from '../../../store';
import { User } from '../../../store/compte/types';
import { addProduitToPanier } from '../../../store/panier/actions';
import { PanierState } from '../../../store/panier/types';
import {
	Commande,
	CommandeLigne,
	RecurrenceMois,
	RecurrenceSemaine,
} from '../../../types';
import request from '../../../utils/request';
import Button from '../../Common/Button/Button';
import styles from './DetailCommande.module.scss';
import Header from './Header/Header';
import ListeProduits from './ListeProduits/ListeProduits';
import PopinRepeatCommande from './PopinRepeatCommande/PopinRepeatCommande';
import { Redirect } from 'react-router-dom';

const getCommandeQuery = loader('./getCommande.graphql');
const getCommandeQueryFav = loader('./getCommandeFav.graphql');
const queryClient = loader('./getClient.graphql');

export interface QueryResponse {
	commande: Pick<
		Commande,
		'id' | 'nom' | 'numero' | 'statut' | 'favorite' | 'dateLivraison'
	> & {
		recurrenceMois?: Pick<RecurrenceMois, 'date'>;
		recurrenceSemaine?: Pick<RecurrenceSemaine, 'jour'>[];
		lignes: (Pick<
			CommandeLigne,
			| 'id'
			| 'statut'
			| 'quantite'
			| 'prixUnitaireFinal'
			| 'dateLivraison'
			| 'commentaire'
			| 'uc'
		> &
			Pick2<CommandeLigne, 'fournisseur', 'id' | 'enseigne'> & {
				produit: ProduitForPanier;
			})[];
	};

	recurrenceMois: Pick<RecurrenceMois, 'validee'>;
	recurrenceSemaine: Pick<RecurrenceSemaine, 'validee'>;
}

interface Props extends RouteComponentProps<{ id: string }> {
	idClient: User['id'];
	dateLivraison?: PanierState['dateLivraison'];
	addProduitToPanier: typeof addProduitToPanier;
}

type Client = {
	fournisseur: {
		id: string;
	};
};

export function _DetailCommande({
	idClient,
	dateLivraison,
	addProduitToPanier,
	location: { search },
	match: {
		params: { id },
	},
}: Props) {
	const searchParams = new URLSearchParams(search);
	const recurrenceId = searchParams.get('recurrenceId') || '';
	const recurrenceDate = searchParams.get('recurrenceDate') || '';
	const from = searchParams.get('from') || '/commandes-favorites';

	const [commande, setCommande] = useState<QueryResponse['commande']>();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');

	const [popinRecurrenceOpen, setPopinRecurrenceOpen] = useState(false);
	const [recurrenceValidee, setRecurrenceValidee] = useState(false);
	const [redirect, setRedirect] = useState(false);
	// const client = await getClient(idClient);

	useEffect(() => {
		setError('');
		setLoading(true);
		if (search.includes('reload=true')) {
			window.location.href = window.location.href.replace('true', 'false');
		}
		if (search.includes('favorite=true')) {
			request<QueryResponse>(getCommandeQueryFav, {
				id: id,
				idClient,
				dateLivraison,
			})
				.then((data: any) => {
					console.log(data);
					setCommande(data.cmdFavId[0]);
					// if (recurrenceMois) {
					// 	setPopinRecurrenceOpen(true);
					// 	if (recurrenceMois.validee) setRecurrenceValidee(true);
					// } else if (recurrenceSemaine) {
					// 	setPopinRecurrenceOpen(true);
					// 	if (recurrenceSemaine.validee) setRecurrenceValidee(true);
					// }
				})
				.catch((err) => setError(err.message))
				.finally(() => setLoading(false));
		} else {
			// const idFournisseur = client.fournisseur.id;

			request<QueryResponse>(getCommandeQuery, {
				id,
				idClient,
				recurrenceId,
				dateLivraison,
				// idFournisseur,
			})
				.then(({ commande, recurrenceMois, recurrenceSemaine }) => {
					setCommande(commande);
					if (recurrenceMois) {
						setPopinRecurrenceOpen(true);
						if (recurrenceMois.validee) setRecurrenceValidee(true);
					} else if (recurrenceSemaine) {
						setPopinRecurrenceOpen(true);
						if (recurrenceSemaine.validee) setRecurrenceValidee(true);
					}
				})
				.catch((err) => setError(err.message))
				.finally(() => setLoading(false));
		}
	}, [id, idClient, dateLivraison, recurrenceId, recurrenceDate]);

	function addCommandeProduitsToPanier() {
		if (!commande) return;
		commande.lignes.forEach(({ produit, quantite, commentaire }) => {
			const { tarifs, ...rest } = produit as any;
			//TODO
			addProduitToPanier({
				quantite,
				commentaire,
				produit: { ...rest, tarif: produit.tarifs[produit.tarifs.length - 1] },
			});
		});
	}

	const fournisseurs = commande
		? commande.lignes.reduce((acc, val) => {
				const idFournisseur = val.fournisseur.id;
				const obj = acc.find((f) => f.idFournisseur === idFournisseur);
				if (!obj) acc.push({ idFournisseur, lignes: [val] });
				else obj.lignes.push(val);
				return acc;
		  }, [] as { idFournisseur: string; lignes: typeof commande.lignes }[])
		: [];
	return redirect ? (
		<Redirect to="/commandes-favorites?orderBy=numero&order=DESC" />
	) : (
		<div className={styles.wrapper}>
			{/* Header */}
			<Header
				from={from}
				commande={commande}
				initLoading={loading}
				updateCommandeState={(params) => {
					setRedirect(true);
					setCommande({ ...commande!, ...params });
				}}
			/>

			{/* Liste des produits par fournisseur */}
			{fournisseurs.length ? (
				fournisseurs.map((f) => {
					return (
						<Fragment key={f.idFournisseur}>
							{/*<HeaderLignesFournisseur id={f.idFournisseur}*/}
							{/*												 statut={statut}*/}
							{/*												 timeLivraison={''}*/}
							{/*												 optionLivraison={''}*/}
							{/*												 dayDelivery={""}*/}
							{/*												 panier={{}}*/}
							{/*												 dateLivraison={dateLivraison}*/}
							{/*												 enseigne={fournisseur.enseigne}*/}
							{/*/>*/}
							<ListeProduits
								error={error}
								commande={commande}
								loading={loading}
								lignes={f.lignes}
								setProduitFavori={(id, newState) => {
									setCommande({
										...commande!,
										lignes: commande!.lignes.map((l) => ({
											...l,
											produit: {
												...l.produit,
												...(l.produit.id === id && {
													isFavoriteForMe: newState,
												}),
											},
										})),
									});
								}}
							/>
						</Fragment>
					);
				})
			) : (
				<ListeProduits
					lignes={[]}
					commande={{}}
					error={error}
					loading={loading}
					setProduitFavori={() => null}
				/>
			)}

			{/* Footer */}
			<footer className={styles.footer}>
				<Button
					inverted
					disabled={!commande}
					onClick={addCommandeProduitsToPanier}
					title={'Ajouter les produits de la commande au panier'}
				>
					Ajouter les produits au panier
				</Button>
			</footer>

			{/* Si on valide une commande récurrente */}
			{commande && recurrenceId && recurrenceDate && (
				<PopinRepeatCommande
					commande={commande}
					validee={recurrenceValidee}
					isOpen={popinRecurrenceOpen}
					closePopin={() => setPopinRecurrenceOpen(false)}
					recurrence={{ id: recurrenceId, date: new Date(recurrenceDate) }}
				/>
			)}
		</div>
	);
}

function mapStateToProps(state: AppState) {
	return {
		idClient: state.compte.id,
		dateLivraison: state.panier.dateLivraison,
	};
}

export default withRouter(
	connect(mapStateToProps, { addProduitToPanier })(_DetailCommande),
);

const getClient = async (id: string) => {
	const variables = { id };
	const response = await request<{ clients: Client[] }>(queryClient, variables);
	return response.clients[0];
};
