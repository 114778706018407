import { loadStateFromLocalStorage } from '../../utils/localStorage';
import {
	ADD_PRODUIT_TO_PANIER,
	CHANGE_DATE_LIVRAISON_PANIER,
	CHANGE_LIGNE_PANIER,
	CHANGE_NOM_PANIER,
	CLOSE_PANIER,
	EMPTY_PANIER,
	GET_INFO_PRODUITS_PANIER_FAIL,
	GET_INFO_PRODUITS_PANIER_START,
	GET_INFO_PRODUITS_PANIER_SUCCESS,
	OPEN_PANIER,
	PanierActionTypes,
	PanierState,
	REMOVE_PANIER_RECURRENCE,
	REMOVE_PRODUIT_FROM_PANIER,
	RESET_PANIER,
	SET_PANIER_AS_COMMANDE_FAVORITE,
	SET_PANIER_RECURRENCE,
} from './types';

const panier = loadStateFromLocalStorage('panier');

const initialState: PanierState = {
	isOpen: false,
	errorInfoProduits: undefined,
	loadingInfoProduits: false,

	isPopinRecurrenceOpen: false,

	lignes: [],
	favorite: false,

	...(panier && {
		nom: panier.nom,
		favorite: panier.favorite,
		dateLivraison: panier.dateLivraison && new Date(panier.dateLivraison),
		recurrenceMois: panier.recurrenceMois,
		recurrenceSemaine: panier.recurrenceSemaine,
	}),
};

export function panierReducer(
	state = initialState,
	action: PanierActionTypes,
): PanierState {
	switch (action.type) {
		case OPEN_PANIER:
			return { ...state, isOpen: true };

		case CLOSE_PANIER:
			return { ...state, isOpen: false };

		case GET_INFO_PRODUITS_PANIER_START:
			return { ...state, loadingInfoProduits: true };

		case GET_INFO_PRODUITS_PANIER_SUCCESS:
			return {
				...state,
				lignes: action.lignes,
				loadingInfoProduits: false,
			};

		case GET_INFO_PRODUITS_PANIER_FAIL:
			return {
				...state,
				errorInfoProduits: action.error,
				loadingInfoProduits: false,
			};

		case ADD_PRODUIT_TO_PANIER:
			if (state.lignes.find(l => l.produit.id === action.ligne.produit.id)) {
				return state;
			}
			return { ...state, lignes: [...state.lignes, action.ligne] };

		case CHANGE_LIGNE_PANIER:
			return {
				...state,
				lignes: state.lignes.map(l => {
					if (l.produit.id === action.idProduit) {
						l.quantite = action.quantite;
					}
					return l;
				}),
			};

		case REMOVE_PRODUIT_FROM_PANIER:
			return {
				...state,
				lignes: state.lignes.filter(l => l.produit.id !== action.idProduit),
			};

		case CHANGE_NOM_PANIER:
			return { ...state, nom: action.nom };

		case SET_PANIER_AS_COMMANDE_FAVORITE:
			return { ...state, favorite: action.favorite };

		case CHANGE_DATE_LIVRAISON_PANIER:
			return { ...state, dateLivraison: action.date };

		case SET_PANIER_RECURRENCE:
			return { ...state, ...action };

		case REMOVE_PANIER_RECURRENCE:
			return {
				...state,
				recurrenceMois: undefined,
				recurrenceSemaine: undefined,
			};

		case EMPTY_PANIER:
			return { ...state, lignes: [] };

		case RESET_PANIER:
			return {
				...state,
				nom: undefined,
				lignes: [],
				favorite: false,
				dateLivraison: undefined,
				recurrenceMois: undefined,
				recurrenceSemaine: undefined,
			};

		default:
			return state;
	}
}
