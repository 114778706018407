import React from 'react';
import BtnNotifications from '../Common/BtnNotifications/BtnNotifications';
import styles from './Admin.module.scss';
import Header from './Header/Header';
import Routes from './Routes/Routes';

function Admin() {
	return (
		<div className={styles.wrapper}>
			<Header />
			<Routes />
			<BtnNotifications />
		</div>
	);
}

export default Admin;
