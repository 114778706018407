import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import {
	photosFournisseursSizes,
	placeholderSizes,
} from '../../../../utils/imgSizes';
import Img from '../../../Common/Img/Img';
import { QueryResponse } from '../ResultatsRecherche';
import { ReactComponent as OeilSVG } from './oeil.svg';
import styles from './VignetteFournisseur.module.scss';

type Props = QueryResponse['fournisseurs'][0] & RouteComponentProps;

export function _VignetteFournisseur({
	id,
	photo,
	enseigne,
	secteurActivite,
	location: { pathname, search },
}: Props) {
	const searchParams = new URLSearchParams(search);

	const isActive = searchParams.get('fournisseur') === id;

	searchParams.set('fournisseur', id);
	const linkToFournisseur = pathname + '?' + searchParams;
	searchParams.delete('fournisseur');
	const linkBack = pathname + '?' + searchParams;

	return (
		<div
			className={[styles.wrapper, isActive && styles.active]
				.filter(Boolean)
				.join(' ')}
		>
			<div className={styles.left}>
				<Img
					alt={enseigne}
					className={styles.photo}
					fallback={photo ? 'jpg' : 'png'}
					mediaQueries={[{ width: styles.photoWidth }]}
					src={photo || '/media/misc/placeholder@120.webp'}
					otherSizes={photo ? photosFournisseursSizes : placeholderSizes}
				/>
			</div>

			<div className={styles.right}>
				<h1 className={styles.enseigne}>{enseigne}</h1>

				<p className={styles.secteur}>{secteurActivite.nom}</p>

				<Link
					className={styles.link}
					to={isActive ? linkBack : linkToFournisseur}
					title={isActive ? 'Fermer fournisseur' : 'Voir fournisseur'}
				>
					<OeilSVG />
					<span className={styles.close} />
				</Link>
			</div>
		</div>
	);
}

export default withRouter(_VignetteFournisseur);
