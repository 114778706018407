import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { getConditionsPaiement } from '../../store/conditionsPaiement/actions';
import { getSecteursActivite } from '../../store/secteursActivite/actions';
import { media } from '../../utils/breakpoints/breakpoints';
import BtnNotifications from '../Common/BtnNotifications/BtnNotifications';
import Routes from '../Fournisseur/Routes/Routes';
import styles from './Fournisseur.module.scss';
import Header from './Header/Header';
import SideMenu from './SideMenu/SideMenu';

interface Props {
	getSecteursActivite: typeof getSecteursActivite;
	getConditionsPaiement: typeof getConditionsPaiement;
}

export function _Fournisseur({
	getSecteursActivite,
	getConditionsPaiement,
}: Props) {
	useEffect(() => {
		getConditionsPaiement();
		getSecteursActivite('FOURNISSEUR');
	}, [getSecteursActivite, getConditionsPaiement]);

	return (
		<div className={styles.wrapper}>
			<Header />

			<div className={styles.middle}>
				<MediaQuery query={media['>=laptop']}>
					<SideMenu />
				</MediaQuery>

				<Routes />
			</div>

			<BtnNotifications />
		</div>
	);
}

export default connect(null, { getSecteursActivite, getConditionsPaiement })(
	_Fournisseur,
);
