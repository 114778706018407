import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../Button/Button';
import styles from './Dispatch.module.scss';

function Dispatch() {
	return (
		<>
			<Link
				to="/connexion-client"
				className={[styles.link, styles.client].join(' ')}
			>
				<h1 className={styles.title}>Vous êtes client</h1>

				<p className={styles.text}>
					Et vous souhaitez avoir accès aux meilleurs produits à prix négociés
				</p>

				<Button className={styles.button}>Se connecter</Button>
			</Link>

			<Link
				to="/connexion-fournisseur"
				className={[styles.link, styles.fournisseur].join(' ')}
			>
				<h1 className={styles.title}>Vous êtes fournisseur</h1>

				<p className={styles.text}>
					Et vous souhaitez proposer vos produits sur notre plateforme en ligne
				</p>

				<Button className={styles.button}>Se connecter</Button>
			</Link>
		</>
	);
}

export default Dispatch;
