import React from 'react';
import Alertes from '../../Common/Alertes/Alertes';
import BtnAlertes from '../../Common/BtnAlertes/BtnAlertes';
import Logo from '../../Common/Logo/Logo';
import MenuGeneral from '../../Common/MenuGeneral/MenuGeneral';
import styles from './Header.module.scss';
import { NavLink } from 'react-router-dom';
import { loader } from 'graphql.macro';
import request from '../../../utils/request';
import { useToasts } from 'react-toast-notifications';

const query = loader('./exec.graphql');
const links = [
	{ title: 'Actualités', to: '/actualites' },
	{ title: 'Commentaires', to: '/admin/commentaires' },
	{ title: 'Alertes', to: '/admin/alertes' },
	{ title: 'Clients', to: '/admin/clients' },
	{ title: 'Fournisseurs', to: '/admin/fournisseurs' },

	{ title: 'Admins', to: '/admin/list' },
	{ title: 'Contact', to: '/contact' },
	{ title: 'Mentions légales', to: '/mentions-legales' },
];

function Header() {
	const { addToast, removeAllToasts } = useToasts();

	async function sendExec() {
		addToast('Import en cours ....', { appearance: 'success' });
		request<any>(query)
			.then((data) => {
				removeAllToasts();

				addToast('Import terminé !', { appearance: 'success' });
				addToast(
					'Information : \n - Total = ' +
						data.exec.total +
						' \n - Ignored :  ' +
						data.exec.total_ignored +
						' \n -  Created : ' +
						data.exec.total_imported,
					{ appearance: 'success' },
				);

				setTimeout(() => {
					removeAllToasts();
				}, 10000);
			})
			.catch((err) => {
				removeAllToasts();
				addToast(
					"Le processus d'import continu en background car il met trop de temps à répondre",
					{ appearance: 'warning' },
				);
				setTimeout(() => {
					removeAllToasts();
				}, 10000);
			});

		setTimeout(() => {
			removeAllToasts();
		}, 300000);
	}

	return (
		<header className={styles.wrapper}>
			<Logo className={styles.logo} />
			<button>
				<p className={styles.btnClasse} onClick={() => sendExec()}>
					Lancer l'import
				</p>
			</button>
			{/*<button className="btnClasse">*/}
			{/*	<p className={styles.nom}>*/}
			{/*		<NavLink*/}
			{/*			id={'actualite'}*/}
			{/*			to={'/actualites'}*/}
			{/*			exact*/}
			{/*			className={styles.link}*/}
			{/*			activeClassName={styles.current}*/}

			{/*		>*/}
			{/*			Actualités*/}
			{/*		</NavLink>*/}
			{/*	</p>*/}
			{/*</button>*/}
			<button className="btnClasse">
				<p className={styles.nom}>
					<NavLink
						id={'admin'}
						to={'/admin/list'}
						exact
						className={styles.link}
						activeClassName={styles.current}
					>
						Admin
					</NavLink>
				</p>
			</button>
			<button className="btnClasse">
				<p className={styles.nom}>
					<NavLink
						id={'client'}
						to={'/admin/clients'}
						exact
						className={styles.link}
						activeClassName={styles.current}
					>
						Clients
					</NavLink>
				</p>
			</button>
			<button className="btnClasse">
				<p className={styles.nom}>
					<NavLink
						id={'fournisseur'}
						to={'/admin/fournisseurs'}
						exact
						className={styles.link}
						activeClassName={styles.current}
					>
						Fournisseurs
					</NavLink>
				</p>
			</button>
			{/*<button className="btnClasse">*/}
			{/*	<p className={styles.nom}><NavLink*/}
			{/*		id={'produits'}*/}
			{/*		to={'/admin/produits'}*/}
			{/*		exact*/}
			{/*		className={styles.link}*/}
			{/*		activeClassName={styles.current}*/}

			{/*	>*/}
			{/*		Produits*/}
			{/*	</NavLink></p>*/}
			{/*</button>*/}
			<button className="btnClasse">
				<p className={styles.nom}>
					<NavLink
						id={'fournisseur'}
						to={'/admin/alertes'}
						exact
						className={styles.link}
						activeClassName={styles.current}
					>
						Alertes
					</NavLink>
				</p>
			</button>
			<button className="btnClasse">
				<p className={styles.nom}>
					<NavLink
						id={'fournisseur'}
						to={'/admin/commentaires'}
						exact
						className={styles.link}
						activeClassName={styles.current}
					>
						Commentaires
					</NavLink>
				</p>
			</button>

			<BtnAlertes />
			<Alertes />

			<MenuGeneral links={links} />
		</header>
	);
}

export default Header;
