import React, { useState, useEffect } from 'react';
import styles from './Checkbox.module.scss';

interface Props {
	children?: string;
	className?: string;
	name?: string;
	register?: any;
	isAllReadyChecked?: boolean;
}

export function Checkbox({
	children,
	className,
	name,
	register,
	isAllReadyChecked,
}: Props) {
	// Utilise isChecked pour gérer l'état de la checkbox
	const [isChecked, setIsChecked] = useState(false);

	function handleChecked(checked: boolean) {
		setIsChecked(checked); // Change l'état lorsqu'on clique sur la checkbox
	}

	// Initialise isChecked à partir de isAllReadyChecked au chargement du composant
	useEffect(() => {
		if (isAllReadyChecked !== undefined) {
			setIsChecked(isAllReadyChecked);
		}
	}, [isAllReadyChecked]);

	return (
		<label
			className={[
				styles.wrapper,
				className,
				isChecked && styles.checked, // Applique un style différent si la case est cochée
			]
				.filter(Boolean)
				.join(' ')}
		>
			<input
				type="checkbox"
				name={name}
				className={styles.input}
				ref={register}
				checked={isChecked} // Utilise isChecked pour déterminer si la case est cochée
				onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
					handleChecked(e.target.checked)
				} // Appelle handleChecked au changement
			/>
			<span className={styles.checkbox} />
			{children && <p className={styles.text}>{children}</p>}
		</label>
	);
}

export default Checkbox;
