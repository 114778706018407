import React from 'react';
import styles from './Skeleton.module.scss';

interface Props {
	className?: string;
	size: 'small' | 'big' | 'full';
}

function Skeleton({ className, size }: Props) {
	return (
		<span className={className}>
			<span className={[styles.text, styles[size]].join(' ')}>&nbsp;</span>
		</span>
	);
}

export default Skeleton;
