import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { useTitle } from '../../../../hooks/useTitle';
import { AppState } from '../../../../store';
import { hideLoginError, logIn } from '../../../../store/compte/actions';
import { UserType } from '../../../../store/compte/types';
import { passwordMinLength } from '../../../../utils/password';
import BtnBack from '../../BtnBack/BtnBack';
import Button from '../../Button/Button';
import ErrorMessage from '../../Form/ErrorMessage/ErrorMessage';
import Form from '../../Form/Form';
import Input from '../../Form/Input/Input';
import SmallLink from '../../Form/SmallLink/SmallLink';
import styles from './Connexion.module.scss';

export interface Props extends RouteComponentProps {
	type: UserType;
	isLoggingIn: boolean;
	isLoggedIn: boolean;
	loginError?: string;
	logIn: typeof logIn;
	hideLoginError: typeof hideLoginError;
}

export function _Connexion({
														 type,
														 logIn,
														 location,
														 isLoggedIn,
														 loginError,
														 isLoggingIn,
														 hideLoginError,
													 }: Props) {
	useTitle('Connexion' + (type === 'Client' ? '' : ' ' + type.toLowerCase()));

	const searchParams = new URLSearchParams(location.search);

	const [login, setLogin] = useState(searchParams.get('login') || '');
	const [password, setPassword] = useState('');

	function changeLogin(val: string) {
		if (loginError) hideLoginError();
		setLogin(val);
	}

	function changePassword(val: string) {
		if (loginError) hideLoginError();
		setPassword(val);
	}

	return isLoggedIn ? (
		<Redirect to="/" />
	) : (
		<Form onSubmit={() => logIn({ login, password, type })}>
			{type !== 'Admin'? (
				<BtnBack to="/connexion" withText className={styles.back} />) : (
				<BtnBack to="/connexion" withText={false} className={styles.back} />)}

			<h1 className={styles.title}>Accès {type.toLowerCase() + 's'}</h1>

			<Input
				label="Identifiant"
				value={login}
				setValue={changeLogin}
				required
				autoFocus
			/>

			<Input
				label="Mot de passe"
				type="password"
				value={password}
				setValue={changePassword}
				minLength={passwordMinLength}
				required
			/>

			{type !== 'Admin' && (
				<SmallLink
					to={
						`/mot-de-passe-${type.toLowerCase()}-oublie` +
						(login ? '?login=' + login : '')
					}
				>
					J'ai oublié mon mot de passe...
				</SmallLink>
			)}

			<ErrorMessage>{loginError}</ErrorMessage>

			<Button submit loading={isLoggingIn}>
				Se connecter
			</Button>
		</Form>
	);
}

function mapStateToProps(state: AppState) {
	return {
		isLoggingIn: state.compte.isLoggingIn,
		isLoggedIn: state.compte.isLoggedIn,
		loginError: state.compte.error,
	};
}

export default withRouter(
	connect(mapStateToProps, { logIn, hideLoginError })(_Connexion),
);
