import React, { ReactNode } from 'react';
import { thinSpace } from '../../../../../utils/french';
import styles from './Info.module.scss';

interface Props {
	label: string;
	className?: string;
	children?: ReactNode;
}

function Info({ children, label, className }: Props) {
	return (
		<p className={[styles.wrapper, className].filter(Boolean).join(' ')}>
			<strong>
				{label}
				{thinSpace}:{' '}
			</strong>

			{children || <span className={styles.skeleton}>&nbsp;</span>}
		</p>
	);
}

export default Info;
